import { useEffect } from "react";
import { useState } from "react";


const Pagination = ({ totalPages, currentPage, onPageChange, itemsPerPage }) => {


    const [previousPage, setPreviousPage] = useState(0);
    const [previousPageEnabled, setPreviousPageEnabled] = useState(false);

    const [nextPage, setnextPage] = useState(0);
    const [nextPageEnabled, setNextPageEnabled] = useState(false);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        if (i <= 5) {
            pageNumbers.push(i);

        }
        if (i === 4)
            break;
    }

    const linkStyles = {
        pointerEvents: 'none',
        color: 'gray',
        textDecoration: 'none',
    };


    return (
        <div className="pagination-wrapper text-center style2">
            <nav className="pt-60">
                <ul className="pagination d-flex justify-content-center mb-0" style={{cursor:'pointer'}}>
                    <li className="prev-page" style={{fontFamily: 'Rokkitt' }}>
                        <a onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                            <i className="fa fa-angle-left"></i>{' '}Previous
                        </a>
                    </li>
                    {pageNumbers.map(number => (
                        <li key={number} style={{fontFamily: 'Rokkitt' }}>
                            <a onClick={() => onPageChange(number)} disabled={number === currentPage} style={number === currentPage ? { borderColor: '#de7a72', backgroundColor: '#de7a72'} : {}}  >
                                {number}
                            </a>
                        </li>
                    ))}
                      <li style={{fontFamily: 'Rokkitt' }}>
                        <a onClick={() => onPageChange(totalPages)} style={currentPage === totalPages ? { borderColor: '#de7a72', backgroundColor: '#de7a72' } : {}}>
                            Last
                        </a>
                    </li>
                    <li className="next-page" style={{fontFamily: 'Rokkitt' }}>
                        <a onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} >
                            Next <i className="fa fa-angle-right">{' '}</i>
                        </a>
                    </li>
                  


                </ul>
            </nav>
        </div>
    );
}
export default Pagination;
