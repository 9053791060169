export const ChefMyOrders = () => {

    return(
        <>
        <div
                    className="tab-pane"
                    id="v-pills-order"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="tabs-wrp brd-rd5">
                      <h4 itemprop="headline">MY ORDERS</h4>
                      <div className="select-wrap-inner">
                        <div className="select-wrp2">
                          <select>
                            <option>Select Orders Status</option>
                            <option>Select Orders Status</option>
                            <option>Select Orders Status</option>
                          </select>
                        </div>
                        <div className="select-wrp2">
                          <select>
                            <option>Select Date Range</option>
                            <option>Select Date Range</option>
                            <option>Select Date Range</option>
                          </select>
                        </div>
                      </div>
                      <div className="order-list">
                        <div className="order-item brd-rd5">
                          <div className="order-thumb brd-rd5">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/resources/order-img1.jpg"
                                alt="order-img1.jpg"
                                itemprop="image"
                              />
                            </a>
                            <span className="post-rate yellow-bg brd-rd2">
                              <i className="fa fa-star-o"></i> 4.25
                            </span>
                          </div>
                          <div className="order-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Maenaam Thai Restaurant
                              </a>
                            </h4>

                            <span className="price">$85.00</span>
                            <span className="processing brd-rd3">
                              PROCESSING
                            </span>
                            <a
                              className="brd-rd2"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              Order Detail
                            </a>
                          </div>
                        </div>
                        <div className="order-item brd-rd5">
                          <div className="order-thumb brd-rd5">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/resources/order-img2.jpg"
                                alt="order-img2.jpg"
                                itemprop="image"
                              />
                            </a>
                            <span className="post-rate yellow-bg brd-rd2">
                              <i className="fa fa-star-o"></i> 3.0
                            </span>
                          </div>
                          <div className="order-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Maenaam Thai Restaurant
                              </a>
                            </h4>

                            <span className="price">$85.00</span>
                            <span className="completed brd-rd3">COMPLETED</span>
                            <a
                              className="brd-rd2"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              Order Detail
                            </a>
                          </div>
                        </div>
                        <div className="order-item brd-rd5">
                          <div className="order-thumb brd-rd5">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/resources/order-img3.jpg"
                                alt="order-img3.jpg"
                                itemprop="image"
                              />
                            </a>
                            <span className="post-rate yellow-bg brd-rd2">
                              <i className="fa fa-star-o"></i> 5.00
                            </span>
                          </div>
                          <div className="order-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Maenaam Thai Restaurant
                              </a>
                            </h4>

                            <span className="price">$85.00</span>
                            <span className="completed brd-rd3">COMPLETED</span>
                            <a
                              className="brd-rd2"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              Order Detail
                            </a>
                          </div>
                        </div>
                        <div className="order-item brd-rd5">
                          <div className="order-thumb brd-rd5">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/resources/order-img4.jpg"
                                alt="order-img4.jpg"
                                itemprop="image"
                              />
                            </a>
                            <span className="post-rate yellow-bg brd-rd2">
                              <i className="fa fa-star-o"></i> 5.30
                            </span>
                          </div>
                          <div className="order-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Maenaam Thai Restaurant
                              </a>
                            </h4>

                            <span className="price">$85.00</span>
                            <span className="completed brd-rd3">COMPLETED</span>
                            <a
                              className="brd-rd2"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              Order Detail
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="pagination-wrapper text-center style2">
                        <nav className="pt-60">
                          <ul className="pagination d-flex justify-content-center mb-0">
                            <li className="prev-page">
                              <a href="javascript:void(0)">
                                <i className="fa fa-angle-left"></i>Prev
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">1</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">2</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">3</a>
                            </li>
                            <li className="page-on">......</li>
                            <li>
                              <a href="javascript:void(0)">28</a>
                            </li>
                            <li className="next-page">
                              <a href="javascript:void(0)">
                                Next<i className="fa fa-angle-right"></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
        </>
    );
}