import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Global from "../../../global";
import ChefRegistrationModel from "../../Models/ChefRegistrationModel";
import { useEffect, useState } from "react";
import { updateChef } from "../../../redux/ActionsCreator/registerService";
import { useSelector, useDispatch } from 'react-redux';
import { getuid } from 'process';
import { fetchCountries, fetchCitiesByCountry, fetchAreasByCity } from '../../../redux/ActionsCreator/dropdownListService';
import AlertModel from '../../Models/AlertModel';
import AlertPopup from '../../General/AlertPopup';

const schema = yup.object().shape({
  completename: yup.string().required('Complete name is required').min(8, 'Complete name must be at least 8 characters').uppercase('First letter should be upper case'),
  email: yup.string().email().required('Email is required field'),
  phone: yup.string().required('Phone is required field').min(10).max(13, 'Phone number must be at most 13 charactors'),
  whatsappNo: yup.string().required('Whatsapp no is required field').min(10).max(13, 'whatsapp number must be at most 13 charactors').nullable(),
  ddlGender: yup.string().required('Gender is required field'),
  ddlExperience: yup.string().required('Experience is required field'),
  ddlNationality: yup.string().required('Nationality is required field'),
  ddlCountry: yup.string().required('Country is required field'),
  //ddlState: yup.string().required('State is required field'),
  ddlCity: yup.string().required('City is required field'),
  ddlArea: yup.string().required('Area is required field'),
  address: yup.string().required('Address is required field').nullable(),
  facebookLink: yup.string().nullable(),
  instaLink: yup.string().nullable(),
  youtubeLink: yup.string().nullable(),
});

const ChefAccount = () => {

  const dispatch = useDispatch();

  const ChefModel = new ChefRegistrationModel();
  var AlertOptions = new AlertModel();

  const { register, handleSubmit, formState: { errors }, reset, setFocus, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  // Hooks Assignments 
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState();
  const [base64, setFileBase64] = useState("");
  const [alertOptions, setAlertOptions] = useState(AlertOptions);
  const [isLoading, setIsLoading] = useState(false);

  const _apiHeader = useSelector((state) => state.ChefAuthReducer.chef?.apiHeader);
  const _chef = useSelector((state) => state.ChefAuthReducer.chef);
  const _chefId = useSelector((state) => state.ChefAuthReducer.chef?.chefId);
  const _countryListDDLR = useSelector((state) => state.DropdownListReducer.CountryListDDLR);
  const _selectedCitiesDDLR = useSelector((state) => state.DropdownListReducer.SelectedCitiesDDLR);
  const _selectedAreasDDLR = useSelector((state) => state.DropdownListReducer.SelectedAreasDDLR);
  const _notifyReturnModelCRR = useSelector((state) => state.ChefRecipeReducer.notifyReturnModelCRR);

  useEffect(() => {
    dispatch(fetchCountries());

  }, [_chef])

  useEffect(() => {

    //console.log('redux state: Chef model (chef account): ', _chef);

    let responseData = _chef;

    if (responseData !== undefined) {
      ChefModel.id = getuid;
      ChefModel.ChefId = responseData.chefId;
      ChefModel.FullName = responseData.fullName;
      ChefModel.Email = responseData.email;
      ChefModel.Password = responseData.password;
      ChefModel.Phone = responseData.phone;
      ChefModel.WhatsappNo = responseData.whatsappNo;
      ChefModel.IsReceipeCustomizationFlag = responseData.isReceipeCustomizationFlag;
      ChefModel.Gender = responseData.gender;
      ChefModel.Experience = responseData.experience;
      ChefModel.NationalityId = responseData.nationalityId;
      ChefModel.CountryId = responseData.CountryId;
      ChefModel.StateId = responseData.stateId;
      ChefModel.CityId = responseData.cityId;
      ChefModel.AreaId = responseData.areaId;
      ChefModel.Address = responseData.address;
      ChefModel.FacebookLink = responseData.facebookLink;
      ChefModel.InstaLink = responseData.instaLink;
      ChefModel.YoutubeLink = responseData.youtubeLink;
      ChefModel.ImageBinary = responseData.imageBinary;

      //console.log('responseData.dtoImage: ', responseData.dtoImage);

      if (responseData.dtoImage != undefined && responseData.dtoImage != null) {
        ChefModel.DTOImage.ImageId = responseData.dtoImage.imageId;
        ChefModel.DTOImage.EntityId = responseData.dtoImage.entityId;
        ChefModel.DTOImage.ImageName = responseData.dtoImage.imageName;
        ChefModel.DTOImage.ImageBinary = responseData.dtoImage.imageBinary;
        ChefModel.DTOImage.ImageTypeId = responseData.dtoImage.imageTypeId;
        ChefModel.DTOImage.IsActive = responseData.dtoImage.isActive;
      }
      var isTempUpload = localStorage.getItem('tempupload');


      // console.log('isTempUpload (Onload)', isTempUpload)
      if (isTempUpload != 'true') {

        if (ChefModel.DTOImage.ImageBinary !== undefined && ChefModel.DTOImage.ImageBinary !== null && ChefModel.DTOImage.ImageBinary !== "") {
          setFileBase64(ChefModel.DTOImage.ImageBinary);
          setFileName(ChefModel.DTOImage.ImageName);
        }
        else {
          if (ChefModel.Gender === "Female") {
            setFileBase64("assets/images/DPFemale.jpg")
          }
          else {
            setFileBase64("assets/images/DPMale.jpg")
          }
        }
      }


      setValue('completename', ChefModel.FullName);
      setValue('email', ChefModel.Email);
      setValue('password', ChefModel.Password);
      setValue('phone', ChefModel.Phone);
      setValue('address', ChefModel.Address);
      setValue('whatsappNo', ChefModel.WhatsappNo);
      setValue('facebookLink', ChefModel.FacebookLink);
      setValue('instaLink', ChefModel.InstaLink);
      setValue('youtubeLink', ChefModel.YoutubeLink);

      if (ChefModel.Gender !== null && ChefModel.Gender !== undefined) {
        setValue('ddlGender', ChefModel.Gender);
      }
      if (ChefModel.Experience !== null && ChefModel.Experience !== undefined) {
        setValue('ddlExperience', ChefModel.Experience);
      }

      if (ChefModel.NationalityId !== null && ChefModel.NationalityId !== undefined) {
        setValue('ddlNationality', ChefModel.NationalityId);


        if (ChefModel.CountryId !== null && ChefModel.CountryId !== undefined) {
          setValue('ddlCountry', ChefModel.CountryId);
          dispatch(fetchCitiesByCountry(ChefModel.CountryId));

        }
        // if (ChefModel.StateId !== null && ChefModel.StateId !== undefined) {
        //   setValue('ddlState', ChefModel.StateId);
        // }
        if (ChefModel.CityId !== null && ChefModel.CityId !== undefined) {
          setValue('ddlCity', ChefModel.CityId);
          dispatch(fetchAreasByCity(ChefModel.CityId));

        }
        if (ChefModel.AreaId !== null && ChefModel.AreaId !== undefined) {
          setValue('ddlArea', ChefModel.AreaId);
        }
      }
    }
  }, []);


  const fnConvertToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);

    reader.onerror = error => reject(error);
  });

  const fnPreviewDisplayPicture = async (e) => {

    if (e.target.files[0].size > 2000000) {
      console.log("File too large");
      alert("File too large")
      return;
    }

    setFile(e.target.files[0]);

    //  console.log(e.target.files[0]);

    const base64 = await fnConvertToBase64(e.target.files[0]);
    // console.log('image base64', base64);

    setFileBase64(base64);
    setFileName(e.target.files[0].name);

    localStorage.setItem('tempupload', true);
    localStorage.setItem('uploadedFile', e.target.files[0].name);
  };

  const onChangeCountry = (countryId) => {
    debugger;
    setValue('ddlCity', '');
    setValue('ddlArea', '');
    dispatch(fetchCitiesByCountry(countryId));

  }

  const onChangeCity = (cityId) => {
    debugger;
    setValue('ddlArea', '');
    dispatch(fetchAreasByCity(cityId));
  }
  /*
  
    const [formData, setFormData] = useState({});
  
    const onUploadPicture = (e) => {
      console.log(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
      e.preventDefault();
      let form = new FormData();
      var element = e.target.files[0];
      form.append('image', element);
      form.append('fileName', e.target.files[0].name);
  
      console.log('image form data', form);
      setFormData(form);
>>>>>>> f9dd585165e5580dc66a1c59428689e7f34a2bac
    }
    
    const uploadFile = async (e) => {
  
      console.log('uploadFile event clicked', file);
  
      const formData = new FormData();
      formData.append("formFile", file);
      formData.append("fileName", fileName);
      try {
        const res = await axios.post("https://localhost:7089/api/Image/PostImage", formData);
        console.log(res);
      } catch (ex) {
        console.log(ex);
      }
    };
    */

  const handleSubmitAccount = (data) => {

    setIsLoading(true);

    //console.log(data);
    ChefModel.chefId = _chef.chefId;
    ChefModel.FullName = data.completename;
    ChefModel.Email = data.email;
    // ChefModel.Password = data.password;
    ChefModel.Phone = data.phone;
    ChefModel.WhatsappNo = data.whatsappNo;
    ChefModel.Experience = data.ddlExperience;
    ChefModel.Gender = data.ddlGender;
    ChefModel.NationalityId = data.ddlNationality;
    ChefModel.CountryId = data.ddlCountry;
    // ChefModel.StateId = data.ddlState;
    ChefModel.CityId = data.ddlCity;
    ChefModel.AreaId = data.ddlArea;
    ChefModel.Address = data.address;
    ChefModel.FacebookLink = data.facebookLink;
    ChefModel.InstaLink = data.instaLink;
    ChefModel.YoutubeLink = data.youtubeLink;


    if (ChefModel.DTOImage !== null && ChefModel.DTOImage !== undefined) {

      debugger;
      var isTempUpload = localStorage.getItem('tempupload');
      if (isTempUpload === 'true') {

        ChefModel.DTOImage.EntityId = ChefModel.chefId;
        ChefModel.DTOImage.ImageName = fileName;
        ChefModel.DTOImage.ImageBinary = base64;
        ChefModel.DTOImage.ImageTypeId = Global.ImageTypeKey.ChefDP;
        ChefModel.DTOImage.IsActive = true;

        setFileBase64(base64);
        setFileName(fileName);
      }
      else {

        if (ChefModel.Gender === "Female") {
          setFileBase64("assets/images/DPFemale.jpg")
        }
        else if (ChefModel.Gender === "Male" || ChefModel.Gender === "Others") {
          setFileBase64("assets/images/DPMale.jpg")
        }
      }
    }

    dispatch(updateChef(ChefModel));

    localStorage.setItem('tempupload', false);
  }

  useEffect(() => {

    if (parseInt(localStorage.rowEffected) > Global.eResponse.Default) {
      setTimeout(() => {
        setIsLoading(false);

        AlertOptions.Type = Global.AlertPopupType.SimpleAlert;
        AlertOptions.Message = "Chef! Your Account Info has been updated successfully."
        AlertOptions.Variant = Global.AlertVariantType.Success;

        localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
        setAlertOptions(AlertOptions);
        window.scrollTo(0, 20);
      }, 3500);
    }
    else if (parseInt(localStorage.rowEffected) == Global.eResponse.Error) {
      setTimeout(() => {
        setIsLoading(false);

        AlertOptions.Type = Global.AlertPopupType.AdditionalContentAlert;
        AlertOptions.Variant = Global.AlertVariantType.Danger;
        AlertOptions.Heading = "Oh chef! You got an error!"
        AlertOptions.Message = _notifyReturnModelCRR?.errorMsg;

        localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
        setAlertOptions(AlertOptions);
        window.scrollTo(0, 20);

      }, 3500);
    }
  });
  return (
    <>
      <div
        className="tab-pane"
        id="v-pills-account"
        role="tabpanel"
        aria-labelledby="v-pills-settings-tab"
      >
        <div className="tabs-wrp account-settings brd-rd5">
          <div className='row page-header'>
            <h4 itemprop="headline">ACCOUNT SETTINGS</h4>
          </div>
          <AlertPopup alertOptions={alertOptions}></AlertPopup>
          <div className="account-settings-inner">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="profile-info text-center">
                  <div className="profile-thumb brd-rd50">
                    <img
                      id="profile-display"
                      src={base64}
                      alt="Profile picture"
                      itemprop="image"
                      className="brd-rd50"
                    />
                  </div>
                  {/* <input type="button" value="upload" onClick={uploadFile} /> */}

                  <div className="profile-img-upload-btn">
                    <label className="fileContainer brd-rd5 yellow-bg">
                      UPLOAD PICTURE
                      <input id="profile-upload" type="file" onChange={fnPreviewDisplayPicture} />
                    </label>
                  </div>
                  <a
                    className="red-clr change-password"
                    href="#"
                    title=""
                    itemprop="url"
                  >
                    Change Password
                  </a>
                  {/*                   
                  <p itemprop="description">
                    Upload a profile picture or choose one of the
                    following
                  </p>
                  <div className="default-img-lst">
                    <img
                      className="brd-rd50"
                      src="assets/images/resources/profile-thumb1.jpg"
                      alt="profile-thumb1.jpg"
                      itemprop="image"
                    />
                    <img
                      className="brd-rd50"
                      src="assets/images/resources/profile-thumb2.jpg"
                      alt="profile-thumb2.jpg"
                      itemprop="image"
                    />
                    <img
                      className="brd-rd50"
                      src="assets/images/resources/profile-thumb3.jpg"
                      alt="profile-thumb3.jpg"
                      itemprop="image"
                    />
                    <img
                      className="brd-rd50"
                      src="assets/images/resources/profile-thumb4.jpg"
                      alt="profile-thumb4.jpg"
                      itemprop="image"
                    />
                    <img
                      className="brd-rd50"
                      src="assets/images/resources/profile-thumb5.jpg"
                      alt="profile-thumb5.jpg"
                      itemprop="image"
                    />
                  </div> */}
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-lg-8">
                <div className="profile-info-form-wrap">
                  <form className="profile-info-form" onSubmit={handleSubmit(handleSubmitAccount)}>
                    <div className="row mrg20">
                      {/* Complete Name */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Complete Name <sup>*</sup>
                        </label>
                        <input {...register("completename")} name="completename"
                          className="brd-rd3"
                          type="text"
                          placeholder="Enter Your Name"
                        />
                        <p className="text-danger">{errors.completename?.message}</p>

                      </div>
                      {/* Email */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Email Address <sup>*</sup>
                        </label>
                        <input {...register("email")} name="email"
                          className="brd-rd3"
                          type="email"
                          placeholder="Enter Your Email Address"
                        />
                        <p className="text-danger">{errors.email?.message}</p>
                      </div>
                      {/* Phone No */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Phone No <sup>*</sup>
                        </label>
                        <input {...register("phone")} name="phone"
                          className="brd-rd3"
                          type="text"
                          placeholder="Enter Your Phone No"
                        />
                        <p className="text-danger">{errors.phone?.message}</p>
                      </div>
                      {/* WhatsappNo */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Whatsapp No <sup>*</sup>
                        </label>
                        <input {...register("whatsappNo")} name="whatsappNo"
                          className="brd-rd3"
                          type="text"
                          placeholder="Enter Your Whatsapp No"
                        />
                        <p className="text-danger">{errors.whatsappNo?.message}</p>
                      </div>
                      {/* Gender */}
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <label>
                          Gender <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlGender")} name="ddlGender" className="selectControlPortal">
                            <option value=''>Select Gender</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            <option value='Others'>Others</option>
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlGender?.message}</p>
                        </div>
                      </div>
                      {/* Experience */}
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <label>
                          Cooking experience <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlExperience")} name="ddlExperience" style={{ width: '175px' }} className="selectControlPortal">
                            <option value=''>Select Experience?</option>
                            <option value='1'>Less than 1 Year</option>
                            <option value='2'>1 Year</option>
                            <option value='3'>2 Years</option>
                            <option value='4'>3 Years</option>
                            <option value='5'>4 Years</option>
                            <option value='6'>5 Years</option>
                            <option value='7'>6 Years</option>
                            <option value='8'>7 Years</option>
                            <option value='9'>8 Years</option>
                            <option value='10'>9 Years</option>
                            <option value='11'>10 Years</option>
                            <option value='12'>More than 10 years</option>

                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlExperience?.message}</p>
                        </div>
                      </div>
                      {/* Nationality */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Nationality <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlNationality")} name="ddlNationality" style={{ width: '375px' }} className="selectControlPortal">
                            <option value='' selected>Select Nationality</option>
                            {
                              _countryListDDLR?.map((value, index) => (
                                <option value={value.countryId} key={index}>{value.countryName}</option>
                              ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlNationality?.message}</p>
                        </div>
                      </div>
                      {/* Country */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Country <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlCountry")} name="ddlCountry" style={{ width: '375px' }} onChange={(e) => { onChangeCountry(e.target.value) }} className="selectControlPortal">
                            <option value='' selected>Select Country</option>
                            {
                              _countryListDDLR?.map((value, index) => (
                                <option value={value.countryId} key={index}>{value.countryName}</option>
                              ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlCountry?.message}</p>
                        </div>
                      </div>
                      {/* State
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <label>
                          State <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlState")} name="ddlState">
                            <option value='' selected>Select State</option>
                            <option value='1'>USA</option>
                            <option value='2'>Spain</option>
                            <option value='3'>Dubai</option>
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlState?.message}</p>
                        </div>
                      </div>
                       */}
                      {/* City */}
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <label>
                          City <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlCity")} name="ddlCity" onChange={(e) => { onChangeCity(e.target.value) }} className="selectControlPortal">
                            <option value='' selected>Select City</option>
                            {
                              _selectedCitiesDDLR?.map((value, index) => (
                                <option value={value.cityId} key={index}>{value.cityName}</option>
                              ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlCity?.message}</p>
                        </div>
                      </div>
                      {/* Area */}
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <label>
                          Area <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlArea")} name="ddlArea" style={{ width: '175px' }} className="selectControlPortal">
                            <option value='' selected>Select Area</option>
                            {_selectedAreasDDLR?.map((value, index) => (
                              <option value={value.areaId} key={index}>{value.areaName}</option>
                            ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlArea?.message}</p>
                        </div>
                      </div>
                      {/* Address */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Address <sup>* (provide your exact address for your customers)</sup>
                        </label>
                        <input {...register("address")} name="address"
                          className="brd-rd3"
                          type="text" aria-multiline="true"
                          placeholder="Type your address"
                        />
                        <p className="text-danger">{errors.address?.message}</p>
                      </div>
                      {/* FacebookLink */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Facebook Link <sup>(optional)</sup>
                        </label>
                        <input {...register("facebookLink")} name="facebookLink"
                          className="brd-rd3"
                          type="text"
                          placeholder="Paste your facebook link here.."
                        />
                        <p className="text-danger">{errors.facebookLink?.message}</p>
                      </div>
                      {/* InstagramLink */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Instagram Link  <sup>(optional)</sup>
                        </label>
                        <input {...register("instaLink")} name="instaLink"
                          className="brd-rd3"
                          type="text"
                          placeholder="Paste your instagram link here.."
                        />
                      </div>
                      {/* YoutubeLink */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Youtube Link <sup>(optional)</sup>
                        </label>
                        <input {...register("youtubeLink")} name="youtubeLink"
                          className="brd-rd3"
                          type="text"
                          placeholder="Paste your youtube link here.."
                        />
                      </div>
                    </div>
                    {isLoading &&
                      <div className='cover-spin'>
                        <h4 className='spin-waiting-msg'>Your account information is updating...</h4>
                      </div>
                    }
                    <div className="col-md-12 col-sm-12 col-lg-12">
                      <button type="submit" className="theme-btn-secondary mt-20" style={{ float: 'right' }} >
                        Update <span></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChefAccount;