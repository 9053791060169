import react from 'react'
import { Component } from 'react';
import LoginForm from './LoginForm';

class Login extends Component{
    render(){
        return(
           <LoginForm/>
        )
    }
}

export default Login;