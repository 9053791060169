import { React, useEffect, useState } from "react";
import { ReactComponent as Phone } from "../../svg/phone-call.svg";
import { ReactComponent as Email } from "../../svg/mail.svg";
import ChefRegistrationModel from "../Models/ChefRegistrationModel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector, connect } from "react-redux";
import { useHistory, useParams, useNavigate } from "react-router-dom";
import { RegisterChef } from "../../redux/ActionsCreator/chefAuthService";
import { fetchChefs } from '../../redux/ActionsCreator/registerService';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { RegisterUser } from "../../redux/ActionsCreator/userAuthService";
import UserRegistrationModel from "../Models/UserRegistrationModel";
import AlertModel from '../Models/AlertModel';
import AlertPopup from '../General/AlertPopup';
import Global from "../../global";

const schema = yup.object().shape({
    typeselect: yup.string().required('Type is required field'),
    completename: yup.string().required('Complete name is required').min(8, 'Complete name must be at least 8 characters'),//.uppercase('First letter should be upper case'),
    email: yup.string().email().required('Email is required field'),
    password: yup.string().required('Password is required field').min(3),
    confirmpassword: yup.string().required('Confirm Password is required field').oneOf([yup.ref('password')], 'Passwords does not match'),
    phone: yup.string().required('Phone is required field').min(10).max(13, 'Phone number must be at most 13 charactors')
});

const Registration = (props) => {

    const { register, handleSubmit, formState: { errors }, reset, setFocus, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    var AlertOptions = new AlertModel();

    let SignUpModel;
    let navigate = useNavigate();

    const dispatch = useDispatch();

    const [loginError, setLoginError] = useState('');
    const [phoneCode, setPhoneCode] = useState()

    const [alertOptions, setAlertOptions] = useState(AlertOptions);


    const _isLoading = useSelector((state) => state.GeneralReducer.isLoading);
    const _chefListRR = useSelector((state) => state.RegisterReducer.chefListRR);


    // useEffect(() => {

    //     if (localStorage.IsAuthenticated === 'true') {
    //         navigate('/Questionnaire');
    //     }
    // });

    const IsChefEmailExist = (Email) => {

        if (Email !== undefined && Email !== '' && Email != null) {
            dispatch(fetchChefs());
            const chefListRR = _chefListRR;
            if (chefListRR !== null && chefListRR.length > 0) {
                // Use the some() method to check if any chef has the specified email
                const emailExists = chefListRR.find(x => x.email === Email)?.email.length > 0;

                if (emailExists) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    const IsChefMobileExist = (Phone) => {

        if (Phone !== undefined && Phone !== '' && Phone != null) {
            dispatch(fetchChefs());
            const chefListRR = _chefListRR;
            if (chefListRR !== null && chefListRR.length > 0) {

                const phoneExists = chefListRR.find(x => x.phone === Phone)?.phone.length > 0;

                if (phoneExists) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }


    const onSubmitHandler = (data) => {
        try {


            let caller;



            // Conditionally set the caller based on UserTypeId
            if (data.typeselect === '3') {

                SignUpModel = new UserRegistrationModel();

                SignUpModel.Name = data.completename;
                SignUpModel.Email = data.email;
                SignUpModel.Password = data.password;
                SignUpModel.Phone = data.phone;
                SignUpModel.UserTypeId = data.typeselect;
                SignUpModel.Longitude = localStorage?.Longitude;
                SignUpModel.Latitude = localStorage?.Latitude;

                caller = RegisterUser(navigate, SignUpModel);

            } else {

                SignUpModel = new ChefRegistrationModel();

                SignUpModel.FullName = data.completename;
                SignUpModel.Email = data.email;
                SignUpModel.Password = data.password;
                SignUpModel.Phone = data.phone;
                SignUpModel.UserTypeId = data.typeselect;
                SignUpModel.Longitude = localStorage?.Longitude;
                SignUpModel.Latitude = localStorage?.Latitude;


                /*
                // Check if the email already exists
                if (IsChefEmailExist(SignUpModel.Email)) {
                    alert("The email you've entered is already registered in our database");
                    return;
                }

                // Check if the mobile number already exists
                if (IsChefMobileExist(SignUpModel.Phone)) {
                    alert("The mobile number you've entered is already registered in our database");
                    return;
                }
*/
                caller = RegisterChef(navigate, SignUpModel);
            }

            dispatch(caller);


            // setTimeout(() => {
            //     setValue('typeselect', '');
            //     setValue('completename', '');
            //     setValue('email', '');
            //     setValue('password', '');
            //     setValue('confirmpassword', '');
            //     setValue('phone', '');

            //     setIsLoading(false);

            //     AlertOptions.Type = Global.AlertPopupType.SimpleAlert;
            //     AlertOptions.Message = "Congratulations! 🎉 You're now part of the Brown Onions family, where culinary adventures come to life! Thank you for joining us."
            //     AlertOptions.Variant = Global.AlertVariantType.Success;

            //     localStorage.setItem(Global.SessionKey.IsAuthenticated, false)
            //     setAlertOptions(AlertOptions);
            //     window.scrollTo(0, 40);

            // }, 2500);

        } catch (err) {
            console.log(err);
        }
    };



    return (
        <section className="pt-110 pb-70">
            <div className="container">
                <div className="row">
                    <AlertPopup alertOptions={alertOptions}></AlertPopup>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <h4 >🎉 Why Join Brown Onions?</h4>
                        <br></br>
                        <p className="fs-13" style={{ textAlign: 'justify' }}>
                            Join Brown Onions and let your culinary creations shine! Boost your restaurant's visibility, reach new customers, and enjoy an effortless online presence. Become part of a platform that celebrates local flavors and personalized dining experiences.
                        </p>
                        <div>
                            <ul style={{ fontSize: '15px', textAlign: 'justify' }}>
                                <li>
                                    <strong>Showcase Your Flavors:</strong> Feature your culinary expertise on Brown Onions, reaching a wider local audience eager to discover and savor unique dishes.
                                </li>
                                <li> <strong>Expand Your Reach:</strong> Attract new customers beyond traditional marketing, connecting with local food enthusiasts actively seeking personalized culinary experiences.</li>
                                <li> <strong>Simplify Online Presence:</strong> Effortlessly establish an online presence with Brown Onions, allowing you to focus on creating exceptional dishes while streamlining the online ordering process for both you and your customers.</li>
                            </ul>
                        </div>


                        {/* <p className="fs-13" style={{ textAlign: 'justify' }}>
                            Get ready for a personalized food mess experience like never before. Your local area chefs & restaurants   are gearing up to bring deliciousness within a 1000-meter radius to your doorstep.
                        </p>
                        <div>
                            <ul style={{ fontSize: '15px', textAlign: 'justify' }}>
                                <li>
                                    <strong>Tailored Delights:</strong> Your taste, your way. Choose from a variety of local chefs and cuisines.
                                </li>
                                <li> <strong>Hassle-Free Ordering:</strong> Pre-schedule your favorite meals, and we'll handle the rest.</li>
                                <li> <strong>Exclusive Access:</strong> Be part of a culinary community, and enjoy special perks as an early member.</li>
                            </ul>
                        </div> */}
                        <br></br>
                        <div className="contact-icon-box d-flex align-items-center">
                            <Phone />
                            <strong className="text-gray fs-15">Phone: </strong>
                            <span className="text-gray fs-15">( +971 ) 501-639632</span>
                        </div>
                        <div className="contact-icon-box d-flex align-items-center">
                            <Email />
                            <strong className="text-gray fs-15">Email: </strong>
                            <span className="text-gray fs-15">contact@brownonions.co</span>
                        </div>
                        <ul className="contact-social list-unstyled d-flex mt-30 mb-0">
                            <li>
                                <a
                                    href="https://www.facebook.com/brownonions"
                                    className=" d-inline-block text-center"
                                    style={{ backgroundColor: "#365dce" }}
                                >
                                    <i className="fa fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/brownonions63"
                                    className=" d-inline-block text-center"
                                    style={{ backgroundColor: "#365dce" }}
                                >
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    href="javascript:void(0)"
                                    className=" d-inline-block text-center"
                                    style={{ backgroundColor: "#e62d31" }}
                                >
                                    <i className="fa fa-google-plus"></i>
                                </a>
                            </li> */}
                            {/* <li>
                                <a
                                    href="javascript:void(0)"
                                    className=" d-inline-block text-center"
                                    style={{ backgroundColor: "#36c9e4" }}
                                >
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li> */}
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/brownonions/"
                                    className=" d-inline-block text-center"
                                    style={{ backgroundColor: "#0f9cd6" }}
                                >
                                    <i className="fa fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-1 col-md-1">
                        <hr style={{ height: '100%', border: 'none', borderLeft: '1px solid #333', margin: '0 10px' }}></hr>
                    </div>
                    <div className="col-lg-5 col-md-5">
                        <h4> Sign Up </h4>
                        <p className="text-danger">{loginError}</p>
                        <form className="contact-form" onSubmit={handleSubmit(onSubmitHandler)}>

                            <select className="mb-3 w-100 formControlPublic" type="select" {...register("typeselect")} name="typeselect">
                                <option value=''>Select Your Type</option>
                                <option value='1'>I am a home chef</option>
                                <option value='2'>I am the owner of my restraunt</option>
                                <option value='3'>I am a user who need a chef for the mess/food</option>
                            </select>
                            <p className="text-danger">{errors.typeselect?.message}</p>

                            <input {...register("completename")} className="w-100 formControlPublic" type="text" name="completename" placeholder="Complete Name (User, Chef, Restraunt/Shop)" />
                            <p className="text-danger">{errors.completename?.message}</p>

                            <input {...register("email")} className="w-100 formControlPublic" type="email" name="email" placeholder="Email Address" />
                            <p className="text-danger">{errors.email?.message}</p>

                            <div style={{ 'display': 'inline' }}>
                                {/* <div className="col-md-2 col-sm-12 col-lg-2">
                                    <div style={{ 'width': '100px' }}>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="AE"
                                            value={phoneCode}
                                            onChange={setPhoneCode}
                                            className="w-20"
                                            maxLength='0'
                                        />
                                    </div>
                                </div> */}
                                <div className="col-md-12 col-sm-12 col-lg-12">
                                    <input {...register("phone")} className="w-100 formControlPublic" type="number" name="phone" placeholder="Phone No" maxLength="13" />
                                    <p className="text-danger">{errors.phone?.message}</p>
                                </div>
                            </div>



                            <input  {...register("password")} className="w-100 formControlPublic" type="password" name="password" placeholder="Password" />
                            <p className="text-danger">{errors.password?.message}</p>

                            <input  {...register("confirmpassword")} className="w-100 formControlPublic" type="password" name="confirmpassword" placeholder="Confirm Password" />
                            <p className="text-danger">{errors.confirmpassword?.message}</p>

                            {_isLoading &&
                                <div className='cover-spin'>

                                </div>
                            }

                            <button type="submit" className="theme-btn-secondary mt-20" >
                                Sign Up <span></span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Registration;

