import { AuthActionTypes as Type,  UserRegisterActionTypes, GeneralActionTypes } from "../../ActionTypes/actionTypes";
import Global from "../../../global";

let user = localStorage.getItem(Global.SessionKey.LoginUserModel);
//console.log("User model from session (UserAuthReducer) from session: ", user);
user = JSON.parse(user);

const initialState = {
  user: user ? user : null,
  isLoggedIn: user ? true : false,
  isLoading: false,
}

export default function UserAuthReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case Type.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
        isLoading: false
      };
    case Type.USER_LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoading: false
      };
    case Type.USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoading: false
      };
    case Type.USER_REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
        isLoading: false
      };
    case Type.USER_REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false
      };
    case UserRegisterActionTypes.UPDATE_USER:
      return {
        ...state,
        user: payload,
        isLoading: false
      };
    default:
      return state;
  }
}