import { ChefRecipeActionTypes as Type } from "../../ActionTypes/actionTypes";

const initialState = {

    fetchChefSingleRecipeCRR: [],
    fetchChefAllRecipesCRR: [],
    fetchAllChefsRecipesCRR: [],
    notifyReturnModelCRR: {},
    fetchAutoCompleteRecipeNamesCRR: []
}

function ChefRecipeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case Type.PUBLISH_CHEF_RECIPE:
            return {
                ...state,
                notifyReturnModelCRR: payload,
            };
        case Type.UPDATE_CHEF_RECIPE:
            return {
                ...state,
                notifyReturnModelCRR: payload
            };
        case Type.FETCH_CHEF_SINGLE_RECIPE:
            return {
                ...state,
                fetchChefSingleRecipeCRR: payload
            };
        case Type.FETCH_CHEF_ALL_RECIPES:
            return {
                ...state,
                fetchChefAllRecipesCRR: payload
            };
        case Type.FETCH_ALL_CHEFS_RECIPES:
            return {
                ...state,
                fetchAllChefsRecipesCRR: payload
            };
        case Type.FETCH_AUTO_COMPLETE_RECIPE_NAMES:
            return {
                ...state,
                fetchAutoCompleteRecipeNamesCRR: payload
            };
       
        default:
            return state;

    }
}

export default ChefRecipeReducer;