import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChefLogout } from "../../redux/ActionsCreator/chefAuthService";
import { useNavigate } from "react-router-dom";
import Global from "../../global";
import { UserLogout } from "../../redux/ActionsCreator/userAuthService";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {

  let logOutTime = Global.AppKeys.LogOutTimeInMinutes * 60000;
  let timer;
  let navigate = useNavigate();

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, logOutTime); // logOutTime in milli seconds.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 20 mintutes, that is app is inactive, the app automatically logs out.
  /* useEffect(() => {
 
     Object.values(events).forEach((item) => {
       window.addEventListener(item, () => {
         resetTimer();
         handleLogoutTimer();
       });
     });
   }, []);
   */

  useEffect(() => {
    const handleActivity = () => {
      resetTimer();
      handleLogoutTimer();
    };

    events.forEach((item) => {
      window.addEventListener(item, handleActivity);
    });

    return () => {
      resetTimer();
      events.forEach((item) => {
        window.removeEventListener(item, handleActivity);
      });
    };
  }, []);

  //const IsAuthenticated = useSelector(state => state.SessionReducer.authenticated);
  const loginType = useSelector((state) => state.SessionReducer.user.loginType);

  const dispatch = useDispatch();
  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {

    if (localStorage.IsAuthenticated === "true") {

      if (loginType) {
        if (loginType === "chef") {
          dispatch(ChefLogout());
        } else if (loginType === "user") {
          dispatch(UserLogout());
        }

        sessionStorage.clear();
        localStorage.clear();
        navigate('/Login');
      }
    }
  };

  return children;
};

export default AppLogout;
