
import ImageModel from '../Models/ImageModel'


export default class ChefRegistrationModel {
  Id;
  ChefId;
  ChefCode;
  FullName;
  ShortName;
  Experience;
  Phone;
  WhatsappNo;
  IsActiveFlag;
  Email;
  Password;
  Address;
  FacebookLink;
  InstaLink;
  YoutubeLink;
  Gender;
  UserTypeId;
  NationalityId;
  CountryId;
  StateId;
  CityId;
  AreaId;
  Latitude;
  Longitude;
  IsDeliveryAvailable;
  IsProfileLive;
  IsEmailVerified;
  IsPhoneVerified;
  CreatedDate;
  CreatedBy;
  ModifiedDate;
  ModifiedBy;
  DTOImage;
  Nationality;
  Country;
  State;
  City;
  Area;
  UserType;
  DTOImage = new ImageModel()

}