import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { applyMiddleware, compose } from 'redux'
import monitorReducersEnhancer from './enhancers/monitorReducer'
import loggerMiddleware from './middleware/logger'
import rootReducer from "./redux/Reducers/rootReducer";
import thunkMiddleware from 'redux-thunk'

export default function configureAppStore(preloadedState) {

     const middlewares = [loggerMiddleware, thunkMiddleware]
     const middlewareEnhancer = applyMiddleware(middlewares)
  
    const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
    const composedEnhancers = compose(...enhancers)
   
    const fullyComposeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composedEnhancers;

  const store = configureStore({
    reducer: rootReducer,
    middleware: [loggerMiddleware,thunkMiddleware, ...getDefaultMiddleware({ immutableCheck: false })],
    preloadedState,
    enhancers: [monitorReducersEnhancer]
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./redux/Reducers/rootReducer', () => store.replaceReducer(rootReducer))
  }

  return store
}