import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header2 from "../header/Header2";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { AddSurvey, FetchQuestionnaire } from '../../redux/ActionsCreator/promotionService';
import SurveyModel from '../Models/SurveyModel';
import AlertModel from '../Models/AlertModel';
import AlertPopup from '../General/AlertPopup';
import Global from "../../global";
import { useNavigate } from "react-router-dom";
import { ChefLogout } from '../../redux/ActionsCreator/chefAuthService';

const Questionnaire = () => {

    const dispatch = useDispatch();
    var AlertOptions = new AlertModel();
    let navigate = useNavigate();
    var Model = new SurveyModel();

    const [alertOptions, setAlertOptions] = useState(AlertOptions);
    const [answers, setAnswers] = useState({
        questionnaireId_1: '',
        questionnaireId_2: '',
        questionnaireId_3: '',
        questionnaireId_4: '',
        questionnaireId_5: '',
        questionnaireId_6: '',
        questionnaireId_7: '',
        questionnaireId_8: '',
        questionnaireId_9: '',
        questionnaireId_10: '',
        questionnaireId_11: '',
        questionnaireId_12: '',
    });
    const [language, setLanguage] = useState(0);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [isErr, setIsErr] = React.useState(false);


    const _apiHeader = useSelector((state) => state.ChefAuthReducer.chef?.apiHeader);
    const _chefId = useSelector((state) => state.ChefAuthReducer.chef?.chefId);
    const _userId = useSelector((state) => state.UserAuthReducer.user?.userId);
    const _fetchQuestionnaires = useSelector((state) => state.PromotionReducer.fetchSurveyQuestionnairePR);
    const _isSurveyAdded = useSelector((state) => state.PromotionReducer.isSurveyAdded);
    const _isLoading = useSelector((state) => state.GeneralReducer.isLoading);
    const _errorMsg = useSelector((state) => state.GeneralReducer.message);




    useEffect(() => {
        console.log('_fetchQuestionnaires : ', _fetchQuestionnaires);
    }, [_fetchQuestionnaires]);

    // Event handler to update answers state
    const handleAnswerChange = (question, value) => {
        debugger;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [question]: value
        }));
    };



    const onChangeLanguage = (e) => {
        if (e.target.value !== '' && e.target.value !== null) {
            setLanguage(e.target.value)
            console.log('User Language:', e.target.value);
            dispatch(FetchQuestionnaire(navigate,1, e.target.value));

            if (e.target.value === '1' || e.target.value === '4') {
                document.getElementById("divQuestionnaire").style.direction = "rtl";
            }
            else {
                document.getElementById("divQuestionnaire").style.direction = "ltr";
            }
        }
    };

    // Event handler for submit button
    const handleSubmit = () => {

        if (!localStorage.APIHeader) {
            navigate("/"); // Redirect to signup screen
            return;
        }
    
        setIsLoadingSubmit(true);
    
        console.log('User Answers:', answers);
    
        setIsErr(false);
    
        const requiredQuestionnaireIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const missingQuestionnaireIds = requiredQuestionnaireIds.filter(id => !answers[`questionnaireId_${id}`]);

        if (missingQuestionnaireIds.length > 0) {
            setIsErr(true);
            setIsLoadingSubmit(false);
            AlertOptions.Type = Global.AlertPopupType.SimpleAlert;
            AlertOptions.Variant = Global.AlertVariantType.Danger;
            AlertOptions.Message = "Please answer all questions before submitting the survey. Thank you!";
            setAlertOptions(AlertOptions);
            window.scrollTo(0, 20);
            return;
        }
    
        const lstQuestionnaire = requiredQuestionnaireIds.map(id => {
            const Model = new SurveyModel();
            Model.QuestionnaireId = id;
            Model.IsYes = answers[`questionnaireId_${id}`] === "Yes";
            Model.ChefId = _chefId;
            Model.UserId = _userId;
            Model.Answer = id === 11 ? answers.questionnaireId_11 : "";
            return Model;
        });
    
        console.log('Survey result : ', lstQuestionnaire);
        dispatch(AddSurvey(navigate, lstQuestionnaire));
    };
    

    useEffect(() => {




        if (parseInt(localStorage.rowEffected) > Global.eResponse.Default) {

            setIsLoadingSubmit(false);

            navigate('/ThankyouPage');
            // AlertOptions.Type = Global.AlertPopupType.SimpleAlert;
            // AlertOptions.Message = "Thank you for the Survey !"
            // AlertOptions.Variant = Global.AlertVariantType.Success;

             localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
            // setAlertOptions(AlertOptions);
            // window.scrollTo(0, 20);
            dispatch(ChefLogout());
        }
        else if (parseInt(localStorage.rowEffected) == Global.eResponse.Error) {

            setIsLoadingSubmit(false);

            AlertOptions.Type = Global.AlertPopupType.AdditionalContentAlert;
            AlertOptions.Variant = Global.AlertVariantType.Danger;
            AlertOptions.Heading = "Oh ! You got an error!"
            AlertOptions.Message = _errorMsg;

            localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
            setAlertOptions(AlertOptions);
            window.scrollTo(0, 20);
        }
    });

    return (

        <React.Fragment>
            <Header2 />
            <div className="container">
                {(isErr || _isSurveyAdded) && (
                    <AlertPopup alertOptions={alertOptions}></AlertPopup>
                )}
                <div class="row ">
                    <div className="col-md-12 col-sm-12 col-lg-12" >
                        <strong style={{ fontSize: '25px', fontFamily: 'system-ui' }}>Restaurants Survey</strong>
                    </div>
                </div>

                <br></br>
                <div class="row ">
                    <div className="col-md-12 col-sm-12 col-lg-12" >
                        <select className="mb-3 w-100 formControlPublic" type="select" name="typeselect"
                            //    onChange={(e) => setLanguage(e.target.value)} 
                            onChange={onChangeLanguage}
                        >
                            <option value=''>Select Language</option>
                            <option value='1'>Arabic</option>
                            <option value='2'>English</option>
                            <option value='3'>Malayalam</option>
                            <option value='4'>Urdu</option>
                        </select>
                    </div>
                </div>
                <br></br>
                {/* <Banner title="Restaurant Survey" /> */}
                <div id='divQuestionnaire'>
                    {_isLoading && !isLoadingSubmit ? (
                        <div className='cover-spin'>
                            <h4 className='spin-waiting-msg'>Please wait.. Survey is loading.</h4>
                        </div>
                    ) : _fetchQuestionnaires.length > 0 ? (

                        _fetchQuestionnaires.map((value, index) => (

                            <div key={index} >
                                <div id={"questionnaireId_" + value.questionnaireId} key={index} className="" >
                                    <div>
                                        <div class="row ">
                                            <div className="col-md-12 col-sm-12 col-lg-12" >
                                                <FormControl>
                                                    <div>
                                                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ fontWeight: 'bold' }}>{value.question}</FormLabel>
                                                    </div>
                                                    {
                                                        value.orderNo === 12 ? (
                                                            <textarea value={answers["questionnaireId_" + value.questionnaireId]} onChange={(e) => handleAnswerChange("questionnaireId_" + value.questionnaireId, e.target.value)}></textarea>

                                                        ) : (
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={answers["questionnaireId_" + value.questionnaireId]}
                                                                onChange={(e) => handleAnswerChange("questionnaireId_" + value.questionnaireId, e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        )
                                                    }

                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {index % 2 === 1 && index + 1 !== _fetchQuestionnaires.length && <br></br>}
                            </div>
                        ))
                    ) : (
                        <p>No questionnaire found.</p>
                    )}
                </div>
                <br></br>
                <div className="col-md-12 col-sm-12 col-lg-12">
                    <button type="button" id='btnSearch' className="theme-btn-secondary" style={{ marginLeft: '42%' }} onClick={handleSubmit}>
                        Submit <span></span>
                    </button>
                </div>
                {isLoadingSubmit &&
                    <div className='cover-spin'>
                        <h4 className='spin-waiting-msg'>Thank you for your time ! Survey is being saving...</h4>
                    </div>
                }
            </div>
            <br></br>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default Questionnaire;