import { UserActionTypes as Type } from "../../ActionTypes/actionTypes";

const initialState = {

    fetchMasterSearchUR: [],
    fetchAllUserMessesSchdeulesOfSingleMstrMessUR: {},
    fetchSingleUserMessSchedulesOfChefUR: {},
    fetchRecipeSearchCriteriaUR: {},
    fetchMatchingFoodCriteriaOfUserUR: [],
    fetchAllBookedMessListOfOneUserUR: [],
    fetchMyChefsUR: [],
    searchText:""

}

function UserReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case Type.FETCH_MASTER_SEARCH:
            return {
                ...state,
                fetchMasterSearchUR: payload,
            };
        case Type.FETCH_ALL_USER__MESSES_SCHEDULES_OF_SINGLE_MASTER_MESS:
            return {
                ...state,
                fetchAllUserMessesSchdeulesOfSingleMstrMessUR: payload,
            };
        case Type.FETCH_SINGLE_USERMESS_SCHEDULES_OF_CHEF:
            return {
                ...state,
                fetchSingleUserMessSchedulesOfChefUR: payload,
            };
        case Type.FETCH_RECIPE_SEARCH_CRITERIA:
            return {
                ...state,
                fetchRecipeSearchCriteriaUR: payload,
            };
        case Type.FETCH_MATCHING_FOOD_CRITERIA_OF_USER:
            return {
                ...state,
                fetchMatchingFoodCriteriaOfUserUR: payload,
            };
        case Type.FETCH_ALL_BOOKED_MESSLIST_OF_ONE_USER:
            return {
                ...state,
                fetchAllBookedMessListOfOneUserUR: payload,
            };
        case Type.FETCH_MY_CHEFS:
            return {
                ...state,
                fetchMyChefsUR: payload,
            };
        case Type.ADD_MASTER_SEARCH:
            return {
                ...state,
                fetchMasterSearchUR: [...state.fetchMasterSearchUR, payload]
            };
        case Type.UPDATE_MASTER_SEARCH:

            const { searchId } = payload;
            const updatedIndex = state.fetchMasterSearchUR.findIndex(search => search.searchId === searchId);

            if (updatedIndex !== -1) {
                const updatedMstrSearch = [
                    ...state.fetchMasterSearchUR.slice(0, updatedIndex),
                    payload,
                    ...state.fetchMasterSearchUR.slice(updatedIndex + 1)
                ];

                return {
                    ...state,
                    fetchMasterSearchUR: updatedMstrSearch
                };
            } else {
                return state; // Return the original state if no item is found to update
            }


            // Filter out the deleted search from the fetchMasterSearchUR list
            const updatedUser = state.fetchMasterSearchUR.filter(search => search.searchId !== searchId);

            return {
                ...state,
                fetchMasterSearchUR: updatedUser
            };
        case Type.DIACTIVE_MASTER_SEARCH:

            // Update the status of the specific search within the fetchMasterSearchUR list
            const updatedSearchUS = state.fetchMasterSearchUR.map(search => {

                const { searchId, isActive } = payload; // Assuming the payload contains the ID of the search and the new status

                if (search.searchId === searchId) {
                    return {
                        ...search,
                        isActive: isActive // Update the status with the newStatus provided in the payload
                    };
                }
                return search;
            });
            return {
                ...state,
                fetchMasterSearchUR: updatedSearchUS
            };

            return {
                ...state,
                fetchUserList: payload
            };
        case Type.ADD_FOOD_MESS_SCHEDULE_OF_USER:
            debugger;
            const { messId, userMessesList, userMessModel } = payload;
            // Create a shallow copy of the state
            const updatedMess = { ...state.fetchAllBookedMessListOfOneUserUR };

            if (updatedMess[messId]) {
                // If the MessId exists, update the userMessesList and userMessModel
                const updatedMesses = { ...updatedMess[messId] };

                updatedMesses.messId = payload.messId;
                updatedMesses.userId = payload.userId;
                updatedMesses.chefId = payload.chefId;
                updatedMesses.chefName = payload.chefName;
                updatedMesses.messTypeId = payload.messTypeId;
                updatedMesses.messType = payload.messType;
                updatedMesses.isDelivery = payload.isDelivery;
                updatedMesses.isActive = payload.isActive;
                updatedMesses.createdDate = payload.createdDate;
                updatedMesses.userMessModel = userMessModel;

                const updatedUserMesses = { ...updatedMesses[userMessModel.userMessId] };

                if (updatedUserMesses) {
                    updatedMesses.userMessesList[userMessModel.userMessId] = userMessModel;
                }
                else {
                    updatedMesses.userMessesList = [...userMessesList, userMessModel];
                }
                updatedMess[messId] = updatedMesses;
            } else {
                // If the MessId doesn't exist, create a new entry
                updatedMess[messId] = {
                    messId,
                    userId: payload.userId,
                    chefId: payload.chefId,
                    chefName: payload.chefName,
                    messTypeId: payload.messTypeId,
                    messType: payload.messType,
                    isDelivery: payload.isDelivery,
                    isActive: payload.isActive,
                    createdDate: payload.createdDate,
                    modifiedDate: payload.modifiedDate,
                    userMessesList: [...userMessesList, userMessModel],
                    userMessModel
                };
            }
            console.log('user reducer (ADD_FOOD_MESS_SCHEDULE_OF_USER) state: ', updatedMess)
            return {
                ...state,
                fetchAllBookedMessListOfOneUserUR: updatedMess
            };
        case Type.SEARCH_TEXT:
                return {
                    ...state,
                    searchText: payload,
                };
        default:
            return state;

    }
}

export default UserReducer;