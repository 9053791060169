import React from "react";
import Modal from "react-modal";
import CustomerReviews from "./customer-reviews";
import Button from 'react-bootstrap/Button'
import table from "../../../../../src/assets/css/table.css";


const customStyles = {
  content: {
    width: '90%', // Adjust the width as needed
    margin: 'auto', // Center the modal horizontally
  },
};

const ViewRecipeModal = ({ isOpen, closeModal, recipe }) => {
 // console.log('recipe: ', recipe)




  function convertTime(time24) {
    // split the time string into hours and minutes

    var timeParts = '';
    var hours24 = 0;
    var minutes = 0;

    if (typeof time24 === 'string') {
      const timeParts = time24.split(':');
      hours24 = parseInt(timeParts[0], 10);
      minutes = timeParts[1];
    }
    else {
      hours24 = time24;
    }

    // determine if it's AM or PM
    var ampm = hours24 >= 12 ? 'PM' : 'AM';

    // convert from 24-hour to 12-hour format
    var hours12 = hours24 % 12;
    hours12 = hours12 ? hours12 : 12; // the hour '0' should be '12'

    // construct the new time string in 12-hour format
    var time12 = hours12 + ':' + minutes + ' ' + ampm;

    return time12;
  }

  return (
    
    recipe ?
      <div >
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>


          <div className="container">
            <Button onClick={closeModal} style={{ backgroundColor: '#de7a72', borderColor: '#de7a72', float: 'right' }}>Close</Button>
            <div className="recipe-thumb brd-rd5" style={{ position: 'absolute' }}>
              <a href="#" title="" itemprop="url">
                <img
                  src="assets/images/resources/BBLogo-Square.jpeg"
                  alt="order-img1.jpg"
                  itemprop="image"
                  style={{ borderRadius: '0px', width: '182px', height: '161px' }}
                />
              </a>
              <span className="post-rate yellow-bg brd-rd2">
                <i className="fa fa-star-o"></i> 4.25
              </span>
            </div>
            <div className="sec-heading text-center">
              <h2 className="text-uppercase fs-45" style={{ whiteSpace: 'normal', overflow: 'hidden', fontSize: '40px' }}>{recipe.recipeName}</h2>
              <p>by Alex Hales</p>
            </div>
          </div>
          <div className="no-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="position-relative"
                  // style={{
                  //   backgroundImage:
                  //     "url(" + "assets/images/parallax-3.jpg" + ")",
                  // }}
                  >
                    <ul className="recipe-cokng-info-2 d-flex justify-content-center list-unstyled" style={{ background: 'none', width: '70%', margin: 'auto', padding: '40px' }}>

                      <li className="fs-16 text-gray">
                        <span>
                          <svg viewBox="0 0 512 512" height="18" fill="#4fd675">
                            <g>

                              <path d="M498.682,435.326L297.917,234.56L63.357,0H45.026l-3.743,9.511c-9.879,25.104-14.1,50.78-12.205,74.249 c2.16,26.752,12.323,49.913,29.392,66.982L241.58,333.852l24.152-24.152l169.285,189.293c16.84,16.84,45.825,17.84,63.665,0 C516.236,481.439,516.236,452.879,498.682,435.326z" />
                            </g>
                            <g>

                              <path d="M156.728,291.442L13.317,434.853c-17.552,17.552-17.552,46.113,0,63.665c16.674,16.674,45.519,18.146,63.665,0 l143.412-143.412L156.728,291.442z" />
                            </g>
                            <g>

                              <path d="M490.253,85.249l-81.351,81.35l-21.223-21.222l81.351-81.351l-21.222-21.222l-81.35,81.35l-21.222-21.222l81.351-81.35 L405.366,0.361L299.256,106.471c-12.981,12.981-20.732,30.217-21.828,48.535c-0.277,4.641-1.329,9.206-3.074,13.548l68.929,68.929 c4.342-1.747,8.908-2.798,13.548-3.075c18.318-1.093,35.554-8.846,48.535-21.827l106.11-106.109L490.253,85.249z" />
                            </g>
                          </svg>
                        </span>

                        <label style={{ fontSize: '18px' }}>Instant recipe:</label>  <strong style={{ fontSize: '18px' }}>{recipe.isQuickSaleAvailable === true ? "YES" : "NO"}</strong>


                      </li>
                      <li className="fs-16 text-gray">
                        <span>
                          <svg viewBox="0 0 443.294 443.294" height="18" fill="#4fd675">
                            <path d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                            <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                          </svg>
                        </span>

                        <label style={{ fontSize: '18px' }} htmlFor="">Available hours:</label>  <strong style={{ fontSize: '18px' }}>{convertTime(recipe.startHour)} - {convertTime(recipe.endHour)}</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <section className="">
            <div className="container bg-white">
              <div className="row">
                <div className="col-md-12">
                  <div className="special-ingradient d-flex">
                    <div className="col-md-4">
                      <h3>Pricing</h3>
                      <div className="w-100">
                        <table class="striped-table" border='0'>
                          <thead>
                            <tr>
                              <th>Quantity Type</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              recipe.chefRecipeDetails?.map((value, index) => (
                                <tr key={index}>
                                  <td>{value.quantityType}</td>
                                  <td>{value.price + " AED"}</td>
                                </tr>
                              ))
                            }


                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="pl-130">
                        <h3>Recipe Info</h3>
                        <div className="list-unstyled gradient-list ">
                          <div className="d-flex">
                            <div >
                              <div style={{ lineHeight: '50px' }}>
                                <label style={{ fontSize: '18px' }}>Weekdays: </label>
                                <span style={{ color: '#eb8a8e', fontSize: '18px' }}>
                                  {" " + recipe.weekdays}
                                </span>
                                <div>
                                  <label style={{ fontSize: '18px' }}>Food Type: </label>
                                  <span style={{ color: '#eb8a8e', fontSize: '18px' }}>
                                    {" " + recipe.foodType}
                                  </span>
                                </div>
                                <div>
                                  <label style={{ fontSize: '18px' }}>Food Category: </label>
                                  <span style={{ color: '#eb8a8e', fontSize: '18px' }}>
                                    {" " + recipe.foodCategory}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container bg-white">
                <h3>Description</h3>
                <p className="end-descrip">
                  ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                  ex.
                </p>
              </div>
              <section className="sec-space">
                <div className="container">
                  <div className="sec-heading text-center">
                    <span className="text-theme fs-20 d-block mb-2 theme-color">
                      What your Customers Said
                    </span>
                    <h2 className="text-uppercase fs-45 mb-40">Customers Reviews</h2>
                  </div>
                  <div className="testimonial-slider">
                    <CustomerReviews />
                  </div>
                </div>
              </section>
            </div>
          </section>




        </Modal> 
        </div> : ""
  );
};
export default ViewRecipeModal;