import React, { Component } from "react";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header2 from "../header/Header2";
import Registration from "./Registration";
import { ChefDetailTest } from "./ChefDetailTest";
import { useParams } from "react-router-dom";
import GeoLocation from "../General/GeoLocation";
class Signup extends Component{
   
    render(){
     // console.log(this.props.match.params.id);
        return(
            <React.Fragment>
                <Header2 />
                <Banner title="Launching Soon" />
                <Registration />
                <GeoLocation/>
               <Footer></Footer>
            </React.Fragment>
        );
    }
}

export default Signup;