import React from "react";
import { Navigate, Route , useNavigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({ component: Component, ...restOfProps }) => {
 alert('protected route load')
 debugger;
  const isAuthenticated = localStorage.getItem("isLoggedIn");
  
  console.log("this", isAuthenticated);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Navigate to="/Login" />
      }
    />
  );
  // return (
  //   isAuthenticated ? <Outlet /> : <Navigate to="/Login" />
  // );
}

export default ProtectedRoutes;