import React, { useEffect, useState } from 'react';
import Global from '../../global';

const GeoLocation = () => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    localStorage.setItem(Global.SessionKey.Longitude, position.coords.longitude);
                    localStorage.setItem(Global.SessionKey.Latitude, position.coords.latitude);
                   // console.log(position.coords.latitude, position.coords.longitude)
                },
                (error) => {
                    alert('Error getting user location:', error)
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    return (

        <div>
            {
                console.log(latitude, longitude)
            /* <h2>User Location</h2>
            {latitude && longitude ? (
                <p>
                    Latitude: {latitude}, Longitude: {longitude}
                </p>
            ) : (
                <p>Loading...</p>
            )} */}
        </div>
    );
};

export default GeoLocation;
