import { apiHeader, urlGetUserAuthentication, urlRegisterUser } from "../../endpoints";
import Global from "../../global";
import { AuthActionTypes, GeneralActionTypes } from "../ActionTypes/actionTypes"
import axios from 'axios'
import { sessionService } from 'redux-react-session';

export const UserLogin = (userLogin) => {

    return async (dispatch) => {

        sessionService.deleteSession();
        sessionService.deleteUser();

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlGetUserAuthentication}`, {
            method: 'POST',
            headers: apiHeader,
            body: JSON.stringify(userLogin)
        })
            .then((response) => response.json())
            .then((responseData) => {

                // console.log("UserLogin (Services: Response data): ", responseData);

                if (responseData === 'wrong credentials') {
                    dispatch({ type: AuthActionTypes.USER_LOGIN_FAIL })
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: "Your credentials are incorrect, Please provide your valid mobile number or password" })
                    sessionService.invalidateSession();
                    localStorage.setItem(Global.SessionKey.IsAuthenticated, false);
                }
                else {

                    var sessionLoginUserData = {

                        userId: responseData.id,
                        usernName: responseData.user.name,
                        loginType: responseData.loginType,
                        token: responseData.token,
                        guidId: responseData.guidId,
                        mobile: responseData.mobile,
                        email: responseData.email,
                        expiredTime: responseData.expiredTime,
                        validaty: responseData.validaty,
                    }
                    sessionService
                        .saveSession(sessionLoginUserData)
                        .then(() => {
                            sessionService
                                .saveUser(sessionLoginUserData)
                                .then(() => {
                                    const _apiHeader = {

                                        'Authorization': `Bearer ${responseData.token}`,
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                    }
                                    responseData.user.apiHeader = _apiHeader;
                                    localStorage.setItem(Global.SessionKey.LoginUserModel, JSON.stringify(responseData.user));
                                    localStorage.setItem(Global.SessionKey.IsAuthenticated, true);
                                    localStorage.setItem(Global.SessionKey.APIHeader, JSON.stringify(responseData.user.apiHeader));

                                    dispatch({ type: AuthActionTypes.USER_LOGIN_SUCCESS, payload: responseData.user })
                                    dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })

                                })
                                .catch((err) => {
                                    console.error(err)
                                    dispatch({ type: AuthActionTypes.USER_LOGIN_FAIL })
                                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                                });
                        })
                        .catch((err) => {
                            console.error(err)
                            dispatch({ type: AuthActionTypes.USER_LOGIN_FAIL })
                            dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                        });
                }

            })
            .catch((error) => {

                dispatch({ type: AuthActionTypes.USER_LOGIN_FAIL })
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: "Oops! something wrong, Please contact through our email (help@brown-onions.com)" })
                console.log("UserLogin Error: ", error);
            })
    }
}

export const UserLogout = () => {
    // console.log("UserAuthService (Services): UserLogout invoked");
    return (dispatch) => {
        sessionService.deleteSession();
        sessionService.deleteUser();
        dispatch({ type: AuthActionTypes.USER_LOGOUT })
    }
}

export const RegisterUser = (navigate,UserModel) => {

    return async (dispatch) => {
        try {

            dispatch({ type: GeneralActionTypes.START_LOADING })

            const res = await axios.post(`${urlRegisterUser}`, UserModel)
            console.log(res.data)

            let responseData = res.data;

            if (responseData === undefined) {
                let errMsg = 'Unable to received respose from Register API';
                dispatch({ type: AuthActionTypes.USER_REGISTER_FAIL })
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                localStorage.setItem(Global.SessionKey.IsAuthenticated, false);
                return;
            }

            if (responseData === 'wrong credentials') {
                dispatch({ type: AuthActionTypes.USER_REGISTER_FAIL })
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData })
                localStorage.setItem(Global.SessionKey.IsAuthenticated, false);
                // alert('Failed to update the user', responseData);
            }
            else {
                const _apiHeader = {

                    'Authorization': `Bearer ${responseData.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
                responseData.user.apiHeader = _apiHeader;

                var sessionLoginUserData = {

                    userId: responseData.id,
                    usernName: responseData.user.name,
                    loginType: responseData.loginType,
                    token: responseData.token,
                    guidId: responseData.guidId,
                    mobile: responseData.mobile,
                    email: responseData.email,
                    expiredTime: responseData.expiredTime,
                    validaty: responseData.validaty,

                }
                sessionService
                    .saveSession(sessionLoginUserData)
                    .then(() => {
                        sessionService
                            .saveUser(sessionLoginUserData)
                            .then(() => {
                                const _apiHeader = {

                                    'Authorization': `Bearer ${responseData.token}`,
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                }
                                responseData.user.apiHeader = _apiHeader;
                                localStorage.setItem(Global.SessionKey.LoginUserModel, JSON.stringify(responseData.user));
                                localStorage.setItem(Global.SessionKey.IsAuthenticated, true);
                                localStorage.setItem(Global.SessionKey.APIHeader, JSON.stringify(responseData.user.apiHeader));

                                dispatch({ type: AuthActionTypes.USER_REGISTER_SUCCESS, payload: responseData.user })
                                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                                navigate("/Questionnaire");

                            })
                            .catch((err) => {
                                console.error(err)
                                dispatch({ type: AuthActionTypes.USER_REGISTER_FAIL })
                                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                            });
                    })
                    .catch((err) => {
                        console.error(err)
                        dispatch({ type: AuthActionTypes.USER_REGISTER_FAIL })
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                    });
            }
        } catch (err) {
            console.error(err)
            dispatch({ type: AuthActionTypes.USER_REGISTER_FAIL })
            dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
        }
        dispatch({ type: GeneralActionTypes.END_LOADING })
    }
}



