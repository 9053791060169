import { UserActionTypes, GeneralActionTypes } from "../ActionTypes/actionTypes"
import Global from "../../global"
import { apiHeader, urlAddFoodMessScheduleOfUser, urlAddMstrSearch, urlDiActiveMstrSearch, urlGetAllBookedMessListOfOneUser, urlGetAllUserMessesSchedulesOfSingleMstrMess, urlGetMstrSearch, urlGetMyChefs, urlGetSingleUserMessSchedulesOfChef, urlMatchingFoodCriteriaOfUser, urlSearchRecipeCriteria, urlUpdateMstrSearch } from "../../endpoints"

export const FetchMasterSearch = (UserId, SearchId) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetMstrSearch}/${UserId}/${SearchId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: UserActionTypes.FETCH_MASTER_SEARCH, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const AddMstrSearch = (MstrSearchModel) => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlAddMstrSearch}`, {
            method: 'POST',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(MstrSearchModel)
        })
            .then((response) => response.json())
            .then((responseData) => {

                console.log("UserController (Services: Response data): ", responseData);

                if (responseData === undefined) {
                    let errMsg = 'Unable to received respose from UserController API';
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                    console.log('UserController: error ', errMsg);
                    return;
                }
                else {

                    dispatch({ type: UserActionTypes.ADD_MASTER_SEARCH, payload: responseData })
                    localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)

                    if (responseData.rowEffected > 0) {
                        dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                        console.log("Mstr Search added successfully");
                    }
                    else {
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData.errorMsg })
                        console.log("UserController Error: ", responseData.errorMsg);
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const UpdateMstrSearch = (SearchId, MstrSearchModel) => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlUpdateMstrSearch}/${SearchId}`, {
            method: 'PUT',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(MstrSearchModel)
        })
            .then((response) => response.json())
            .then((responseData) => {

                console.log("UserController (Services: Response data): ", responseData);

                if (responseData === undefined) {
                    let errMsg = 'Unable to received respose from UserController API';
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                    console.log('UserController: error ', errMsg);
                    return;
                }
                else {

                    dispatch({ type: UserActionTypes.UPDATE_MASTER_SEARCH, payload: responseData })

                    localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)

                    if (responseData.rowEffected > 0) {

                        dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                        console.log("Mstr Search updated successfully");
                    }
                    else {
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData.errorMsg })
                        console.log("UserController Error: ", responseData.errorMsg);
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const DiActiveMstrSearch = (SearchId, BValue) => {
    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlDiActiveMstrSearch}/${SearchId}/${BValue}`, {
            method: 'POST',
            headers: JSON.parse(localStorage.APIHeader),
        })
            .then((response) => response.json())
            .then((responseData) => {

                console.log("UserController (Services: Response data): ", responseData);

                if (responseData === undefined || responseData === 0) {
                    let errMsg = 'Unable to received respose from UserController API';
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                    console.log('UserController: error ', errMsg);
                    return;
                }
                else {

                    dispatch({ type: UserActionTypes.DIACTIVE_MASTER_SEARCH, payload: responseData })
                    localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)

                    if (responseData.rowEffected > 0) {
                        dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                        console.log("Mstr Search DiActive successfully");
                    }
                    else {
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData.errorMsg })
                        console.log("UserController Error: ", responseData.errorMsg);
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const FetchAllUserMessesSchedulesOfSingleMstrMess = (MessId) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetAllUserMessesSchedulesOfSingleMstrMess}/${MessId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: UserActionTypes.FETCH_ALL_USER__MESSES_SCHEDULES_OF_SINGLE_MASTER_MESS, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const FetchSingleUserMessSchedulesOfChef = (MessId, UserMessId) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetSingleUserMessSchedulesOfChef}/${MessId}/${UserMessId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: UserActionTypes.FETCH_SINGLE_USERMESS_SCHEDULES_OF_CHEF, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const AddFoodMessScheduleOfUser = (MstrMessModel) => {
    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlAddFoodMessScheduleOfUser}`, {
            method: 'POST',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(MstrMessModel)
        })
            .then((response) => response.json())
            .then((responseData) => {

                console.log("UserController (Services: Response data): ", responseData);

                if (responseData === undefined) {
                    let errMsg = 'Unable to received respose from UserController API';
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                    console.log('UserController: error ', errMsg);
                    return;
                }
                else {

                    dispatch({ type: UserActionTypes.ADD_FOOD_MESS_SCHEDULE_OF_USER, payload: responseData })
                    localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)

                    if (responseData.rowEffected > 0) {
                        dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                        console.log("Mstr User Mess Schedule added successfully");
                    }
                    else {
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData.errorMsg })
                        console.log("UserController Error: ", responseData.errorMsg);
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const FetchRecipeSearchCriteria = (MstrSearchModel) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlSearchRecipeCriteria}`, {
            method: 'POST',
            headers: apiHeader,
            body: JSON.stringify(MstrSearchModel)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: UserActionTypes.FETCH_RECIPE_SEARCH_CRITERIA, payload: responseData })
                localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const MatchingFoodCriteriaOfUser = (UserId) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlMatchingFoodCriteriaOfUser}/${UserId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: UserActionTypes.FETCH_MATCHING_FOOD_CRITERIA_OF_USER, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const FetchAllBookedMessListOfOneUser = (UserId) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetAllBookedMessListOfOneUser}/${UserId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: UserActionTypes.FETCH_ALL_BOOKED_MESSLIST_OF_ONE_USER, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const FetchMyChefs = (UserId) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetMyChefs}/${UserId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: UserActionTypes.FETCH_MY_CHEFS, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("UserController Error: ", error);
            });
    }
}

export const SearchText = (Term) => {

    return (dispatch) => {

        dispatch({ type: UserActionTypes.SEARCH_TEXT, payload : Term })
    }
}




