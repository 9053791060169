import { ChefRecipeActionTypes, GeneralActionTypes } from "../ActionTypes/actionTypes"
import { apiHeader, urlGetAutoCompleteDistinctRecipeNames, urlGetChefAllRecipes, urlPublishChefRecipe, urlUpdateChefRecipe } from "../../endpoints"
import Global from "../../global"

export const PublishChefRecipe = (ChefRecipeModel) => {
    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlPublishChefRecipe}`, {
            method: 'POST',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(ChefRecipeModel)
        })
            .then((response) => response.json())
            .then((responseData) => {

                if (responseData === undefined) {
                    let errMsg = 'Unable to received respose from ChefRecipeController API';
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                    return;
                }
                else {

                    dispatch({ type: ChefRecipeActionTypes.PUBLISH_CHEF_RECIPE, payload: responseData })

                    localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)

                    if (responseData.rowEffected > 0) {
                        dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                    }
                    else {
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData.errorMsg })
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
            });
    }
}

export const fetchAutoCompleteRecipeNames = () => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlGetAutoCompleteDistinctRecipeNames}`, {
            method: 'GET',
            headers: apiHeader
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: ChefRecipeActionTypes.FETCH_AUTO_COMPLETE_RECIPE_NAMES, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => { dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) }) })
    }
}

export const fetchChefAllRecipes = (chefId) => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })
        // console.log('Api Header (ChefRecipeService):', localStorage.APIHeader)

        await fetch(`${urlGetChefAllRecipes}/${chefId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: ChefRecipeActionTypes.FETCH_CHEF_ALL_RECIPES, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                console.log(error)
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
            })
    }
}

export const UpdateChefRecipe = (ChefRecipeModel) => {
    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlUpdateChefRecipe}`, {
            method: 'POST',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(ChefRecipeModel)
        })
            .then((response) => response.json())
            .then((responseData) => {

                console.log("ChefRecipeController (UpdateChefRecipeService: Response data): ", responseData);

                if (responseData === undefined) {
                    let errMsg = 'Unable to received respose from ChefRecipeController API';
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                    console.log('ChefRecipeController: error ', errMsg);
                    return;
                }
                else {

                    dispatch({ type: ChefRecipeActionTypes.UPDATE_CHEF_RECIPE, payload: responseData })

                    localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)

                    if (responseData.rowEffected > 0) {

                        dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })

                        console.log("Chef recipe updated successfully");

                    }
                    else {
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData.errorMsg })
                        console.log("ChefRecipeController Error: ", responseData.errorMsg);
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("ChefRecipeController Error: ", error);
            });
    }
}
