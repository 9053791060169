import { combineReducers } from 'redux'
import { sessionReducer } from 'redux-react-session';
import RegisterReducer from './Chef/chefRegisterReducer'
import GeneralReducer from '../Reducers/General/generalReducer'
import ChefAuthReducer from './Chef/chefAuthReducer'
import DropdownListReducer from './General/dropdownListReducer'
import ChefRecipeReducer from './Chef/chefRecipeReducer'
import UserAuthReducer from './User/userAuthReducer'
import OrdersReducer from './Orders/ordersReducer'
import UserReducer from './User/userReducer'
import PromotionReducer from './Promotion/promotionReducer'


export default combineReducers({
    RegisterReducer,
    ChefAuthReducer,
    GeneralReducer,
    DropdownListReducer,
    ChefRecipeReducer,
    UserAuthReducer,
    OrdersReducer,
    UserReducer,
    PromotionReducer,
    SessionReducer: sessionReducer
});