import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import About from '../About/About';
import BookingTable from '../BookingTable/BookingTable';
import Blog from '../Common/Blog';
import Blogview from '../Blog/Blogview';

import { AccessAlarm, ShoppingBasket, NavigateNext, ArrowBack, MoreTime } from '@mui/icons-material';
import SelectQuantity from './select-quantity';
import MessSchedule from './mess-schedule';
import CheckOut from './checkout';
import PersonalInfo from './personalInfo';


const steps = ['Select Quantity', 'Mess Schedule','Personal Info' ,'Proceed to Checkout'];

const UserAddFoodMessSchedule = ({ closeModal, recipe }) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };



    return (
        recipe ?
            <>
                <div className="row">
                    <div className='modal-header'>
                        <div className="col-md-12 col-sm-12 col-lg-12" >
                            <div className='text-center' >
                                <Typography variant="overline" display="block">
                                    Add this item in your daily Mess
                                </Typography>

                                {closeModal == undefined ? '' :
                                    <button onClick={closeModal} style={{ float: 'right', marginRight: '35px' }}>X</button>
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <br></br>

                <div className="container">
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption">Optional</Typography>
                                    );
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}> {
                                    activeStep == 0 ?
                                        <div><br></br><SelectQuantity /></div> :
                                        activeStep == 1 ?
                                            <div><br></br><MessSchedule /></div> :
                                            activeStep == 2 ?
                                            <div><br></br><PersonalInfo/></div> :
                                            <div><br></br><CheckOut/></div>
                                }

                                </Typography>
                                <div className="sticky-buttons">
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            variant="outlined"
                                        >
                                            <ArrowBack /> Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {isStepOptional(activeStep) && (
                                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                Skip
                                            </Button>
                                        )}

                                        {/* <Button variant="outlined" onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? (<span> <ShoppingBasket />&nbsp;Proceed to Checkout</span>) : <span><NavigateNext />Next</span>}
                                            </Button> */}

                                        {activeStep === steps.length - 1 ?
                                                (<div>
                                                    <Button onClick={handleNext} variant="contained" startIcon={<ShoppingBasket />}>&nbsp;Proceed to Checkout</Button>
                                                    <Button variant="outlined" startIcon={<MoreTime />}>Save & Add More</Button></div>) :
                                            (<Button onClick={handleNext} variant="outlined" startIcon={<NavigateNext />}>Next</Button>)}
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                    </Box>

                </div>



            </> : ""
    );
}
export default UserAddFoodMessSchedule;