import ImageModel from "../../component/Models/ImageModel";
import { apiHeader, urlUpdateChef, urlGetChefById, urlGetAllChef, urlGetAllUser, urlGetUserById, urlUpdateUser } from "../../endpoints";
import { RegisterActionTypes as ActionType, AuthActionTypes, GeneralActionTypes } from "../ActionTypes/actionTypes"
import Global from "../../global";

export const fetchChefs = () => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlGetAllChef}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: ActionType.FETCH_CHEFS, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => { dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) }) })
    }
}

export const fetchChef = (id) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetChefById}/${id}`, {
            method: 'GET',
            headers: localStorage.APIHeader
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                if (responseData !== null && responseData !== undefined) {
                    dispatch({ type: ActionType.FETCH_CHEF, payload: responseData })
                    dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                }
            })
            .catch((error) => { dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) }) })
    }
}

export const updateChef = (ChefModel) => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlUpdateChef}/${ChefModel.chefId}`, {
            method: 'PUT',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(ChefModel)
        })
            .then((response) => response.json())
            .then((responseUpdatedChef) => {

                responseUpdatedChef.apiHeader = JSON.parse(localStorage.APIHeader);

                localStorage.setItem(Global.SessionKey.RowEffected, responseUpdatedChef.rowEffected)

                if (responseUpdatedChef.rowEffected > 0) {
                    localStorage.setItem(Global.SessionKey.LoginChefModel, JSON.stringify(responseUpdatedChef));

                    dispatch({ type: ActionType.UPDATE_CHEF, payload: responseUpdatedChef })
                    dispatch({ type: AuthActionTypes.CHEF_LOGIN_SUCCESS, payload: responseUpdatedChef })
                    dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                }
                else {
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseUpdatedChef.notifyMessage })
                    // console.log("updateChef Error: ", responseUpdatedChef.notifyMessage);
                }

            })
            .catch((error) => { dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) }) })
    }
}

export const fetchUsers = () => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlGetAllUser}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: ActionType.FETCH_USERS, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => { dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) }) })
    }
}

export const fetchUser = (id) => {

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetUserById}/${id}`, {
            method: 'GET',
            headers: localStorage.APIHeader
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                if (responseData !== null && responseData !== undefined) {
                    dispatch({ type: ActionType.FETCH_USER, payload: responseData })
                    dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                }
            })
            .catch((error) => { dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) }) })
    }
}

export const updateUser = (UserModel) => {

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlUpdateUser}/${UserModel.userId}`, {
            method: 'PUT',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(UserModel)
        })
            .then((response) => response.json())
            .then((responseUpdatedUser) => {

                responseUpdatedUser.apiHeader = JSON.parse(localStorage.APIHeader);

                localStorage.setItem(Global.SessionKey.RowEffected, responseUpdatedUser.rowEffected)

                if (responseUpdatedUser.rowEffected > 0) {
                    localStorage.setItem(Global.SessionKey.LoginUserModel, JSON.stringify(responseUpdatedUser));

                    dispatch({ type: ActionType.UPDATE_USER, payload: responseUpdatedUser })
                    dispatch({ type: AuthActionTypes.USER_LOGIN_SUCCESS, payload: responseUpdatedUser })
                    dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                }
                else {
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseUpdatedUser.notifyMessage })
                    // console.log("updateUser Error: ", responseUpdatedUser.notifyMessage);
                }

            })
            .catch((error) => { dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) }) })
    }
}

