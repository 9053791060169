import React, { Component, Fragment, useEffect, useState, } from "react";
import { Link, useParams } from "react-router-dom";
import Header2 from "../header/Header2";
import Footer from "../footer/Footer";
import AboutIntro from "../Common/AboutIntro";
import Blog from "../Common/Blog";
import SubscriptionForm from "../Common/SubscriptionForm";
import QuoteDay from "../Common/QuoteDay";
import FoodRestaurants from "../Common/FoodRestaurants";
import SubscriptionFormTwo from "../Common/SubscriptionFormTwo";
import AboutPricing from "../Common/AboutPricing";
import ChefPost from "../Common/ChefPost";
import ChefRecipes from "../Common/ChefRecipes";
import { ReactComponent as Arrow } from "../../svg/right-arrow.svg";
import "slick-carousel/slick/slick.css";
import HomeTwoSlider from "../slider/HomeTwoSlider";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate  } from "react-router-dom";
import AppLogout from "../General/AppLogout";
import SearchRecipeControl from "../General/SearchRecipeControl";
import { useSelector, useDispatch } from 'react-redux';
import { SearchText } from "../../redux/ActionsCreator/userService";

const Homepage2 = () => {

  var dispatch = useDispatch();
  
  const [getRecipeValue, setRecipeValue] = useState("");

  useEffect(() =>{
      document.querySelector('.rbt-input-main').focus();
  },[]);

  let navigate = useNavigate();


const handleAutocompleteChange = (selected) => {
  if (selected.length > 0) {
    const selectedValue = selected[0].value;
    setRecipeValue(selectedValue);
    dispatch(SearchText(selectedValue))
    navigate(`/MasterSearch`);
  }
};

  return(
    
    <Fragment>
      {/* <AppLogout></AppLogout> */}
    <Header2  navigate={navigate}/>
    {/* <!-- Slider Section --> */}
    <section className="main-slider2 position-relative">
      <HomeTwoSlider />
      <div className="container">
        <div className="kenburn-text text-center" style={{marginTop:'5%'}}>
          <form className="newsleter-form pt-2">
            <div className="row-fluid" style={{   width:'60%',marginLeft: '25%' }}>
            <div className="col-md-10 col-sm-10 col-lg-10" >
            <SearchRecipeControl handleChangeSearchRecipe={(recipe) => {}} handleAutocompleteChange={handleAutocompleteChange}/>
              {/* <input type="text" id="txtSearchReceipe" placeholder="Search your receipe here.."  style={{width:'800px',height:'50px'}}/> */}
              </div>
            </div>
            <div className="row-fluid" style={{  marginLeft: '27%', display:'none' }}>
              <Link className="theme-btn-secondary mt-35" to="/BookingTable">
                Find Receipes
                <span></span>
              </Link>
            </div>
          </form>
          <h3 className="line position-relative text-white" style={{fontVariant: 'all-petite-caps'}}>
            Enjoy Your Food From Multiple Chefs & Restaurants Which Are Exists Near By You
          </h3>
          <h2 className="text-theme text-white" style={{fontSize:'100px'}}>Create Customize food mess</h2>
          <p className="mb-0">
            Explore new type of customize mess food on your door step where you can choose distinguish receipes from different type of restraunts and individual chefs, located near your home.
          </p>
        </div>
      </div>
    </section>
   
    <ChefRecipes />
    {/*ChefRecipes*/}
    {/* AboutPricing */}
    <ChefPost />
    {/* AboutPricing */}
    <QuoteDay />
    {/* QuoteDay */}
    <SubscriptionFormTwo />
    {/* SubscriptionFormTwo */}
    <FoodRestaurants />
    {/*FOOD RESTAURANTS*/}
   
    <Blog />
    {/*Blog*/}
    <SubscriptionForm />
    {/*SubscriptionForm*/}
    <Footer />
  </Fragment>
  )
}


// class Homepage22 extends Component {
//   componentDidMount(){
//     window.onload = () => {
//         document.getElementById("txtSearchReceipe").focus();
//       };
// }
//   render() {
//     return (
//       <Fragment>
//         <Header2 />
//         {/* <!-- Slider Section --> */}
//         <section className="main-slider2 position-relative">
//           <HomeTwoSlider />
//           <div className="container">
//             <div className="kenburn-text text-center">
//               <form className="newsleter-form pt-2">
//                 <div className="row-fluid">
//                   <input type="text" id="txtSearchReceipe" placeholder="Search your receipe here.."  style={{width:'800px',height:'50px'}}/>
//                 </div>
//                 <div className="row-fluid">
//                   <Link className="theme-btn-secondary mt-35" to="/BookingTable">
//                     Find Receipes
//                     <span></span>
//                   </Link>
//                 </div>
//               </form>
//               <h3 className="line position-relative text-white" style={{fontVariant: 'all-petite-caps'}}>
//                 Enjoy Your Food From Multiple Chefs & Restaurants Which Are Exists Near By You
//               </h3>
//               <h2 className="text-theme text-white" style={{fontSize:'100px'}}>Create Customize food mess</h2>
//               <p className="mb-0">
//                 Explore new type of customize mess food on your door step where you can choose distinguish receipes from different type of restraunts and individual chefs, located near your home.
//               </p>
//             </div>
//           </div>
//         </section>
       
//         <ChefRecipes />
//         {/*ChefRecipes*/}
//         {/* AboutPricing */}
//         <ChefPost />
//         {/* AboutPricing */}
//         <QuoteDay />
//         {/* QuoteDay */}
//         <SubscriptionFormTwo />
//         {/* SubscriptionFormTwo */}
//         <FoodRestaurants />
//         {/*FOOD RESTAURANTS*/}
       
//         <Blog />
//         {/*Blog*/}
//         <SubscriptionForm />
//         {/*SubscriptionForm*/}
//         <Footer />
//       </Fragment>
//     );
//   }
// }

export default Homepage2;
