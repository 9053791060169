export const RegisterActionTypes = {

    FETCH_CHEFS: 'FETCH_CHEFS',
    FETCH_CHEF: 'FETCH_CHEF',
    UPDATE_CHEF: 'UPDATE_CHEF',
    FETCH_USERS: 'FETCH_USERS',
    FETCH_USER: 'FETCH_USER',
    UPDATE_USER: 'UPDATE_USER',
}

export const UserRegisterActionTypes = {

    FETCH_USER: 'FETCH_USER',
    UPDATE_USER: 'UPDATE_USER',
    FETCH_USERS: 'FETCH_USERS',
    ADD_USER: 'ADD_USER'
}

export const GeneralActionTypes = {

    SET_MESSAGE: "SET_MESSAGE",
    CLEAR_MESSAGE: "CLEAR_MESSAGE",
    START_LOADING: "START_LOADING",
    END_LOADING: "END_LOADING",
    LOGIN_TYPE: "LOGIN_TYPE",
}

export const AuthActionTypes = {

    CHEF_LOGIN_SUCCESS: "CHEF_LOGIN_SUCCESS",
    CHEF_LOGIN_FAIL: "CHEF_LOGIN_FAIL",
    CHEF_LOGOUT: "CHEF_LOGOUT",
    CHEF_REGISTER_SUCCESS: "CHEF_REGISTER_SUCCESS",
    CHEF_REGISTER_FAIL: "CHEF_REGISTER_FAIL",
    USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
    USER_LOGOUT: "USER_LOGOUT",
    USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
    USER_REGISTER_FAIL: "USER_REGISTER_FAIL"
}

export const DropdownListActionTypes = {

    FETCH_CONTINENTS: "FETCH_CONTINENTS",
    FETCH_COUNTRIES: "FETCH_COUNTRIES",
    FETCH_STATES: "FETCH_STATES",
    FETCH_CITIES: "FETCH_CITIES",
    FETCH_AREAS: "FETCH_AREAS",
    FETCH_CITIES_BY_COUNTRY: "FETCH_CITIES_BY_COUNTRY",
    FETCH_AREAS_BY_CITY: "FETCH_AREAS_BY_CITY",
    FETCH_FOOD_TYPES: "FETCH_FOOD_TYPES",
    FETCH_FOOD_CATEGORY: "FETCH_FOOD_CATEGORY",
    FETCH_QUANTITY_TYPE: "FETCH_QUANTITY_TYPE",
    FETCH_USER_TYPE: "FETCH_USER_TYPE",
}

export const ChefRecipeActionTypes = {

    PUBLISH_CHEF_RECIPE: "PUBLISH_CHEF_RECIPE",
    UPDATE_CHEF_RECIPE: "UPDATE_CHEF_RECIPE",
    DI_ACTIVE_CHEF_RECIPE: "DI_ACTIVE_CHEF_RECIPE",
    DI_ACTIVE_MASTER_RECIPE: "DI_ACTIVE_MASTER_RECIPE",
    FETCH_ALL_CHEFS_RECIPES: "FETCH_ALL_CHEFS_RECIPES",
    FETCH_CHEF_ALL_RECIPES: "FETCH_CHEF_ALL_RECIPES",
    FETCH_CHEF_SINGLE_RECIPE: "FETCH_CHEF_SINGLE_RECIPE",
    FETCH_AUTO_COMPLETE_RECIPE_NAMES: "FETCH_AUTO_COMPLETE_RECIPE_NAMES",

}

export const OrdersActionTypes = {

    FETCH_MASTER_ORDERS: "FETCH_MASTER_ORDERS",
    ADD_MASTER_ORDER: "ADD_MASTER_ORDER",
    UPDATE_MASTER_ORDER: "UPDATE_MASTER_ORDER",
    DELETE_MASTER_ORDER: "DELETE_MASTER_ORDER",
    UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
    FETCH_ORDER_COMMENTS: "FETCH_ORDER_COMMENTS",
    ADD_ORDER_COMMENTS: "ADD_ORDER_COMMENTS",
    FETCH_ORDER_LIST: "FETCH_ORDER_LIST",
    ADD_ORDER_HISTORY: "ADD_ORDER_HISTORY",
    FETCH_ORDER_HISTORY: "FETCH_ORDER_HISTORY",
}

export const UserActionTypes = {
    
    FETCH_MASTER_SEARCH: "FETCH_MASTER_SEARCH",
    ADD_MASTER_SEARCH: "ADD_MASTER_SEARCH",
    UPDATE_MASTER_SEARCH: "UPDATE_MASTER_SEARCH",
    DIACTIVE_MASTER_SEARCH: "DIACTIVE_MASTER_SEARCH",
    FETCH_ALL_USER__MESSES_SCHEDULES_OF_SINGLE_MASTER_MESS: "FETCH_ALL_USER__MESSES_SCHEDULES_OF_SINGLE_MASTER_MESS",
    FETCH_SINGLE_USERMESS_SCHEDULES_OF_CHEF: "FETCH_SINGLE_USERMESS_SCHEDULES_OF_CHEF",
    ADD_FOOD_MESS_SCHEDULE_OF_USER: "ADD_FOOD_MESS_SCHEDULE_OF_USER",
    FETCH_RECIPE_SEARCH_CRITERIA: "FETCH_RECIPE_SEARCH_CRITERIA",
    FETCH_MATCHING_FOOD_CRITERIA_OF_USER: "FETCH_MATCHING_FOOD_CRITERIA_OF_USER",
    FETCH_ALL_BOOKED_MESSLIST_OF_ONE_USER: "FETCH_ALL_BOOKED_MESSLIST_OF_ONE_USER",
    FETCH_MY_CHEFS: "FETCH_MY_CHEFS",
    SEARCH_TEXT : "SEARCH_TEXT"
}

export const PromotionActionTypes = {
    FETCH_QUESTIONNAIRE: "FETCH_QUESTIONNAIRE",
    ADD_SURVEY: "ADD_SURVEY",
}