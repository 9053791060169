import { PromotionActionTypes, GeneralActionTypes } from "../ActionTypes/actionTypes"
import Global from "../../global"
import { apiHeader, urlGetSurveyQuestionnaireBySurveyId, urlAddSurvey } from "../../endpoints"

export const FetchQuestionnaire = (navigate, SurveyId, LanguageId) => {

    if (!localStorage.APIHeader) {
        navigate("/"); // Redirect to signup screen
    }

    return (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        fetch(`${urlGetSurveyQuestionnaireBySurveyId}/${SurveyId}/${LanguageId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader || '{}')
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: PromotionActionTypes.FETCH_QUESTIONNAIRE, payload: responseData })
                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("PromotionController Error: ", error);

            });
    }
}

export const AddSurvey = (navigate, SurveyDetailList) => {

    if (!localStorage.APIHeader) {
        navigate("/"); // Redirect to signup screen
    }

    return async (dispatch) => {

        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlAddSurvey}`, {
            method: 'POST',
            headers: JSON.parse(localStorage.APIHeader),
            body: JSON.stringify(SurveyDetailList)
        })
            .then((response) => response.json())
            .then((responseData) => {

                console.log("PromotionController (Services: Response data): ", responseData);

                if (responseData === undefined) {
                    let errMsg = 'Unable to received respose from PromotionController API';
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                    console.log('PromotionController: error ', errMsg);
                    return;
                }
                else {

                    dispatch({ type: PromotionActionTypes.ADD_SURVEY, payload: responseData })
                    localStorage.setItem(Global.SessionKey.RowEffected, responseData.rowEffected)

                    if (responseData.rowEffected > 0) {
                        dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                        console.log("Survey added successfully");
                    }
                    else {
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData.errorMsg })
                        console.log("PromotionController Error: ", responseData.errorMsg);
                    }
                }
            })
            .catch((error) => {
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: JSON.stringify(error) })
                console.log("PromotionController Error: ", error);
            });
    }
}