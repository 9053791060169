import React, { useEffect, useState } from 'react'
import MstrSearchModel from "../Models/MstrSearchModel";
import { useSelector, useDispatch } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import GeoLocation from "../General/GeoLocation";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DDLMenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const MessSchedule = () => {

    var SearchModel = new MstrSearchModel();

    const _userId = useSelector((state) => state.SessionReducer.user.userId);


    const [optionsWeekdays, setOptionsWeekdays] = useState([
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
        { label: 'Sunday', value: 7 },
    ]);
    const [getDistanceMeterValue, setDistanceMeterValue] = useState([200]);
    const [getSelectedWeekdaysValue, setSelectedWeekdaysValue] = useState([]);
    const [getFromTimeValue, setFromTimeValue] = useState("");
    const [getToTimeValue, setToTimeValue] = useState("");
    const [getSelectedPayTypeValue, setPayTypeValue] = useState(-1);
    const [getSelectedDeliveryValue, setDeliveryValue] = useState(-1);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [isErr, setIsErr] = React.useState(false);

    const handleSearchClick = async () => {

        console.log('getDistanceMeterValue:', getDistanceMeterValue[0]);
        console.log('getSelectedWeekdaysValue:', getSelectedWeekdaysValue.map(day => day.label).join(','));
        console.log('getFromTimeValue:', getFromTimeValue);
        console.log('getToTimeValue:', getToTimeValue);
        console.log('getSelectedPayTypeValue:', getSelectedPayTypeValue);
        console.log('getSelectedDeliveryValue:', getSelectedDeliveryValue);
        console.log('localStorage.Longitude:', localStorage.Longitude);
        console.log('localStorage.Latitude:', localStorage.Latitude);

        setErrorMsg('');
        setIsErr(false);

        var msg = "";

        if (getSelectedWeekdaysValue.length === 0) {
            msg += "<div>Please select your weekdays.</div>";
            setIsErr(true);
        }
        if (getFromTimeValue === "") {
            msg += "<div>Please select your desire start time.</div>";
            setIsErr(true);
        }
        if (getToTimeValue === "") {
            msg += "<div>Please select desire end time.</div>";
            setIsErr(true);
        }
        if (getSelectedPayTypeValue === "-1") {
            msg += "<div>Please select any payment type option.</div>";
            setIsErr(true);
        }
        if (getSelectedDeliveryValue === "-1") {
            msg += "<div>Please select your delivery option.</div>";
            setIsErr(true);
        }


        if (msg.length > 0) {
            setIsErr(true);
            setErrorMsg(msg)
            return;
        }
        else {
            setIsErr(false);
        }


        var _fromTime = null;
        var _toTime = null;
        if (getFromTimeValue !== "") {
            _fromTime = getFromTimeValue + ":00";
        }
        if (getToTimeValue !== "") {
            _toTime = getToTimeValue + ":00";
        }
        SearchModel.UserId = _userId;
        SearchModel.DistanceInMeter = parseInt(getDistanceMeterValue[0]);
        SearchModel.Weekdays = getSelectedWeekdaysValue.map(day => day.label).join(',');
        SearchModel.FromTime = _fromTime;
        SearchModel.ToTime = _toTime;
        SearchModel.PayTypeId = getSelectedPayTypeValue;
        SearchModel.IsDelivery = getSelectedDeliveryValue == true ? true : false;
        SearchModel.Longitude = localStorage.Longitude;
        SearchModel.Latitude = localStorage.Latitude;
        SearchModel.ChefReceipesList = null;
        SearchModel.ArrayRecipeNames = null;
        console.log('SearchModel: ', SearchModel);

    };

    const handleChangeWeekdays = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedWeekdaysValue(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        console.log('handleChangeWeekdays: ', value)
    };
    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    {/* Weekdays */}
                    <div className="col-md-6 col-sm-6 col-lg-6">

                        <FormControl fullWidth required >
                            <InputLabel id="demo-multiple-checkbox-label">Weekdays</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={getSelectedWeekdaysValue}
                                onChange={handleChangeWeekdays}
                                input={<OutlinedInput label="Weekdays" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={DDLMenuProps}
                            >
                                {optionsWeekdays.map((days) => (
                                    <MenuItem key={days.label} value={days.label}>
                                        <Checkbox checked={getSelectedWeekdaysValue.indexOf(days.label) > -1} />
                                        <ListItemText primary={days.label} setValue={days.value} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {/* Time Range */}
                    <div className="col-md-6 col-sm-6 col-lg-6">
                        <fieldset >
                            <div className="d-lg-flex">
                                <div className="">
                                    <Box component="form" sx={{ '& > :not(style)': { m: 0 }, }} autoComplete="off">
                                        <TextField required InputProps={{
                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        }} sx={{ width: '18.1ch' }} id="filled-basic" label="From Time" variant="outlined"  type='time' onChange={(value) => { setFromTimeValue(value.target.value) }} value={getFromTimeValue} placeholder="From Time" name="FromTime" />
                                        &nbsp;<TextField required InputProps={{
                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        }} sx={{ width: '18.1ch' }} id="filled-basic" label="To Time" variant="outlined"  type='time' onChange={(value) => { setToTimeValue(value.target.value) }} value={getToTimeValue} name="ToTime" />
                                    </Box>


                                </div><span>&nbsp;</span>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    {/* Payment Delivery Type */}
                    <div className="col-md-6 col-sm-6 col-lg-6">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl required fullWidth >
                                <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={getSelectedPayTypeValue}
                                    label="Select Payment Type"
                                    onChange={(value) => { setPayTypeValue(value.target.value) }}
                                >
                                    <MenuItem value={-1}>How will you pay your order ?</MenuItem>
                                    <MenuItem value={1}>Per-order Payment</MenuItem>
                                    <MenuItem value={2}>lump-sum payment</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                    </div>
                    {/* Delivery Type */}
                    <div className="col-md-6 col-sm-6 col-lg-6">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl required fullWidth >
                                <InputLabel id="demo-simple-select-label">Delivery Type</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={getSelectedDeliveryValue}
                                    label="Select Payment Delivery"
                                    onChange={(value) => { setDeliveryValue(value.target.value) }}
                                >
                                    <MenuItem value={-1}>How will you receive your order ?</MenuItem>
                                    <MenuItem value={1}>Delivery</MenuItem>
                                    <MenuItem value={2}>Pick up</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MessSchedule;