import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Global from "../../../global";
import UserRegistrationModel from "../../Models/UserRegistrationModel";
import { useEffect, useState } from "react";
import { updateUser } from "../../../redux/ActionsCreator/registerService";
import { useSelector, useDispatch } from 'react-redux';
import { getuid } from 'process';
import { fetchCountries, fetchCitiesByCountry, fetchAreasByCity } from '../../../redux/ActionsCreator/dropdownListService';
import AlertModel from '../../Models/AlertModel';
import AlertPopup from '../../General/AlertPopup';

const schema = yup.object().shape({
  completename: yup.string().required('Complete name is required').min(8, 'Complete name must be at least 8 characters').uppercase('First letter should be upper case'),
  email: yup.string().email().required('Email is required field'),
  phone: yup.string().required('Phone is required field').min(10).max(13, 'Phone number must be at most 13 charactors'),
  whatsappNo: yup.string().required('Whatsapp no is required field').min(10).max(13, 'whatsapp number must be at most 13 charactors').nullable(),
  ddlNationality: yup.string().required('Nationality is required field'),
  ddlCountry: yup.string().required('Country is required field'),
  ddlCity: yup.string().required('City is required field'),
  ddlArea: yup.string().required('Area is required field'),
  address: yup.string().required('Address is required field').nullable(),

});

const UserAccount = () => {

  const dispatch = useDispatch();

  const UserModel = new UserRegistrationModel();
  var AlertOptions = new AlertModel();

  const { register, handleSubmit, formState: { errors }, reset, setFocus, setValue } = useForm({
    resolver: yupResolver(schema),
  });

   // Hooks Assignments 
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState();
  const [base64, setFileBase64] = useState("");
  const [alertOptions, setAlertOptions] = useState(AlertOptions);

  const _apiHeader = useSelector((state) => state.UserAuthReducer.user?.apiHeader);
  const _user = useSelector((state) => state.UserAuthReducer.user);
  const _userId = useSelector((state) => state.UserAuthReducer.user?.userId);
  const _countryListDDLR = useSelector((state) => state.DropdownListReducer.CountryListDDLR);
  const _selectedCitiesDDLR = useSelector((state) => state.DropdownListReducer.SelectedCitiesDDLR);
  const _selectedAreasDDLR = useSelector((state) => state.DropdownListReducer.SelectedAreasDDLR);
  const _isLoading =  useSelector((state) => state.GeneralReducer.isLoading);

  useEffect(() => {
    dispatch(fetchCountries());

  }, [_user])

  useEffect(() => {

    //console.log('redux state: User model (user account): ', _user);

    let responseData = _user;

    if (responseData !== undefined) {
      UserModel.id = getuid;
      UserModel.UserId = responseData.userId;
      UserModel.Name = responseData.name;
      UserModel.Email = responseData.email;
      UserModel.Password = responseData.password;
      UserModel.Phone = responseData.phone;
      UserModel.WhatsappNo = responseData.whatsappNo;
      UserModel.NationalityId = responseData.nationalityId;
      UserModel.CountryId = responseData.countryId;
      UserModel.StateId = responseData.stateId;
      UserModel.CityId = responseData.cityId;
      UserModel.AreaId = responseData.areaId;
      UserModel.Address = responseData.address;
     /*

      if (responseData.dtoImage != undefined && responseData.dtoImage != null) {
        UserModel.DTOImage.ImageId = responseData.dtoImage.imageId;
        UserModel.DTOImage.EntityId = responseData.dtoImage.entityId;
        UserModel.DTOImage.ImageName = responseData.dtoImage.imageName;
        UserModel.DTOImage.ImageBinary = responseData.dtoImage.imageBinary;
        UserModel.DTOImage.ImageTypeId = responseData.dtoImage.imageTypeId;
        UserModel.DTOImage.IsActive = responseData.dtoImage.isActive;
      }
      var isTempUpload = localStorage.getItem('tempupload');


      // console.log('isTempUpload (Onload)', isTempUpload)
      if (isTempUpload != 'true') {

        if (UserModel.DTOImage.ImageBinary !== undefined && UserModel.DTOImage.ImageBinary !== null && UserModel.DTOImage.ImageBinary !== "") {
          setFileBase64(UserModel.DTOImage.ImageBinary);
          setFileName(UserModel.DTOImage.ImageName);
        }
        else {
          if (UserModel.Gender === "Female") {
            setFileBase64("assets/images/DPFemale.jpg")
          }
          else {
            setFileBase64("assets/images/DPMale.jpg")
          }
        }
      }

*/
      setValue('completename', UserModel.Name);
      setValue('email', UserModel.Email);
      setValue('password', UserModel.Password);
      setValue('phone', UserModel.Phone);
      setValue('address', UserModel.Address);
      setValue('whatsappNo', UserModel.WhatsappNo);

      if (UserModel.NationalityId !== null && UserModel.NationalityId !== undefined) {
        setValue('ddlNationality', UserModel.NationalityId);
      }

      if (UserModel.CountryId !== null && UserModel.CountryId !== undefined) {
        setValue('ddlCountry', UserModel.CountryId);
        dispatch(fetchCitiesByCountry(UserModel.CountryId));
       
      }
      // if (UserModel.StateId !== null && UserModel.StateId !== undefined) {
      //   setValue('ddlState', UserModel.StateId);
      // }
      if (UserModel.CityId !== null && UserModel.CityId !== undefined) {
        setValue('ddlCity', UserModel.CityId);
        dispatch(fetchAreasByCity(UserModel.CityId));
      
      }
      if (UserModel.AreaId !== null && UserModel.AreaId !== undefined) {
        setValue('ddlArea', UserModel.AreaId);
      }
    }
  }, []);
  

  const fnConvertToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);

    reader.onerror = error => reject(error);
  });

  const fnPreviewDisplayPicture = async (e) => {

    if (e.target.files[0].size > 2000000) {
      console.log("File too large");
      alert("File too large")
      return;
    }

    setFile(e.target.files[0]);

    //  console.log(e.target.files[0]);

    const base64 = await fnConvertToBase64(e.target.files[0]);
    // console.log('image base64', base64);

    setFileBase64(base64);
    setFileName(e.target.files[0].name);

    localStorage.setItem('tempupload', true);
    localStorage.setItem('uploadedFile', e.target.files[0].name);
  };

  const onChangeCountry = (countryId) => {
    debugger;
    setValue('ddlCity', '');
    setValue('ddlArea', '');
    dispatch(fetchCitiesByCountry(countryId));
   
  }

  const onChangeCity = (cityId) => {
    debugger;
    setValue('ddlArea', '');
    dispatch(fetchAreasByCity(cityId));
  }
  

    const handleSubmitAccount = (data) => {

      //console.log(data);
      UserModel.userId = _user.userId;
      UserModel.Name = data.completename;
      UserModel.Email = data.email;
      // UserModel.Password = data.password;
      UserModel.Phone = data.phone;
      UserModel.WhatsappNo = data.whatsappNo;
      UserModel.NationalityId = data.ddlCountry;
      UserModel.CountryId = data.ddlCountry;
      // UserModel.StateId = data.ddlState;
      UserModel.CityId = data.ddlCity;
      UserModel.AreaId = data.ddlArea;
      UserModel.Address = data.address;
     
  
  /*
      if (UserModel.DTOImage !== null && UserModel.DTOImage !== undefined) {
  
        debugger;
        var isTempUpload = localStorage.getItem('tempupload');
        if (isTempUpload === 'true') {
  
          UserModel.DTOImage.EntityId = UserModel.userId;
          UserModel.DTOImage.ImageName = fileName;
          UserModel.DTOImage.ImageBinary = base64;
          UserModel.DTOImage.ImageTypeId = Global.ImageTypeKey.UserDP;
          UserModel.DTOImage.IsActive = true;
  
          setFileBase64(base64);
          setFileName(fileName);
        }
        else {

          if (UserModel.Gender === "Female") {
            setFileBase64("assets/images/DPFemale.jpg")
          }
          else  if (UserModel.Gender === "Male" || UserModel.Gender === "Others") {
            setFileBase64("assets/images/DPMale.jpg")
          }
        }
      }
  */
     dispatch(updateUser(UserModel));
  
      //localStorage.setItem('tempupload', false);
    }

    useEffect(()=>{
   
      if (parseInt(localStorage.rowEffected) > Global.eResponse.Default) {
      //  setTimeout(() => {

          AlertOptions.Type = Global.AlertPopupType.SimpleAlert;
          AlertOptions.Message = "Your Account Info has been updated successfully."
          AlertOptions.Variant = Global.AlertVariantType.Success;

          localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
          setAlertOptions(AlertOptions);
          window.scrollTo(0, 20);
      //  }, 3500);
      }
      else if (parseInt(localStorage.rowEffected) == Global.eResponse.Error) {
        //setTimeout(() => {

          AlertOptions.Type = Global.AlertPopupType.AdditionalContentAlert;
          AlertOptions.Variant = Global.AlertVariantType.Danger;
          AlertOptions.Heading = "Oh user! You got an error!"
          AlertOptions.Message = _user.notifyMessage;

          localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
          setAlertOptions(AlertOptions);
          window.scrollTo(0, 20);

      //  }, 3500);
      }
    });
  return (
    <>
      <div
        className="tab-pane"
        id="v-pills-account"
        role="tabpanel"
        aria-labelledby="v-pills-settings-tab"
      >
        <div className="tabs-wrp account-settings brd-rd5">
        <div className='row page-header'>
          <h4 itemprop="headline">ACCOUNT SETTINGS</h4>
          </div>
          <AlertPopup alertOptions={alertOptions}></AlertPopup>
          <div className="account-settings-inner">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="profile-info text-center">
                  <div className="profile-thumb brd-rd50">
                    <img
                      id="profile-display"
                      src={base64}
                      alt="Profile picture"
                      itemprop="image"
                      className="brd-rd50"
                    />
                  </div>
                  {/* <input type="button" value="upload" onClick={uploadFile} /> */}

                  <div className="profile-img-upload-btn">
                    <label className="fileContainer brd-rd5 yellow-bg">
                      UPLOAD PICTURE
                      <input id="profile-upload" type="file" onChange={fnPreviewDisplayPicture} />
                    </label>
                  </div>
                  <a
                    className="red-clr change-password"
                    href="#"
                    title=""
                    itemprop="url"
                  >
                    Change Password
                  </a>
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-lg-8">
                <div className="profile-info-form-wrap">
                  <form className="profile-info-form" onSubmit={handleSubmit(handleSubmitAccount)}>
                    <div className="row mrg20">
                      {/* Complete Name */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Complete Name <sup>*</sup>
                        </label>
                        <input {...register("completename")} name="completename"
                          className="brd-rd3"
                          type="text"
                          placeholder="Enter Your Name"
                        />
                        <p className="text-danger">{errors.completename?.message}</p>

                      </div>
                      {/* Email */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Email Address <sup>*</sup>
                        </label>
                        <input {...register("email")} name="email"
                          className="brd-rd3"
                          type="email"
                          placeholder="Enter Your Email Address"
                        />
                        <p className="text-danger">{errors.email?.message}</p>
                      </div>
                      {/* Phone No */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Phone No <sup>*</sup>
                        </label>
                        <input {...register("phone")} name="phone"
                          className="brd-rd3"
                          type="text"
                          placeholder="Enter Your Phone No"
                        />
                        <p className="text-danger">{errors.phone?.message}</p>
                      </div>
                      {/* WhatsappNo */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Whatsapp No <sup>*</sup>
                        </label>
                        <input {...register("whatsappNo")} name="whatsappNo"
                          className="brd-rd3"
                          type="text"
                          placeholder="Enter Your Whatsapp No"
                        />
                        <p className="text-danger">{errors.whatsappNo?.message}</p>
                      </div>
                     
                      {/* Nationality */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                        Nationality <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlNationality")} name="ddlNationality" style={{ width: '375px' }} >
                            <option value='' selected>Select Nationality</option>
                            {
                              _countryListDDLR?.map((value, index) => (
                                <option value={value.countryId} key={index}>{value.countryName}</option>
                              ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlNationality?.message}</p>
                        </div>
                      </div>

                      {/* Country */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Country <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlCountry")} name="ddlCountry" style={{ width: '375px' }} onChange={(e) => { onChangeCountry(e.target.value) }}>
                            <option value='' selected>Select Country</option>
                            {
                              _countryListDDLR?.map((value, index) => (
                                <option value={value.countryId} key={index}>{value.countryName}</option>
                              ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlCountry?.message}</p>
                        </div>
                      </div>
                      {/* City */}
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <label>
                          City <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlCity")} name="ddlCity" onChange={(e) => { onChangeCity(e.target.value) }}>
                            <option value='' selected>Select City</option>
                            {
                              _selectedCitiesDDLR?.map((value, index) => (
                                <option value={value.cityId} key={index}>{value.cityName}</option>
                              ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlCity?.message}</p>
                        </div>
                      </div>
                      {/* Area */}
                      <div className="col-md-6 col-sm-12 col-lg-6">
                        <label>
                          Area <sup>*</sup>
                        </label>
                        <div className="select-wrp">
                          <select {...register("ddlArea")} name="ddlArea" style={{width:'175px'}}>
                            <option value='' selected>Select Area</option>
                            {_selectedAreasDDLR?.map((value, index) => (
                              <option value={value.areaId} key={index}>{value.areaName}</option>
                            ))
                            }
                          </select>
                          <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlArea?.message}</p>
                        </div>
                      </div>
                      {/* Address */}
                      <div className="col-md-12 col-sm-12 col-lg-12">
                        <label>
                          Address <sup>* (provide your exact address for the delievery)</sup>
                        </label>
                        <input {...register("address")} name="address"
                          className="brd-rd3"
                          type="text" aria-multiline="true"
                          placeholder="Type your address"
                        />
                        <p className="text-danger">{errors.address?.message}</p>
                      </div>
                    </div>
                    {_isLoading &&
                      <div className='cover-spin'>
                        <h4 className='spin-waiting-msg'>Your account information is updating...</h4>
                      </div>
                    }
                    <div className="col-md-12 col-sm-12 col-lg-12">
                      <button type="submit" className="theme-btn-secondary mt-20" style={{ float: 'right' }} >
                        Update <span></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAccount;