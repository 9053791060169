import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Stack, Badge} from 'react-bootstrap';
const CheckOut = () => {
    return (
        <React.Fragment>
            <div className='container' style={{overflowY:'auto'}}>

                <div className="row">
                    <div className='modal-header'>
                        <div className="col-md-6 col-sm-6 col-lg-6" >
                            <div className='left' >
                                <Typography variant="overline" display="block">
                                    Summary
                                </Typography>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6" >
                            <div className='right' >
                                <Typography variant="overline" display="block">
                                    Total Amount: <strong>85.00 AED</strong>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>

              
                <div className="row" style={{ fontSize: 'small' }}>
                    <div><strong style={{ color: '#eb8a8e', fontSize: '16px' }} className="fontRokkitt">BEAF BIRYANI</strong> - <span style={{font:'calibri light'}} >Try Luck Restrauant, Al Qasimiya Area Plot 782</span></div>
                    <div class="d-flex"><strong>Quantity: </strong>&nbsp; 3 | &nbsp; 
                            <Stack direction="horizontal" gap={2} style={{ color: 'white' }}>
                                <Badge bg="secondary" text="light">Regular (26.00 AED)</Badge>
                            </Stack>
                    </div>
                    <div><strong>Price: </strong>3 X 26 = 78 | 78 + 3 + 4 = 85.00 AED</div>
                    <div class="d-flex"><strong>Addons: </strong>
                        <Stack direction="horizontal" gap={2} style={{color:'white'}}>
                            <Badge bg="secondary" text="light">Extra Mayoneese (3.00 AED)</Badge> 
                            <Badge bg="secondary" text="light">Peperoni Sauce (4.00 AED)</Badge>
                        </Stack>
                    {/* <span >Extra Mayoneese (3.00 AED)</span>&nbsp;<span>Peperoni Sauce (4.00 AED)</span> */}
                    </div>
                    <div><strong>Extra Instructions: </strong>Need more salt and spicess, add more potatos.</div>
                    <hr></hr>
                    <div class="d-flex"><strong>Weekdays: </strong>
                        <Stack direction="horizontal" gap={2} style={{color:'white'}}>
                            <Badge bg="secondary" text="light">Wednesday</Badge> 
                            <Badge bg="secondary" text="light">Friday</Badge>
                            <Badge bg="secondary" text="light">Sunday</Badge>
                        </Stack>
                    </div>
                    {/* <div><strong>Weekdays: </strong>Wednesday, Friday</div> */}
                    <div><strong>Delivery Duration: </strong>between 3 PM to 6 PM</div>
                    <div><strong>Payment Type: </strong>Per/order</div>
                    <div><strong>Delivery Type: </strong>Pickup</div>
                    <hr></hr>
                    <div><strong>Country: </strong>United Arab Emirates</div>
                    <div><strong>City: </strong>Sharjah</div>
                    <div><strong>Area: </strong>Al Qasimiya</div>
                    <div><strong>Street: </strong>King Abdul Azeez</div>
                    <div><strong>Building Name: </strong>Manara Ali</div>
                    <div><strong>Flat/Villa No: </strong>708</div>
                    <div><strong>Name: </strong>Johnson Abraham</div>
                    <div><strong>Mobile No: </strong>+97150321486</div>
                    <div><strong>Whatsapp No: </strong>+97150321486</div>
                    <div><strong>Email: </strong> johnson.abraham23@gmail.com</div>

                </div>
               
                {/* <div className="row">
                        <div className="col-md-4 col-sm-4 col-lg-4" >
                        </div>
                        <div className="col-md-4 col-sm-4 col-lg-4" >
                        </div>
                        <div className="col-md-4 col-sm-4 col-lg-4" >
                        </div>
                </div> */}

            </div>
        </React.Fragment>
    );
}

export default CheckOut;