import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Global from '../../global'
import AlertModel from '../Models/AlertModel';

var type, variant, message, heading = "", link = "", additionalContent = "";

function AlertPopup(alertOptions) {

  const [show, setShow] = useState(true);

  type = alertOptions.alertOptions.Type;
  variant = alertOptions.alertOptions.Variant;
  message = alertOptions.alertOptions.Message;
  heading = alertOptions.alertOptions.Heading;
  link = alertOptions.alertOptions.URL;
  additionalContent = alertOptions.alertOptions.AdditionalContent;


  if (message === undefined || message === "") {
    return;
  }

  let htmlElements;
  if (typeof message === 'string') {
    if (message.includes('<div>')) {
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = message;
      htmlElements = Array.from(tempContainer.childNodes);
    }
  }
 
  switch (type) {

    case Global.AlertPopupType.SimpleAlert:
      return (
        <Alert variant={variant}>{message}</Alert>
      );
      case Global.AlertPopupType.SimpleAlertWithNextLine:
      return (
        <Alert variant={variant}>{htmlElements.map((element, index) => (
          <div key={index}>{element.textContent}</div>
        ))}</Alert>
      );
    case Global.AlertPopupType.LinkAlert:
      return (
        <Alert variant={variant}>
          Please{' '}
          <Alert.Link href={link}>click here</Alert.Link>. to {message}
        </Alert>
      );
    case Global.AlertPopupType.ContentAlert:
      return (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{heading}</Alert.Heading>
          <p>{message}</p>
        </Alert>
      );
    case Global.AlertPopupType.AdditionalContentAlert:
      return (
        <Alert variant={variant}>
          <Alert.Heading>{heading}</Alert.Heading>
          <p>{message}</p>
          <hr />
          <p className="mb-0">{additionalContent}</p>
        </Alert>
      );
    case Global.AlertPopupType.ContentAlertWithButton:
      return (
        <>
          <Alert show={show} variant={variant}>
            <Alert.Heading>{heading}</Alert.Heading>
            <p>{message}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} variant={`outline-` + variant}>
                Close me!
              </Button>
            </div>
          </Alert>
        </>
      );
  }
}

export default AlertPopup;