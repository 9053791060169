import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from "react";
import { Link, Navigate, useNavigate, useHistory } from "react-router-dom";
import WOW from "wowjs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoginModel from "../Models/LoginModel";
import { useDispatch, useSelector } from "react-redux";
import { ChefLogin } from "../../redux/ActionsCreator/chefAuthService";
import { UserLogin } from "../../redux/ActionsCreator/userAuthService";
import Global from "../../global";
//import * from "../../../public/logo.jpg";


const schema = yup.object().shape({
  mobile: yup.string().required('Mobile number is required field').min(10).max(13, 'Phone number must be at most 13 charactors'),
  password: yup.string().required('Password is required field').min(3),
});

const LoginForm = () => {

  const { message } = useSelector(state => state.GeneralReducer);
  const _isLoading = useSelector((state) => state.GeneralReducer.isLoading);
  const IsAuthenticated = useSelector(state => state.SessionReducer.authenticated);

  const { register, handleSubmit, formState: { errors }, reset, setFocus, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const [loginError, setLoginError] = useState('');
  const [selectedRole, setSelectedRole] = useState('chef'); // Default to Chef role

  let navigate = useNavigate();

  const loginModel = new LoginModel();

  const dispatch = useDispatch();

  

  useEffect(() => {
    new WOW.WOW({
      live: true,
    }).init();
    
    if (message !== null && message !== undefined) {
      setLoginError(message)
    }
    console.log('localStorage.IsAuthenticated (Login Form): ', localStorage.IsAuthenticated);
    if (localStorage.IsAuthenticated === "true" && IsAuthenticated  === true) {
      navigate('/Dashboard');
    }
  })


  useLayoutEffect(() => {
    setFocus("mobile");
  }, [])

  const onSubmitHandler = (data) => {

    setLoginError('');
    console.log('login data', data);

    loginModel.Mobile = data.mobile;
    loginModel.password = data.password;

    try {

      let response;

      if (selectedRole === 'chef') {
        response = dispatch(ChefLogin(loginModel));
      } else {
        response = dispatch(UserLogin(loginModel));
      }

    } catch (err) {
      console.error('Login failed:', err);
      navigate('/Login');
    }

  };

  return (
    <section>
      {_isLoading &&
        <div className='cover-spin'></div>
      }
      <div className="sec-space">
        <div
          className="parallax"
          style={{
            backgroundImage:
              "url(" + "assets/images/resources/member-bg.jpg" + ")",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div
                className="becom-membr wow bounce"
                data-wow-delay="0.5s"
                style={{
                  backgroundImage:
                    "url(" +
                    "assets/images/resources/background-circle.jpg" +
                    ")",
                }}
              >
                 <img src="assets/images/logo.png" style={{position: 'absolute',height: '70px',marginLeft: '15%',marginTop: '3%'}}/>
                <div className="membr-contnt text-center">

                 
                  <h4 className="font-weight-bold mb-0">
                    Brown Onions Portal
                  </h4>
                  <span className="fm-arimo fs-14 text-gray">
                    Start your food journey here.
                  </span>
                  <form className="bcom-membr mt-30" onSubmit={handleSubmit(onSubmitHandler)}>
                    <p className="text-danger">{loginError}</p>

                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        id="chefRadio"
                        name="role"
                        value="chef"
                        label="chef"
                        checked={selectedRole === 'chef'}
                        onChange={() => setSelectedRole('chef')}
                        style={{ maxHeight: '20px', display: 'inline-block' }}
                        
                      />
                      <label htmlFor="chefRadio">Chef</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        id="userRadio"
                        name="role"
                        value="user"
                        checked={selectedRole === 'user'}
                        onChange={() => setSelectedRole('user')}
                        style={{ maxHeight: '20px', display: 'inline-block' }}
                       
                      />
                      <label htmlFor="userRadio">User</label>
                    </div>

                    <input {...register("mobile")} className="w-100 formControlPublic"  type="number" name="mobile" placeholder="Enter your mobile number..." maxLength="13" />
                    <p className="text-danger">{errors.mobile?.message}</p>

                    <input  {...register("password")} className="w-100 formControlPublic" type="password" name="password" placeholder="Enter your password" />
                    <p className="text-danger">{errors.password?.message}</p>

                    <div style={{ maxWidth: '200px', marginLeft: '110px' }}>
                      <button id='btnLogin' type="submit" className="theme-btn">
                        sign in
                      </button>
                    </div>
                  </form>
                  <div className="signup-mem mt-30">
                    <span>Not a member? </span>
                    <Link to="/Signup"> Join today</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LoginForm;
