import { AuthActionTypes as Type,  RegisterActionTypes, GeneralActionTypes } from "../../ActionTypes/actionTypes";
import Global from "../../../global";

let chef = localStorage.getItem(Global.SessionKey.LoginChefModel);
//console.log("Chef model from session (ChefAuthReducer) from session: ", chef);
chef = JSON.parse(chef);

const initialState = {
  chef: chef ? chef : null,
  isLoggedIn: chef ? true : false,
  isLoading: false,
}

export default function ChefAuthReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case Type.CHEF_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        chef: payload,
        isLoading: false
      };
    case Type.CHEF_LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        chef: null,
        isLoading: false
      };
    case Type.CHEF_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        chef: null,
        isLoading: false
      };
    case Type.CHEF_REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        chef: payload,
        isLoading: false
      };
    case Type.CHEF_REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false
      };
    case RegisterActionTypes.UPDATE_CHEF:
      debugger;
      return {
        ...state,
        chef: payload,
        isLoading: false
      };
    default:
      return state;
  }
}