import RangeSlider from "../Common/RangeSlider";
import React, { useEffect, useState, useRef, useMemo, useLayoutEffect, useCallback } from 'react'
import { Controller, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Global from "../../global";
import { useSelector, useDispatch } from 'react-redux';
import AlertModel from '../Models/AlertModel';
import AlertPopup from '../General/AlertPopup';
import { fetchFoodCategory, fetchFoodTypes, fetchQuantityTypes } from "../../redux/ActionsCreator/dropdownListService";
import { fetchAutoCompleteRecipeNames } from "../../redux/ActionsCreator/chefRecipeService";
import Modal from "react-modal";
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown';
import { MultiSelect } from "react-multi-select-component";
import { FaCalendar, FaSearch,FaCalendarWeek, FaThumbsUp ,FaEye } from "react-icons/fa";
import { GiHotMeal ,GiFruitBowl  } from "react-icons/gi";
import { BiSolidCategory } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";
import MstrSearchModel from "../Models/MstrSearchModel";
import { AddMstrSearch, FetchRecipeSearchCriteria } from "../../redux/ActionsCreator/userService";
import GeoLocation from "../General/GeoLocation";
import ChefRecipeModel from "../Models/ChefRecipeModel";
import RecipePriceModel from "../Models/RecipePriceModel";
import { convertTime } from "../../utility";
import Pagination from '../General/Pagination';
import AutoSuggest from "../General/AutoSuggest";
import ScrollToTopButton from "../General/ScrollToTopButton";
import SearchRecipeControl from "../General/SearchRecipeControl";
import ToggleButton from 'react-bootstrap/ToggleButton';
import { disablePageScroll, enablePageScroll } from '../../utility';
import ChefMasterView from "../chef/chef-master-view";
// import { Provider, ClapButton, LikeButton , UpdownButton ,RateButton ,Rating } from "@lyket/react";
import { SearchText } from "../../redux/ActionsCreator/userService";
import UserAddFoodMessSchedule from "./user-add-food-mess-schedule";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DDLMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const customModalStyles = {
    content: {
        inset: '20px',
        width: '70%', // Adjust the width as needed
        margin: 'auto', // Center the modal horizontally
        maxWidth: '100%',
        border:'none',
        backgroundColor:"rgb(232, 235, 241)",
        padding:'30px',
        borderRadius:'20px'

    },
};

const customAddMessModalStyles = {
    overlay: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgb(103 97 97 / 75%)', // Adjust the color as needed
      },
    content: {
        inset: '20px',
        width: '60%', // Adjust the width as needed
        margin: 'auto', // Center the modal horizontally
        maxWidth: '100%',
        border:'none',
        background:"white",
        // backgroundColor: 'black',
        padding:'0px !important',
        borderRadius:'20px',
        overflowX:'hidden',
        height:'98%',
        

    },
};
const componentStyles = {
   // color: "blue",
    //fontSize: "12px",
    border: '0px !important',
    //color: '#666666' 
    // Add more styles as needed
};

const schema = yup.object().shape({

    RecipeName: yup.string().required('Recipe name is required'),
    ddlPaymentDeliveryType: yup.string().nullable().required('Payment Delivery Type is required field'),
    ddlFoodType: yup.string().nullable().required('Food Type is required field'),
    ddlFoodCategory: yup.number().nullable().required('Food Category is required field'),
    FromTime: yup.string().required('Starting Hour is required field'),
    ToTime: yup.string().required('Ending Hour is required field'),
    Weekdays: yup.number().nullable().required('Weekdays is required field'),
    ddlDeliveryType: yup.string().required('Please select delivery type').nullable(),
    cbIstantDelivery: yup.string(),
});

var foodCategoryOptions = [];
var foodTypeOptions = [];
var totalPages = 0;
//var errorMsg  ="";

const MasterSearch = ({ closeModal}) => {

    //setRecipeValue(SearchText)
    //console.log('MasterSearch: ', SearchText);
    const { register, watch, control, getValues, handleSubmit, formState: { errors, isSubmitSuccessful }, reset, resetField, setFocus, setValue, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const { field } = useController({ name: 'ddlFoodCategory', control });
    const { value: ddlFoodCategoryValue, onChange: ddlFoodCategoryOnChange, ...restFoodCategoryField } = field;

    const recipeSectionRef = useRef();

    var dispatch = useDispatch();

    var SearchModel = new MstrSearchModel();
    var AlertOptions = new AlertModel();
    var ChefReceipesModel = new ChefRecipeModel();
    var ChefRecipeDetails = new RecipePriceModel();
    var AlertOptionsInstantOrderInfo = new AlertModel();

    const _isLoading = useSelector((state) => state.GeneralReducer.isLoading);
    const _userId = useSelector((state) => state.SessionReducer.user.userId);
    const _fetchAutoCompleteRecipeNamesCRR = useSelector((state) => state.ChefRecipeReducer.fetchAutoCompleteRecipeNamesCRR);
    const _foodTypeListDDLR = useSelector((state) => state.DropdownListReducer.FoodTypeListDDLR);
    const _foodCategoryListDDLR = useSelector((state) => state.DropdownListReducer.FoodCategoryListDDLR);
    const _messTypeListDDLR = useSelector((state) => state.DropdownListReducer.MessTypeListDDLR);
    const _fetchRecipeSearchCriteriaUR = useSelector((state) => state.UserReducer.fetchRecipeSearchCriteriaUR);
    const _searchText = useSelector((state) => state.UserReducer.searchText);

    const [optionsWeekdays, setOptionsWeekdays] = useState([
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
        { label: 'Sunday', value: 7 },
    ]);

    const [getDistanceMeterValue, setDistanceMeterValue] = useState([200]);
    const [getRecipeValue, setRecipeValue] = useState("");
    const [getSelectedWeekdaysValue, setSelectedWeekdaysValue] = useState([]);
    const [getSelectedFoodTypeValue, setSelectedFoodTypeValue] = useState([]);
    const [getSelectedCuicinesValue, setSelectedCuicinesValue] = useState([]);
    const [getFromTimeValue, setFromTimeValue] = useState("");
    const [getToTimeValue, setToTimeValue] = useState("");
    const [getSelectedPayTypeValue, setPayTypeValue] = useState(-1);
    const [getSelectedDeliveryValue, setDeliveryValue] = useState(-1);
    const [getCheckedInstantOrderValue, setCheckedInstantOrderValue] = useState(false);
    const [alertOptions, setAlertOptions] = useState(AlertOptions);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [isErr, setIsErr] = React.useState(false);

    const [getPayLoad, setPayLoad] = useState({});
    const [getSearchClick, setSearchClick] = useState(false);
    const [checked, setChecked] = useState(false);
    const [viewInnerModalIsOpen, setViewInnerModalIsOpen] = useState(false);
    const [AddMessInnerModalIsOpen, setAddMessInnerModalIsOpen] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);

    const openInnerModal = (value,whichModal) => {
        disablePageScroll();
        setSelectedRecipe(value);
        if (whichModal === "view") {
            setViewInnerModalIsOpen(true);
        }
        else if (whichModal === "addMess") {
            setAddMessInnerModalIsOpen(true);
        }
       
    };

    const closeInnerModal = () => {
        enablePageScroll();
        setSelectedRecipe(null);
        setViewInnerModalIsOpen(false);
        setAddMessInnerModalIsOpen(false);
    };
    // On Page Load (one time)
    useEffect(() => {

        dispatch(fetchAutoCompleteRecipeNames());

        if (_messTypeListDDLR.length <= 0) {
        }

        if (_foodTypeListDDLR.length <= 0) {
            dispatch(fetchFoodTypes());
        }

        if (_foodCategoryListDDLR.length <= 0) {
            dispatch(fetchFoodCategory());
        }

       // Scroll to the recipe section when the component mounts
    if (recipeSectionRef.current) {
        recipeSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
     

      
        var txtSearchRecipe = document.getElementById('txtSearchRecipe');
        if (txtSearchRecipe !== null) {
            txtSearchRecipe.focus();
        }
     
    //   if(SearchText !== "" && _searchText != ""){
    //     setRecipeValue(_searchText)

    if(_searchText !== "" && _searchText != ""){
        setRecipeValue(_searchText)
        handleSearchClick();
      }


      if (isErr) {
        AlertOptions.Type = Global.AlertPopupType.SimpleAlertWithNextLine;
        AlertOptions.Variant = Global.AlertVariantType.Danger;
        AlertOptions.Message = errorMsg;

        setError(false);

        setAlertOptions(AlertOptions);
        window.scrollTo(0, 20);
    }

    }, []);

    const handlePageChange = (pageNumber) => {

        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    }

    const handleSearchClick = async  () => {
        
        

        setSearchClick(true);
                console.log('getDistanceMeterValue:', getDistanceMeterValue[0]);
                console.log('getrecipe:', getRecipeValue == "" ? _searchText : getRecipeValue);
                console.log('getSelectedWeekdaysValue:', getSelectedWeekdaysValue.map(day => day.label).join(','));
                console.log('getSelectedFoodTypeValue:', getSelectedFoodTypeValue.map(foodType => foodType.value).join(','));
                console.log('getSelectedCuicinesValue:', getSelectedCuicinesValue.map(foodCategory => foodCategory.value).join(','));
                console.log('getFromTimeValue:', getFromTimeValue);
                console.log('getToTimeValue:', getToTimeValue);
                console.log('getSelectedPayTypeValue:', getSelectedPayTypeValue);
                console.log('getSelectedDeliveryValue:', getSelectedDeliveryValue);
                console.log('getCheckedInstantOrderValue:', getCheckedInstantOrderValue);
                console.log('localStorage.Longitude:', localStorage.Longitude);
                console.log('localStorage.Latitude:', localStorage.Latitude);
        
        setErrorMsg('');
        setIsErr(false);
        
        var msg = "";
       
        if (getRecipeValue === "") {
            msg += "<div>Please search your desire recipe.</div>";
            setIsErr(true);
        }
        if (getSelectedWeekdaysValue.length === 0) {
            msg += "<div>Please select your weekdays.</div>";
            setIsErr(true);
        }
        if (getFromTimeValue === "") {
            msg += "<div>Please select your desire start time.</div>";
            setIsErr(true);
        }
        if (getToTimeValue === "") {
            msg += "<div>Please select desire end time.</div>";
            setIsErr(true);
        }
        if (getSelectedPayTypeValue === "-1") {
            msg += "<div>Please select any payment type option.</div>";
            setIsErr(true);
        }
        if (getSelectedDeliveryValue === "-1") {
            msg += "<div>Please select your delivery option.</div>";
            setIsErr(true);
        }
       
        /*
        if (msg.length > 0) {
            setIsErr(true);
            setErrorMsg(msg)

            var txtSearchRecipe = document.getElementById('txtSearchRecipe');
            if (txtSearchRecipe !== null) {
                txtSearchRecipe.focus();
            }

            return;
        }
        else{
            setIsErr(false);
        }
        */

        var _fromTime = null;
        var _toTime = null;
        if (getFromTimeValue !== "") {
            _fromTime = getFromTimeValue + ":00";
        }
        if (getToTimeValue !== "") {
            _toTime = getToTimeValue + ":00";
        }
        SearchModel.UserId = _userId;
        SearchModel.DistanceInMeter = parseInt(getDistanceMeterValue[0]);
        SearchModel.RecipeSearchText = getRecipeValue == "" ? _searchText : getRecipeValue;
        dispatch(SearchText(SearchModel.RecipeSearchText));
        SearchModel.Weekdays = getSelectedWeekdaysValue.map(day => day.label).join(',');
        SearchModel.FoodTypeIds = getSelectedFoodTypeValue.map(foodType => foodType.value).join(',');
        SearchModel.FoodCategoryIds = getSelectedCuicinesValue.map(foodCategory => foodCategory.value).join(',');
        SearchModel.FromTime = _fromTime;
        SearchModel.ToTime = _toTime;
        SearchModel.PayTypeId = getSelectedPayTypeValue;
        SearchModel.IsDelivery = getSelectedDeliveryValue == true ? true : false;
        SearchModel.Longitude = localStorage.Longitude;
        SearchModel.Latitude = localStorage.Latitude;
        SearchModel.ChefReceipesList = null;
        SearchModel.ArrayRecipeNames = null;
        console.log('SearchModel: ', SearchModel);

        dispatch(FetchRecipeSearchCriteria(SearchModel))
        setCurrentPage(1);

        await new Promise((resolve) => setTimeout(resolve, 1000));
        // Scroll to the recipe section again when the search operation is complete
    if (recipeSectionRef.current) {
        recipeSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    
    };

    if (foodTypeOptions == null || foodTypeOptions == undefined || foodTypeOptions.length == 0) {
        for (let index = 0; index < _foodTypeListDDLR.length; index++) {
            const option = {
                value: _foodTypeListDDLR[index].id,
                label: _foodTypeListDDLR[index].foodType
            }
            foodTypeOptions.push(option);
        }
    }

    if (foodCategoryOptions == null || foodCategoryOptions == undefined || foodCategoryOptions.length == 0) {
        for (let index = 0; index < _foodCategoryListDDLR.length; index++) {
            const option = {
                value: _foodCategoryListDDLR[index].id,
                label: _foodCategoryListDDLR[index].foodCategory
            }
            foodCategoryOptions.push(option);
        }
    }

    var filteredRecipes = [];
    var currentItems = [];
    totalPages = 0;
    if (_fetchRecipeSearchCriteriaUR?.chefReceipesList !== null && _fetchRecipeSearchCriteriaUR?.chefReceipesList?.length > 0) {

        filteredRecipes = filteredRecipes.length > 0 ? filteredRecipes : _fetchRecipeSearchCriteriaUR?.chefReceipesList;

        totalPages = Math.ceil(filteredRecipes.length / itemsPerPage);

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        currentItems = filteredRecipes?.slice(startIndex, endIndex);

    }

    const handleChangeSearchRecipe = (arrRecipe) => {
        console.log("handleChangeSearchRecipe (MasterSearch): ",arrRecipe);
        var _recipeSearch = "";
        if (Array.isArray(arrRecipe)) {
            if (arrRecipe.length > 0) {
                setRecipeValue(arrRecipe[0].value);
                _recipeSearch= arrRecipe[0].value;
            }
        } else {
            setRecipeValue(arrRecipe);
            _recipeSearch = arrRecipe;
        }
    } 
    const handleChangeLikeBtn = (event)=>{
        alert(event.target.value)
    }
    const handlePress = (buttonData) => {
        // Access the data from the buttonData object
        console.log('Button Data:', buttonData);
        // Perform any other actions with the data as needed
      };
      const handleOnPressRating = (data) => {
        // Access the data from the buttonData object
        console.log('OnPressRating Data:', data);
        // Perform any other actions with the data as needed
      };
      const handleOnLoadRating = (data) => {
        // Access the data from the buttonData object

        var arr=[];

        console.log('OnLoadRating Data:', data);

        arr.push(data);

       // console.log('OnLoadRating Data Array:', arr);

        const averageRating = data.attributes.averageRating = generateRandomRating();

        data.attributes.totalRanking = 100;
        data.attributes.namespaceRanking = 59;
        data.attributes.averageRatingDecimal = averageRating;
        data.attributes.totalVotes = averageRating;
        data.attributes.userRating = averageRating;


        // Perform any other actions with the data as needed
      };
      const generateRandomRating = () => {
        return Math.floor(Math.random() * 5) + 1;
      };

      const handleAutocompleteChange = (selected) => {
        console.log("handleAutocompleteChange (MasterSearch): ",selected);
        if (selected.length > 0) {
          const selectedValue = selected[0].value;
          setRecipeValue(selectedValue);
          console.log('User master search selectedValue',selectedValue);
          
        }
      };
      const handleChangeWeekdays = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedWeekdaysValue(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
        console.log('handleChangeWeekdays: ', value)
      };
      const handleChangeFoodType = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedFoodTypeValue(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
        console.log('handleChangeFoodType: ', value)
      };
      const handleChangeFoodCategory = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedCuicinesValue(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
        console.log('handleChangeFoodCategory: ', value)
      };
    return (
        <>
            <GeoLocation />
            <div className="container " id="master-search-modal" >
                <div class="slide-in">
                {closeModal == undefined ? '' :
                <Button onClick={closeModal} variant="outline-secondary" style={{ float: 'right'}}>Close</Button>
                }
                <div className='row page-header'>
                    <h5 itemprop="headline" id='lblHeader' style={{ textAlign: 'center' }}>SEARCH YOUR DAILY MESS FOOD</h5>
                </div>
           
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <RangeSlider initialValue={getDistanceMeterValue} onChange={(value) => { setDistanceMeterValue(value) }} />
                    </div>
                </div>
                {isErr && (
                        <AlertPopup alertOptions={alertOptions}></AlertPopup>
                )}

                <div className="row">
                    {/* Recipe Name (Auto Complete) */}
                    <div className="col-md-10 col-sm-10 col-lg-10" style={{   width:'60%',marginLeft: '20%' }}>
                        <SearchRecipeControl handleChangeSearchRecipe={handleChangeSearchRecipe} handleAutocompleteChange={handleAutocompleteChange}/>
                    </div>
                    {/* Istant Delivery */}
                    <div className="col-md-2 col-sm-2 col-lg-2">
                        <input type="checkbox" onChange={(value) => { setCheckedInstantOrderValue(value.target.checked) }}  />
                        <label htmlFor="IstantDelivery" style={{ fontSize: '13px' }}>&nbsp;&nbsp;Order now ?</label>

                    </div>
                </div>
                    <div className="row">
                        {/* Weekdays */}
                        <div className="col-md-4 col-sm-4 col-lg-4">
                             {/* <MultiSelect
                            options={optionsWeekdays}
                            value={getSelectedWeekdaysValue}
                            onChange={setSelectedWeekdaysValue}
                            labelledBy="Select Weekdays"
                            style={{borderColor: '#de7a72',color: '#666666'}}
                            id="ddlWeekDays"
                        /> */}
                            <FormControl fullWidth  >
                                <InputLabel id="demo-multiple-checkbox-label">Weekdays</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={getSelectedWeekdaysValue}
                                    onChange={handleChangeWeekdays}
                                    input={<OutlinedInput label="Weekdays" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={DDLMenuProps}
                                >
                                    {optionsWeekdays.map((days) => (
                                        <MenuItem key={days.label} value={days.label}>
                                            <Checkbox checked={getSelectedWeekdaysValue.indexOf(days.label) > -1} />
                                            <ListItemText primary={days.label} setValue={days.value} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    {/* Time Range */}
                    <div className="col-md-4 col-sm-4 col-lg-4">
                        <fieldset >
                            <div className="d-lg-flex">
                                {/* <label>From Time <sup>*</sup></label> */}
                                <div className="">
                                    {/* <input name="FromTime" type="time" placeholder="From Time" style={{ borderRadius: '5px' }} onChange={(value) => { setFromTimeValue(value.target.value) }} value={getFromTimeValue} /> */}

                                        <Box component="form" sx={{ '& > :not(style)': { m: 0 }, }} autoComplete="off">
                                            <TextField InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }} sx={{ width: '18ch' }} id="filled-basic" label="From Time" variant="outlined"  type='time' onChange={(value) => { setFromTimeValue(value.target.value) }} value={getFromTimeValue} placeholder="From Time" name="FromTime" />
                                           &nbsp;&nbsp;<TextField InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }} sx={{ width: '18ch' }} id="filled-basic" label="To Time" variant="outlined"  type='time' onChange={(value) => { setToTimeValue(value.target.value) }} value={getToTimeValue} name="ToTime" />
                                        </Box>


                                </div><span>&nbsp;</span>
                            </div>
                        </fieldset>
                    </div>
                    {/* Payment Delivery Type */}
                        <div className="col-md-4 col-sm-4 col-lg-4">
                            {/* <select type="select" onChange={(value) => { setPayTypeValue(value.target.value) }} name="ddlPaymentDeliveryType" title="Select Payment Delivery Type" style={{ borderRadius: '5px' }}
                            >
                                <option value='-1'>How will you pay your order ?</option>
                                <option value='1'>Per-order Payment</option>
                                <option value='2'>lump-sum payment</option>
                            </select> */}

                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={getSelectedPayTypeValue}
                                        label="Select Payment Type"
                                        onChange={(value) => { setPayTypeValue(value.target.value) }}
                                    >
                                        <MenuItem value={-1}>How will you pay your order ?</MenuItem>
                                        <MenuItem value={1}>Per-order Payment</MenuItem>
                                        <MenuItem value={2}>lump-sum payment</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                        </div>

                </div>
                <br></br>
                <div className="row">

                    {/* Delivery Type */}
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        {/* <select type="select" onChange={(value) => { setDeliveryValue(value.target.value) }} name="ddlDeliveryType" title="Select Delivery Type"
                            style={{ borderRadius: '5px' }}>
                            <option value='-1'>How will you receive your order ?</option>
                            <option value='1'>Delivery</option>
                            <option value='2'>Pick up</option>
                        </select> */}
                        
                        <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label">Delivery Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={getSelectedDeliveryValue}
                                        label="Select Payment Delivery"
                                        onChange={(value) => { setDeliveryValue(value.target.value) }}
                                    >
                                        <MenuItem value={-1}>How will you receive your order ?</MenuItem>
                                        <MenuItem value={1}>Delivery</MenuItem>
                                        <MenuItem value={2}>Pick up</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                    </div>
                    {/* Food Type */}
                    <div className="col-md-4 col-sm-4 col-lg-4">

                        {/* <MultiSelect
                            placeholder="Select Food Type"
                            options={foodTypeOptions}
                            labelledBy="Select Food Type"
                            value={getSelectedFoodTypeValue}
                            onChange={setSelectedFoodTypeValue}
                            style={componentStyles}
                            
                        /> */}


                            <FormControl fullWidth >
                                <InputLabel id="demo-multiple-checkbox-label">Food Type</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={getSelectedFoodTypeValue}
                                    onChange={handleChangeFoodType}
                                    input={<OutlinedInput label="Food Category" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={DDLMenuProps}
                                >
                                    {foodTypeOptions.map((days) => (
                                        <MenuItem key={days.label} value={days.label}>
                                            <Checkbox checked={getSelectedFoodTypeValue.indexOf(days.label) > -1} />
                                            <ListItemText primary={days.label} setValue={days.value} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                    </div>
                    {/* Cuicine */}
                    <div className="col-md-4 col-sm-4 col-lg-4">
                        {/* <MultiSelect
                            placeholder="Select Food Category"
                            options={foodCategoryOptions}
                            labelledBy="Select Cuicines"
                            value={getSelectedCuicinesValue}
                            onChange={setSelectedCuicinesValue}
                            style={componentStyles}/> */}

                            <FormControl fullWidth >
                                <InputLabel id="demo-multiple-checkbox-label">Food Category</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={getSelectedCuicinesValue}
                                    onChange={handleChangeFoodCategory}
                                    input={<OutlinedInput label="Food Type" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={DDLMenuProps}
                                >
                                    {foodCategoryOptions.map((days) => (
                                        <MenuItem key={days.label} value={days.label}>
                                            <Checkbox checked={getSelectedCuicinesValue.indexOf(days.label) > -1} />
                                            <ListItemText primary={days.label} setValue={days.value} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                </div>
                 <br></br>                           
                {_isLoading &&
                    <div className='cover-spin'>
                        <h4 className='spin-waiting-msg'>Please wait..We are searching the recipes</h4>
                    </div>
                }
                <div className="col-md-12 col-sm-12 col-lg-12">
                    <button type="button" id='btnSearch' className="theme-btn-secondary" style={{ marginLeft: '42%' }} onClick={handleSearchClick} >
                        Search <span></span>
                    </button>
                </div>
                { getSearchClick ?(
                <div className="container" >
                    <div className="col-md-12 col-sm-12 col-lg-12" style={{ display: 'inline-block' }}>
                        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange}
                            itemsPerPage={itemsPerPage} />
                        <div className="recipe-list" ref={recipeSectionRef} >
                            <br></br>
                            {_isLoading ? (
                                <div className='cover-spin'>
                                    <h4 className='spin-waiting-msg'>Please wait.. Your recipes are loading.</h4>
                                </div>
                            ) : currentItems?.length > 0 ? (

                                currentItems.map((value, index) => (
                                    <React.Fragment key={index}>
                                        <div id={"cardId_" + value.chefRecipeId} key={index} className="" >

                                            <div className="recipe-item-search brd-rd5 slide-in" style={{ borderRadius: '15px', marginBottom: '2px', border: '2px solid #de7a72;', background: 'oldlace' }}>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <div className="recipe-stars d-md-flex align-items-center" style={{ position: 'relative', float: 'right' }}>
                                                            <a href="#"><span className="ml-3" style={{ fontSize: 'small', textDecoration: 'underline' }}>(4.2 /5) 76 ratings </span></a>
                                                            <div>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        <div className="chef-special">
                                                            <ul className="recipe-cokng-info d-flex justify-content-center p-0 list-unstyled">
                                                                <li className="fs-16 text-gray">
                                                                    <span>
                                                                        <svg viewBox="0 0 512 512" height="18" fill="#4fd675">
                                                                            <g>

                                                                                <path d="M498.682,435.326L297.917,234.56L63.357,0H45.026l-3.743,9.511c-9.879,25.104-14.1,50.78-12.205,74.249 c2.16,26.752,12.323,49.913,29.392,66.982L241.58,333.852l24.152-24.152l169.285,189.293c16.84,16.84,45.825,17.84,63.665,0 C516.236,481.439,516.236,452.879,498.682,435.326z" />
                                                                            </g>
                                                                            <g>

                                                                                <path d="M156.728,291.442L13.317,434.853c-17.552,17.552-17.552,46.113,0,63.665c16.674,16.674,45.519,18.146,63.665,0 l143.412-143.412L156.728,291.442z" />
                                                                            </g>
                                                                            <g>

                                                                                <path d="M490.253,85.249l-81.351,81.35l-21.223-21.222l81.351-81.351l-21.222-21.222l-81.35,81.35l-21.222-21.222l81.351-81.35 L405.366,0.361L299.256,106.471c-12.981,12.981-20.732,30.217-21.828,48.535c-0.277,4.641-1.329,9.206-3.074,13.548l68.929,68.929 c4.342-1.747,8.908-2.798,13.548-3.075c18.318-1.093,35.554-8.846,48.535-21.827l106.11-106.109L490.253,85.249z" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>

                                                                    <label style={{ fontSize: '12px' }}>Instant recipe:</label>  <strong style={{ fontSize: '12px' }}>{value.isQuickSaleAvailable === true ? "YES" : "NO"}</strong>


                                                                </li>
                                                                <li className="fs-16 text-gray">
                                                                    <span>
                                                                        <svg viewBox="0 0 443.294 443.294" height="18" fill="#4fd675">
                                                                            <path d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                                                                            <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                                                                        </svg>
                                                                    </span>

                                                                    <label style={{ fontSize: '12px' }} htmlFor="">Hours:</label>  <strong style={{ fontSize: '12px' }}>{convertTime(value.startHour)} - {convertTime(value.endHour)}</strong>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-3 col-lg-3">
                                                        <div className="recipe-thumb brd-rd5">
                                                            <a href="#" title="" itemprop="url">
                                                                <img
                                                                    src="/assets/images/biryani.jpg"
                                                                    alt="order-img1.jpg"
                                                                    itemprop="image"
                                                                    style={{ borderRadius: '4px', width: '140px', height: '130px' }}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9 col-sm-9 col-lg-9">
                                                        <div className="recipe-info">
                                                            <div>
                                                                <FaCalendarWeek /> <label style={{ fontSize: '12px' }} > Weekdays:</label>
                                                                <span style={{ color: '#eb8a8e', fontSize: '13px' }} className="fontRokkitt">
                                                                    {" " + value.weekdays}
                                                                </span>
                                                                <div>
                                                                    <GiFruitBowl /> <label style={{ fontSize: '12px' }}> Food Type: </label>
                                                                    <span style={{ color: '#eb8a8e', fontSize: '13px' }} className="fontRokkitt">
                                                                        {" " + value.foodType}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <BiSolidCategory /> <label style={{ fontSize: '12px' }}> Food Category: </label>
                                                                    <span style={{ color: '#eb8a8e', fontSize: '13px' }} className="fontRokkitt">
                                                                        {" " + value.foodCategory}
                                                                    </span>
                                                                </div>
                                                                <div style={{ marginTop: '15px' }}>
                                                                    <h5 itemprop="headline"  > <GiHotMeal />

                                                                        <a href="#" title="" itemprop="url" className="recipe-name">
                                                                            {" " + value.recipeName}
                                                                        </a>
                                                                    </h5>
                                                                </div>
                                                                {value && (
                                                                    <span className="red-clr">
                                                                        {
                                                                            value?.chefRecipeDetails?.reduce((min, price) => {
                                                                                return price.price < min ? price.price : min;
                                                                            }, Infinity) + " AED"
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div style={{ marginTop: '1.875rem', display: 'block', marginLeft: '220px' }}>
                                                                {/* <Button variant="warning" onClick={() => openModal(value, 'view')}>View</Button>{' '}
                                            <Button variant="danger" onClick={() => openModal(value, 'edit')}>Edit</Button>{' '} */}
                                                            </div>



                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4 col-sm-4 col-lg-4">
                                                </div>
                                                <div className="col-md-2 col-sm-2 col-lg-2">
                                                </div> */}
                                                </div>
                                                <div className="row" key={index}>
                                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                                        {/* <div style={{float:'left'}}>
                                                        <Provider 
                                                         apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                                         theme={{
                                                             colors: {
                                                                 background: "#b8fff3",
                                                                 text: "violet",
                                                                 primary: "rgba(255, 224, 138, 0.4)"
                                                             }
                                                         }}>
                                                          
                                                           
                                                            <UpdownButton 
                                                                namespace="testing-react"
                                                                id={"Id_"+value.chefRecipeId}
                                                                onPressUp={handlePress}
                                                                onPressDown={handlePress}
                                                                key={index}
                                                            />
                                                        </Provider>
                                                        </div> */}
                                                        <div style={{ float: 'right' }}>
                                                            <Button variant="warning" size="sm" onClick={() => openInnerModal(value,"view")}><FaEye />{' '} View</Button>{' '}
                                                            <Button variant="danger" size="sm" onClick={() => openInnerModal(value,"addMess")}><MdAddCircle />{' '} Add To Mess</Button>{' '}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {index % 2 === 1 && index + 1 !== currentItems.length && <br></br>}
                                    </React.Fragment>
                                ))
                            ) : (
                                <p><strong>No matching recipes found.</strong> <br></br>  Don't Worry!  If you can't find your desired item in the search results, Simply enter your recipe name & details, click on search. We'll save your criteria and notify you when a matching criteria of the item becomes available.</p>
                            )}


                        </div>
                        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange}
                            itemsPerPage={itemsPerPage}  />
                        
                    </div>
                            <Modal isOpen={viewInnerModalIsOpen} onRequestClose={closeInnerModal} style={customModalStyles} >
                                <div className='container'>
                                    <ChefMasterView closeModal={closeInnerModal} recipe={selectedRecipe} />
                                </div>
                            </Modal>

                            <Modal isOpen={AddMessInnerModalIsOpen} onRequestClose={closeInnerModal} style={customAddMessModalStyles} >
                                <div className=''>
                                    <UserAddFoodMessSchedule  closeModal={closeInnerModal} recipe={selectedRecipe}/>
                                </div>
                            </Modal>
                </div>
                ) :''
}
            </div>
            </div>
        {/* </Modal> */}
        
        </>
    );
}

export default MasterSearch;