import React, { useEffect, useState } from 'react'
import MasterSearch from "../../user/user-master-search";
import Button from 'react-bootstrap/Button'
import { disablePageScroll, enablePageScroll } from '../../../utility';
import Modal from "react-modal";

const UserDashboard = () => {

    const customStyles = {
        content: {
            inset: '0px',
            width: '100%', // Adjust the width as needed
            margin: 'auto', // Center the modal horizontally
            padding: '10px',
            backgroundColor: '#e8ebf1',
            maxWidth: '100%',
    
        },
    };

    const [viewModalIsOpen, setViewModalIsOpen] = useState(false);

    const openModal = () => {
        disablePageScroll();
        setViewModalIsOpen(true);
    };

    const closeModal = () => {
        enablePageScroll();
        setViewModalIsOpen(false);
    };

    return (
        <>
        <div style={{ marginTop: '1.875rem', display: 'block', marginLeft: '220px' }}>
            <Button variant="warning" onClick={() => openModal()}>Find Mess</Button>{' '}
            </div>
           
            <Modal isOpen={viewModalIsOpen} onRequestClose={closeModal} style={customStyles} >
                <MasterSearch closeModal={closeModal}></MasterSearch>
            </Modal>
        </>
    );
}
export default UserDashboard;