import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchAutoCompleteRecipeNames, fetchChefAllRecipes } from '../../../redux/ActionsCreator/chefRecipeService';
import Pagination from '../../General/Pagination';
import Button from 'react-bootstrap/Button'
import ViewRecipeModal from './details/view-recipe-modal';
import EditRecipeModal from './details/edit-recipe-modal';
import { convertTime,disablePageScroll, enablePageScroll } from '../../../utility';
import { FaCalendar, FaSearch,FaCalendarWeek, FaThumbsUp ,FaEye } from "react-icons/fa";
import { GiHotMeal ,GiFruitBowl  } from "react-icons/gi";
import { BiSolidCategory } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";

var totalPages = 0;

const ChefMyRecipes = (props) => {

    const dispatch = useDispatch();

    const [RecipesList, setRecipesList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

   
    const _apiHeader = useSelector((state) => state.ChefAuthReducer.chef?.apiHeader);
    const _chefId = useSelector((state) => state.ChefAuthReducer.chef?.chefId);
    const _notifyReturnModelCRR = useSelector((state) => state.ChefRecipeReducer.notifyReturnModelCRR);
    const _fetchChefAllRecipes = useSelector((state) => state.ChefRecipeReducer.fetchChefAllRecipesCRR);
    const _fetchAutoCompleteRecipeNames = useSelector((state) => state.ChefRecipeReducer.fetchAutoCompleteRecipeNamesCRR);
    const _isLoading = useSelector((state) => state.GeneralReducer.isLoading);

   

    const openModal = (value, modalType) => {
        disablePageScroll();
        saveScrollPosition();
        setSelectedRecipe(value);
        if (modalType === 'view') {
            setViewModalIsOpen(true);
        } else if (modalType === 'edit') {
            setEditModalIsOpen(true);
        }
    };


    const closeModal = () => {
        enablePageScroll();
        restoreScrollPosition();
        setSelectedRecipe(null);
        setViewModalIsOpen(false);
        setEditModalIsOpen(false);
    };

     // Function to save the scroll position
     const saveScrollPosition = () => {
        setScrollPosition(window.scrollY);
      };
    
      // Function to restore the scroll position
      const restoreScrollPosition = () => {
        window.scrollTo(0, scrollPosition);
      };

    const handlePageChange = (pageNumber) => {

        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    }

    useEffect(() => {
      
        // alert('laod recipes')

        dispatch(fetchChefAllRecipes(_chefId));
        dispatch(fetchAutoCompleteRecipeNames());

        document.getElementById('txtSearchRecipe').focus();
    }, [_chefId, _apiHeader]);


    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    
  
    var filteredRecipes = [];
    var currentItems = [];
   
    if (_fetchChefAllRecipes !== null && _fetchChefAllRecipes?.length > 0) {
        filteredRecipes = filteredRecipes.length > 0 ? filteredRecipes : _fetchChefAllRecipes;

        filteredRecipes = _fetchChefAllRecipes.filter((recipe) => recipe?.recipeName.toLowerCase().includes(searchQuery.toLowerCase()));

        totalPages = Math.ceil(filteredRecipes.length / itemsPerPage);

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        currentItems = filteredRecipes?.slice(startIndex, endIndex);
      
    }
   
    const ModifyChefAllRecipes = () => {

        dispatch(fetchChefAllRecipes(_chefId, _apiHeader));
        dispatch(fetchAutoCompleteRecipeNames(_apiHeader));

        currentItems = _fetchChefAllRecipes;
        console.log("After updated: ", currentItems);
        closeModal();
    }

    return (

        <>
            <div
                className="tab-pane"
                id="v-pills-recipe"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
            >
                <div className="tabs-wrp brd-rd5">
                    <div className='row page-header'>
                        <h4 itemprop="headline">MY RECIPES</h4>
                    </div>
                    <br></br>
                    <div className='row'>
                        <input
                            className="w-100 formControlPortal"
                            id='txtSearchRecipe'
                            type="text"
                            name="SearchRecipe"
                            placeholder="Search your published recipes here.."
                            autoComplete='off'
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            style={{fontFamily: 'Rokkitt' }}
                        />
                    </div>


                    <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange}
                        itemsPerPage={itemsPerPage} />

                    <div className="recipe-list">
                        <br></br>
                        {_isLoading ? (
                            <div className='cover-spin'>
                                <h4 className='spin-waiting-msg'>Please wait.. Your recipes are loading.</h4>
                            </div>
                        ) : currentItems.length > 0 ? (

                            currentItems.map((value, index) => (

                                <React.Fragment key={index}>
                                <div id={"cardId_" + value.chefRecipeId} key={index} className="" >

                                    <div className="my-recipes-portal brd-rd5 slide-in" style={{ borderRadius: '15px', marginBottom: '2px', border: '2px solid #de7a72;', background: 'oldlace' }}>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <div className="recipe-stars d-md-flex align-items-center" style={{ position: 'relative', float: 'right' }}>
                                                    <a href="#"><span className="ml-3" style={{ fontSize: 'small', textDecoration: 'underline' }}>(4.2 /5) 76 ratings </span></a>
                                                    <div>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <div className="chef-special">
                                                    <ul className="recipe-cokng-info d-flex justify-content-center p-0 list-unstyled">
                                                        <li className="fs-16 text-gray">
                                                            <span>
                                                                <svg viewBox="0 0 512 512" height="18" fill="#4fd675">
                                                                    <g>

                                                                        <path d="M498.682,435.326L297.917,234.56L63.357,0H45.026l-3.743,9.511c-9.879,25.104-14.1,50.78-12.205,74.249 c2.16,26.752,12.323,49.913,29.392,66.982L241.58,333.852l24.152-24.152l169.285,189.293c16.84,16.84,45.825,17.84,63.665,0 C516.236,481.439,516.236,452.879,498.682,435.326z" />
                                                                    </g>
                                                                    <g>

                                                                        <path d="M156.728,291.442L13.317,434.853c-17.552,17.552-17.552,46.113,0,63.665c16.674,16.674,45.519,18.146,63.665,0 l143.412-143.412L156.728,291.442z" />
                                                                    </g>
                                                                    <g>

                                                                        <path d="M490.253,85.249l-81.351,81.35l-21.223-21.222l81.351-81.351l-21.222-21.222l-81.35,81.35l-21.222-21.222l81.351-81.35 L405.366,0.361L299.256,106.471c-12.981,12.981-20.732,30.217-21.828,48.535c-0.277,4.641-1.329,9.206-3.074,13.548l68.929,68.929 c4.342-1.747,8.908-2.798,13.548-3.075c18.318-1.093,35.554-8.846,48.535-21.827l106.11-106.109L490.253,85.249z" />
                                                                    </g>
                                                                </svg>
                                                            </span>

                                                            <label style={{ fontSize: '12px' }}>Instant recipe:</label>  <strong style={{ fontSize: '12px' }}>{value.isQuickSaleAvailable === true ? "YES" : "NO"}</strong>


                                                        </li>
                                                        <li className="fs-16 text-gray">
                                                            <span>
                                                                <svg viewBox="0 0 443.294 443.294" height="18" fill="#4fd675">
                                                                    <path d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                                                                    <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                                                                </svg>
                                                            </span>

                                                            <label style={{ fontSize: '12px' }} htmlFor="">Hours:</label>  <strong style={{ fontSize: '12px' }}>{convertTime(value.startHour)} - {convertTime(value.endHour)}</strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-lg-3">
                                                <div className="recipe-thumb brd-rd5">
                                                    <a href="#" title="" itemprop="url">
                                                        <img
                                                            src="/assets/images/biryani.jpg"
                                                            alt="order-img1.jpg"
                                                            itemprop="image"
                                                            style={{ borderRadius: '4px', width: '140px', height: '130px' }}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-9 col-sm-9 col-lg-9">
                                                <div className="recipe-info">
                                                    <div>
                                                        <FaCalendarWeek /> <label style={{ fontSize: '12px' }} > Weekdays:</label>
                                                        <span style={{ color: '#eb8a8e', fontSize: '13px' }} className="fontRokkitt">
                                                            {" " + value.weekdays}
                                                        </span>
                                                        <div>
                                                            <GiFruitBowl /> <label style={{ fontSize: '12px' }}> Food Type: </label>
                                                            <span style={{ color: '#eb8a8e', fontSize: '13px' }} className="fontRokkitt">
                                                                {" " + value.foodType}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <BiSolidCategory /> <label style={{ fontSize: '12px' }}> Food Category: </label>
                                                            <span style={{ color: '#eb8a8e', fontSize: '13px' }} className="fontRokkitt">
                                                                {" " + value.foodCategory}
                                                            </span>
                                                        </div>
                                                        <div style={{ marginTop: '15px' }}>
                                                            <h5 itemprop="headline"  > <GiHotMeal />

                                                                <a href="#" title="" itemprop="url" className="recipe-name">
                                                                    {" " + value.recipeName}
                                                                </a>
                                                            </h5>
                                                        </div>
                                                        {value && (
                                                            <span className="red-clr">
                                                                {
                                                                    value?.chefRecipeDetails?.reduce((min, price) => {
                                                                        return price.price < min ? price.price : min;
                                                                    }, Infinity) + " AED"
                                                                }
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div style={{ marginTop: '1.875rem', display: 'block', marginLeft: '220px' }}>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" key={index}>
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <div style={{ float: 'right' }}>
                                                    <Button variant="warning" size="sm" onClick={() => openModal(value,"view")}><FaEye />{' '} View</Button>{' '}
                                                    <Button variant="danger" size="sm" onClick={() => openModal(value,"edit")}><MdAddCircle />{' '} Edit</Button>{' '}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {index % 2 === 1 && index + 1 !== currentItems.length && <br></br>}
                            </React.Fragment>
                            ))
                        ) : (
                            <p>No matching recipes found.</p>
                        )}

                    </div>
                    <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange}
                        itemsPerPage={itemsPerPage} />

                    <div className='container'>
                        <ViewRecipeModal isOpen={viewModalIsOpen} closeModal={closeModal} recipe={selectedRecipe} />
                        <EditRecipeModal isOpen={editModalIsOpen} closeModal={closeModal} recipe={selectedRecipe} fnReloadRecipes={ModifyChefAllRecipes} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChefMyRecipes