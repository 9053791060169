export default class UserRegistrationModel {

    UserId;
    Name;
    ShortName;
    Phone;
    WhatsappNo;
    IsActive;
    Address;
    Email;
    Password;
    NationalityId;
    CountryId;
    StateId;
    CityId;
    AreaId;
    Latitude;
    Longitude;
    CreatedDate;
    ModifedDate;
    ModifedBy;
}