import RecipePriceModel from "./RecipePriceModel"


export default class ChefRecipeModel {
          ChefRecipeId = 0
        ; RecipeId
        ; RecipeCode
        ; RecipeName
        ; FoodCategoryId
        ; FoodCategory
        ; FoodTypeId
        ; FoodType
        ; IsActive
        ; ChefId
        ; Weekdays
        ; IsQuickSaleAvailable
        ; IsRecipeCustomizationAllow
        ; StartHour
        ; EndHour
        ; CreatedDate
        ; ModifiedDate
        ; CreatedBy
        ; ModifiedBy
        ; ChefRecipeDetails = new RecipePriceModel()
        ; ChefName
        ; UserChefDistanceInMeter
        ; UserChefDistanceInKM
        ; UserMessTimeDurationAvailableInMin
        ; UserMessTimeDurationAvailableInWords
        ; RecipeCurrentTimeLeftInMin
        ; RecipeCurrentTimeLeftInWords
        ; UserCurrentTimeLeftInMin
        ; UserCurrentTimeLeftInWords
}