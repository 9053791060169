import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import ErrorBoundary from './component/General/ErrorBoundary'
import "./index.css";
import ChefDetail from "./component/chef/ChefDetail";
import ChefList from "./component/chef/ChefList";
import Blogview from "./component/Blog/Blogview";
import Bloglist from "./component/Blog/Bloglist";
import Blog from "./component/Blog/Blog";
import About from "./component/About/About";
import RecipeStyle1 from "./component/RecipeStyle/RecipeStyle1";
import RecipeStyle2 from "./component/RecipeStyle/RecipeStyle2";
import RecipeStyle3 from "./component/RecipeStyle/RecipeStyle3";
import RecipeStyle4 from "./component/RecipeStyle/RecipeStyle4";
import RecipeGridView from "./component/RecipeStyle/RecipeGridView";
import RecipeListView from "./component/RecipeStyle/RecipeListView";
import Dashboard from "./component/Dashoard/Dashboard";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import Contact from "./component/Contact/Contact";
import Homepage from "./component/home/Homepage";
import Homepage2 from "./component/home/Homepage2";
import OurHistory from "./component/ourhistory/OurHistory";
import BookingTable from "./component/BookingTable/BookingTable";
import HowToPage from "./component/howtopage/HowToPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/login/Login";
import Signup from "./component/signup/Signup";
import { ChefDetailTest } from "./component/signup/ChefDetailTest";
import isDev from "./environment";
import { useSelector } from "react-redux";
import configureAppStore from "./configureAppStore";
import { sessionService } from 'redux-react-session';
import ProtectedRoutes from "./component/General/ProtectedRoutes";
import MainDashboardEntry from "./component/General/MainDashboardEntry";
import AppLogout from "./component/General/AppLogout";
import Modal from 'react-modal'; 
import Global from "./global";
import RecipeSearch from "./component/user/RecipeSearch";
import Questionnaire from "./component/promotion/Questionnaire";
import ThankYouPage from "./component/General/Thankyou";

const Root = () => {

  const IsAuthenticated = useSelector(state => state.SessionReducer.authenticated);

  let authRoutes;
  if (IsAuthenticated  === true) {
    authRoutes = <Route path={`${process.env.PUBLIC_URL}/Dashboard`} element={<AppLogout><Dashboard /></AppLogout>} ></Route>
  }
  console.log(' IsLoggedIn (root index file): ', IsAuthenticated)
  //console.log('Is development mode: ' + isDev());
  
  return (

    <BrowserRouter basename={"/"}>
      
      <Routes>
      <Route path={`${process.env.PUBLIC_URL}/`} element={<Signup />} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/Login`} element={<Login />} ></Route>
        {/* <Route path={`${process.env.PUBLIC_URL}/Signup`} element={<Signup />} ></Route> */}

        <Route path={`${process.env.PUBLIC_URL}/Homepage2`} element={<AppLogout><Homepage2 /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/Homepage`} element={<AppLogout><Homepage /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/About`} element={<AppLogout><About /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/Contact`} element={<AppLogout><Contact /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/Blog`} element={<AppLogout><Blog /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/Blogview`} element={<AppLogout><Blogview /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/Bloglist`} element={<AppLogout><Bloglist /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/ChefDetail/:id`} element={<AppLogout><ChefDetail /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/ChefList`} element={<AppLogout><ChefList /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/RecipeStyle1`} element={<AppLogout><RecipeStyle1 /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/RecipeStyle2`} element={<AppLogout><RecipeStyle2 /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/RecipeStyle3`} element={<AppLogout><RecipeStyle3 /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/RecipeStyle4`} element={<AppLogout><RecipeStyle4 /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/RecipeGridView`} element={<AppLogout><RecipeGridView /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/RecipeListView`} element={<AppLogout><RecipeListView /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/OurHistory`} element={<AppLogout><OurHistory /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/HowToPage`} element={<AppLogout><HowToPage /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/BookingTable`} element={<AppLogout><BookingTable /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/ChefDetailTest/Signup/edit/:id`} element={<AppLogout><Signup /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/ChefDetailTest`} element={<AppLogout><ChefDetailTest /> </AppLogout>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/MasterSearch`} element={<RecipeSearch/>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/Questionnaire`} element={<Questionnaire/>} ></Route>
        <Route path={`${process.env.PUBLIC_URL}/ThankyouPage`} element={<ThankYouPage/>} ></Route>
        {authRoutes}
        {/* <Route exact  path={`${process.env.PUBLIC_URL}/`} component={<ProtectedRoutes />} /> */}
        {/* <Route exact   path={`${process.env.PUBLIC_URL}/`} element={<AppLogout><MainDashboardEntry />} /> */}

        {/* <Route exact path={`${process.env.PUBLIC_URL}/`}  element={<AppLogout> <ProtectedRoutes> <MainDashboardEntry /></ProtectedRoutes> }/> */}
      </Routes >
    
    </BrowserRouter>
  );
}


//const store = configureStore({ reducer: rootReducer })

 const store = configureAppStore({});
 sessionService.initSessionService(store);
console.log('store.getState()', store.getState())

const validateSession = (session) => {
  
  // check if your session is still valid
  console.log('validateSession: ',session);
  return true;
}

const options = { refreshOnCheckAuth: true, redirectPath: '/Login', driver: 'COOKIES', validateSession };
 
sessionService.initSessionService(store, options)
  .then(() => console.log('Redux React Session is ready and a session was refreshed from your storage'))
  .catch(() => console.log('Redux React Session is ready and there is no session in your storage'));

// Set the "App element" to hide from screen readers when the modal is open
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  </Provider>
);

// reportWebVitals();
