import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ChefAccount from "./chef-account";
import { ChefDashboardHome } from "./chef-dashboard-home";
import { ChefMyReviews } from "./chef-my-reviews";
import { ChefMyOrders } from "./chef-myorders";
import { ChefShortlist } from "./chef-shortlist";
import { ChefStatement } from "./chef-statement";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useHistory } from "react-router-dom";
import { ChefLogout } from "../../../redux/ActionsCreator/chefAuthService";
import { useState, useLayoutEffect } from "react";
import ChefMyReceipes from "./chef-recipes";
import ChefAddReceipe from "./chef-add-recipe";
import { useEffect } from "react";
import ChefTimeline from "./chef-timeline";


const ChefTabForm = () => {

  let navigate = useNavigate();

  const { isLoggedIn, chef } = useSelector(state => state.ChefAuthReducer);


  const [_chef, setChef] = useState({});
  const [_chefImage, setImage] = useState("");

  useLayoutEffect(() => {

    if (chef !== null) {

      if (chef.email !== null || chef.email !== undefined) {
        setChef(chef);

        if (chef.dtoImage.imageBinary === undefined || chef.dtoImage.imageBinary === null || chef.dtoImage.imageBinary === '') {
          if (chef.gender === 'Female') {
            setImage("assets/images/DPFemale.jpg")
          }
          else {
            setImage("assets/images/DPMale.jpg")
          }
        }
        else {
          setImage(chef.dtoImage.imageBinary);
        }
      }
    }

  }, [chef]);

  const dispatch = useDispatch();

  const fnLogOut = () => {

    if (isLoggedIn === true) {

      dispatch(ChefLogout());
      console.log('state.chefAuthReducer ', isLoggedIn)
      sessionStorage.clear();
      localStorage.clear();
      navigate('/Login')
    }
  }

  return (
    <div className={`dashboard ${isLoggedIn ? 'sec-box slide-in' : ''}`}>
      <Tabs>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <TabList>
                <div
                  className="profile-sidebar brd-rd5 wow fadeIn"
                  data-wow-delay="0.2s"
                >
                  <div className="profile-sidebar-inner brd-rd5">
                    <div className="user-info red-bg">
                      <img
                        className="brd-rd50"
                        src={_chefImage}
                        alt="Chef Image"
                        itemprop="image"
                        style={{ height: '100px', width: '100px' }}
                      />
                      <div className="user-info-inner" >
                        <h5 itemprop="headline">
                          <a href="#" title="" itemprop="url" style={{ fontFamily: 'Rokkitt' }}>
                            {_chef.fullName}
                          </a>
                        </h5>
                        <span>
                          <a href="#" title="" itemprop="url" style={{ fontFamily: 'Rokkitt' }}>
                            {_chef.email}
                          </a>
                        </span>
                        <a
                          className="brd-rd3 sign-out-btn"
                          href="#"
                          title=""
                          itemprop="url"
                        >
                          <button type="button" className="fa fa-sign-out" onClick={fnLogOut}>SIGN OUT</button>
                          {/* <i className="fa fa-sign-out"></i> SIGN OUT */}
                        </a>
                      </div>
                    </div>
                    <div
                      className="dashboard-tabs nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >


                      <Tab
                        className="nav-link active nav-style"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <a href="#dashboard">
                          <i className="fa fa-dashboard"></i> DASHBOARD
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-account-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-account"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-account"
                        aria-selected="false"
                      >
                        <a href="#account-settings">
                          <i className="fa fa-cog"></i> ACCOUNT SETTINGS
                        </a>
                      </Tab>


                      <Tab
                        className="nav-link active nav-style"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <a href="#my-receipes">
                          <i className="fa fa-cutlery" aria-hidden="true"></i> MY RECEIPES
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link active nav-style"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <a href="#add-receipe">
                          <i class="fa fa-plus" aria-hidden="true"></i> PUBLISH RECEIPE
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-order-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-order"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-order"
                        aria-selected="false"
                      >
                        <a href="#my-orders">
                          <i className="fa fa-shopping-basket"></i> TODAYS ORDERS TIMELINE
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        <a href="#my-reviews">
                          <i className="fa fa-comments"></i> MY REVIEWS
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-order-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-order"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-order"
                        aria-selected="false"
                      >
                        <a href="#my-orders">
                          <i className="fa fa-shopping-basket"></i> MY ORDERS
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-shortlist-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-shortlist"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-shortlist"
                        aria-selected="false"
                      >
                        <a href="#shortlists">
                          <i className="fa fa-heart"></i> SHORTLISTS
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-statement-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-statement"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-statement"
                        aria-selected="false"
                      >
                        <a href="#statement">
                          <i className="fa fa-wpforms"></i> STATEMENT
                        </a>
                      </Tab>


                    </div>
                  </div>
                </div>
              </TabList>
            </div>
            <div className="col-md-8">

              <TabPanel>
                {/* Chef Dashboard Home */}
                <ChefDashboardHome />
              </TabPanel>

              <TabPanel>
                {/* Chef Account */}
                <ChefAccount />
              </TabPanel>

              <TabPanel>
                <ChefMyReceipes />
              </TabPanel>
              <TabPanel>
                <ChefAddReceipe />
              </TabPanel>

              <TabPanel>
                {/* Chef Timeline */}
                <ChefTimeline></ChefTimeline>
              </TabPanel>

              <TabPanel>
                {/* My Reviews */}
                <ChefMyReviews />
              </TabPanel>
              <TabPanel>
                {/* My Orders */}
                <ChefMyOrders />
              </TabPanel>
              <TabPanel>
                {/* Shortlist */}
                <ChefShortlist />
              </TabPanel>
              <TabPanel>
                {/* Statements */}
                <ChefStatement />
              </TabPanel>

            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );

}
export default ChefTabForm;
