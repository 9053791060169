import isDev from "./environment";
import Global from "./global";

const baseURL = process.env.REACT_APP_API_URL;

// Backend APIs Authentication JWT Token


export var authToken = '';

// if (isDev()) {

//   authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoic2hhaHJveiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InNyay5zaGFocm96NjNAZ21haWwuY29tIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiI3OTIyNzQ0YS0xMTlhLTQyNjYtOTc5ZC1hYjQzOGEyZmEyZmQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyYXRpb24iOiJEZWMgV2VkIDA3IDIwMjIgMTc6NDM6MDYgUE0iLCJuYmYiOjE2NzAzNDg1ODYsImV4cCI6MTY3MDQyMDU4NiwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NzA4OSIsImF1ZCI6Imh0dHBzOi8vbG9jYWxob3N0OjcwODkifQ.5yE-r8aCi6Tpn_9BysjTzRR6IS_kaT10uTv_QOdH-io'

//   sessionStorage.setItem('authToken', authToken);
// }
// else {

authToken = localStorage.AuthToken;
//}

console.log('authToken (endpoint): ', localStorage.AuthToken);

export const apiHeader = {

  'Authorization': `Bearer ${localStorage.getItem(Global.SessionKey.AuthToken)}`,
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

//console.log('baseURL (endpoint):', baseURL)

// RegisterController End Points

export const urlGetAllChef = `${baseURL}/Register/GetAllChef`
export const urlGetChefById = `${baseURL}/Register/GetChefById`
export const urlRegisterChef = `${baseURL}/Register/RegisterChef`
export const urlUpdateChef = `${baseURL}/Register/UpdateChef`
export const urlDeleteChef = `${baseURL}/Register/DeleteChef`
export const urlGetAllUser = `${baseURL}/Register/GetAllUsers`
export const urlGetUserById = `${baseURL}/Register/GetUserById`
export const urlRegisterUser = `${baseURL}/Register/RegisterUser`
export const urlUpdateUser = `${baseURL}/Register/UpdateUser`
export const urlDeleteUser = `${baseURL}/Register/DeleteUser`

// AccountController End Points

export const urlGetChefAuthentication = `${baseURL}/Account/GetChefAuthentication`
export const urlGetUserAuthentication = `${baseURL}/Account/GetUserAuthentication`

// ImageController End Points

export const urlImagePost = `${baseURL}/Image/PostImage`

// DropdownsController End Points

export const urlGetAllContinents = `${baseURL}/Dropdowns/GetAllContinents`
export const urlGetAllCountries = `${baseURL}/Dropdowns/GetAllCountries`
export const urlGetAllStates = `${baseURL}/Dropdowns/GetAllStates`
export const urlGetAllCities = `${baseURL}/Dropdowns/GetAllCities`
export const urlGetAllAreas = `${baseURL}/Dropdowns/GetAllAreas`
export const urlGetCitiesByCountryId = `${baseURL}/Dropdowns/GetCitiesByCountryId`
export const urlGetAreasByCityId = `${baseURL}/Dropdowns/GetAreasByCityId`
export const urlGetAllFoodTypes = `${baseURL}/Dropdowns/GetAllFoodTypes`
export const urlGetAllFoodCategories = `${baseURL}/Dropdowns/GetAllFoodCategories`
export const urlGetQuantityTypes = `${baseURL}/Dropdowns/GetQuantityTypes`
export const urlGetUserTypes = `${baseURL}/Dropdowns/GetUserTypes`

// ChefRecipeController End Points

export const urlPublishChefRecipe = `${baseURL}/ChefRecipe/PublishChefRecipe`
export const urlUpdateChefRecipe = `${baseURL}/ChefRecipe/UpdateChefRecipe`
export const urlDiActiveChefRecipe = `${baseURL}/ChefRecipe/DiActiveChefRecipe`
export const urlDiActiveMasterRecipe = `${baseURL}/ChefRecipe/DiActiveMasterRecipe`
export const urlGetAllChefsRecipes = `${baseURL}/ChefRecipe/GetAllChefsRecipes`
export const urlGetChefAllRecipes = `${baseURL}/ChefRecipe/GetChefAllRecipes`
export const urlGetChefSingleRecipe = `${baseURL}/ChefRecipe/GetChefSingleRecipe`
export const urlGetAutoCompleteDistinctRecipeNames = `${baseURL}/ChefRecipe/GetAutoCompleteDistinctRecipeNames`

// UserController End Points

export const urlGetMstrSearch = `${baseURL}/User/GetMstrSearch`
export const urlAddMstrSearch = `${baseURL}/User/AddMstrSearch`
export const urlUpdateMstrSearch = `${baseURL}/User/UpdateMstrSearch`
export const urlDiActiveMstrSearch = `${baseURL}/User/DiActiveMstrSearch`
export const urlGetAllUserMessesSchedulesOfSingleMstrMess = `${baseURL}/User/GetAllUserMessesSchedulesOfSingleMstrMess`
export const urlGetSingleUserMessSchedulesOfChef = `${baseURL}/User/GetSingleUserMessSchedulesOfChef`
export const urlAddFoodMessScheduleOfUser = `${baseURL}/User/AddFoodMessScheduleOfUser`
export const urlSearchRecipeCriteria = `${baseURL}/User/SearchRecipeCriteria`
export const urlMatchingFoodCriteriaOfUser = `${baseURL}/User/MatchingFoodCriteriaOfUser`
export const urlGetAllBookedMessListOfOneUser = `${baseURL}/User/GetAllBookedMessListOfOneUser`
export const urlGetMyChefs = `${baseURL}/User/GetMyChefs`
//Orders
export const urlGetMstrOrders = `${baseURL}/User/GetMstrOrders`
export const urlAddMstrOrder = `${baseURL}/User/AddMstrOrder`
export const urlUpdateMstrOrder = `${baseURL}/User/UpdateMstrOrder`
export const urlDeleteMstrOrder = `${baseURL}/User/DeleteMstrOrder`
export const urlUpdateUserOrderStatus = `${baseURL}/User/UpdateUserOrderStatus`
export const urlGetOrderComments = `${baseURL}/User/GetOrderComments`
export const urlAddOrderComments = `${baseURL}/User/AddOrderComments`
export const urlGetOrderList = `${baseURL}/User/GetOrderList`
export const urlGetOrderHistory = `${baseURL}/User/GetOrderHistory`
export const urlAddOrderHistory = `${baseURL}/User/AddOrderHistory`

//Prmotions
export const urlGetSurveyQuestionnaireBySurveyId = `${baseURL}/Promotion/GetSurveyQuestionnaireBySurveyId`
export const urlAddSurvey = `${baseURL}/Promotion/AddSurvey`