import React from "react";
import Modal from "react-modal";
import CustomerReviews from "./customer-reviews";
import Button from 'react-bootstrap/Button'
import table from "../../../../../src/assets/css/table.css";
import ChefAddRecipe from "../chef-add-recipe"


const customStyles = {
  content: {
    width: '90%', // Adjust the width as needed
    margin: 'auto', // Center the modal horizontally
  },
};

const EditRecipeModal = ({ isOpen, closeModal, recipe ,fnReloadRecipes }) => {
 // console.log('recipe: ', recipe)


  return (
    recipe ?
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>


        <div className="container">
          <Button onClick={closeModal} style={{ backgroundColor: '#de7a72', borderColor: '#de7a72', float: 'right' }}>Close</Button>
          <div className="recipe-thumb brd-rd5" style={{ position: 'absolute' }}>
            <a href="#" title="" itemprop="url">
              <img
                src="assets/images/resources/BBLogo-Square.jpeg"
                alt="order-img1.jpg"
                itemprop="image"
                style={{ borderRadius: '0px', width: '182px', height: '161px' }}
              />
            </a>
            <span className="post-rate yellow-bg brd-rd2">
              <i className="fa fa-star-o"></i> 4.25
            </span>
          </div>
          <div className="sec-heading text-center">
            <h2 className="text-uppercase fs-45" style={{whiteSpace:'normal',overflow:'hidden',fontSize:'40px'}}>{recipe.recipeName}</h2>
            <p >by Alex Hales</p>
          </div>

          <br></br>
          <br></br>
          <br></br>
          <div style={{ position: 'absolute', padding: '0px', width: '72%' }} >
            <ChefAddRecipe recipeModel={recipe} fnReloadRecipes={fnReloadRecipes} />
          </div>
        </div>



      </Modal> : ""
  );
};
export default EditRecipeModal;