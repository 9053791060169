import React, { useEffect, useState } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import StarIcon from '@material-ui/icons/Star';
import 'react-vertical-timeline-component/style.min.css';
import Button from 'react-bootstrap/Button'
import { useSelector, useDispatch } from 'react-redux';
import ManageOrderModal from './details/manage-order';
import { disablePageScroll, enablePageScroll } from '../../../utility';

const ChefTimeline = () => {

    const [manageOrderModalIsOpen, setViewModalIsOpen] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState(null);


    const openModal = (value, modalType) => {
        disablePageScroll();
        setSelectedRecipe(value);
        setViewModalIsOpen(true);
    };


    const closeModal = () => {
        enablePageScroll();
        setSelectedRecipe(null);
        setViewModalIsOpen(false);
      
    };


    return (

        <div className="tabs-wrp brd-rd5">
            
            <div className='row page-header'>
                <h4 itemprop="headline ">TODAY'S ORDER TIMELINE</h4>
            </div>
            <hr></hr>

            <div className='container'>
                <ManageOrderModal isOpen={manageOrderModalIsOpen} closeModal={closeModal} recipe={selectedRecipe} />
            </div>

            <VerticalTimeline>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date="1 PM"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Ali Rehman</h3>
                    <h4 className="vertical-timeline-element-subtitle-current">Chicken Biryani</h4>
                    <br></br>
                    <div>
                        Status : <span className='label label-warning'>Processing</span>
                    </div>
                    <div className=''>
                    <p style={{fontFamily:'math',fontSize:'small'}}><strong>User Comments : </strong>
                     Need extra rice , need more salty recipe.
                    </p>
                    </div>
                 
                    <br></br>
                    <div style={{ display: 'inline-block', float: 'right' }}>
                        <Button variant="warning" className='mini-button' onClick={() => openModal("")}>Manage Order</Button>{' '}
                        <Button variant="danger" className='mini-button' >Cooked</Button>{' '}
                    </div>
                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={"3 PM "}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Sushant Mehta</h3>
                    <h4 className="vertical-timeline-element-subtitle">Italian Pasta</h4>
                    <br></br>
                    <div>
                        Status : <span className='label label-info'>Ready for collection</span>
                    </div>
                    <div className=''>
                     <p style={{fontFamily:'math',fontSize:'small'}}><strong>User Comments : </strong>
                        Creative Direction, User Experience, Visual Design, SEO, Online Marketing
                    </p>
                    </div>
                 
                  
                    <br></br>
                    <div style={{ display: 'inline-block', float: 'right' }}>
                        <Button variant="warning" className='mini-button'>Manage Order</Button>{' '}
                        <Button variant="danger" className='mini-button'>Cooked</Button>{' '}
                    </div>
                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="6 PM"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Susane Martha</h3>
                    <h4 className="vertical-timeline-element-subtitle">Green Salad</h4>
                    <br></br>
                    <div>
                    Status : <span className='label label-default'>Not Started</span>
                    </div>
                    <p style={{fontFamily:'math',fontSize:'small'}}><strong>User Comments : </strong>
                        User Experience, Visual Design
                    </p>
                    <br></br>
                    <div style={{ display: 'inline-block', float: 'right' }}>
                        <Button variant="warning" className='mini-button'>Manage Order</Button>{' '}
                        <Button variant="danger" className='mini-button'>Cooked</Button>{' '}
                    </div>
                </VerticalTimelineElement>



                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2006 - 2008"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Web Designer</h3>
                    <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                    <p>
                        User Experience, Visual Design
                    </p>
                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="April 2013"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
                    <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
                    <p>
                        Strategy, Social Media
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="November 2012"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
                    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
                    <p>
                        Creative Direction, User Experience, Visual Design
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="2002 - 2006"
                    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
                    icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
                    <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                    <p>
                        Creative Direction, Visual Design
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<StarIcon />}
                />
            </VerticalTimeline>
        </div>
    )
}

export default ChefTimeline;

