const Global = {

    // Session Keys

    SessionKey: {
        LoginUserId: 'LoginUserId',
        AuthToken: 'AuthToken',
        LoginUserType: 'LoginUserType',
        LoginChefModel: 'ChefModel',
        LoginUserModel: 'UserModel',
        RowEffected: 'rowEffected',
        IsAuthenticated : 'IsAuthenticated',
        APIHeader : 'APIHeader',
        Longitude : 'Longitude',
        Latitude : 'Latitude'
    },

    AppKeys: {
        LogOutTimeInMinutes: 30
    },

    ImageTypeKey: {
        ChefDP: 1,
        UserDP: 2,
        RestaurantLogo: 3
    },

    eRemarks: {
        Success: 'success'
    },

    eResponse: {

        Default: 0,
        Success: 1,
        Error: -1,
        Warning: -2
    },

    AlertVariantType: {

        Primary: 'primary',
        Secondary: 'secondary',
        Success: 'success',
        Danger: 'danger',
        Warning: 'warning',
        Info: 'info',
        Light: 'light',
        Dark: 'dark'
    },

    AlertPopupType: {

        SimpleAlert: 'SimpleAlert',
        SimpleAlertWithNextLine: 'SimpleAlertWithNextLine',
        LinkAlert: 'LinkAlert',
        ContentAlert: 'ContentAlert',
        AdditionalContentAlert: 'AdditionalContentAlert',
        ContentAlertWithButton: 'ContentAlertWithButton',
    },




}


export default Global;

