import { DropdownListActionTypes as Type } from "../../ActionTypes/actionTypes";

const initialState = {
    
    SelectedCitiesDDLR: [],
    SelectedAreasDDLR: [],
    ContinentListDDLR: [],
    CountryListDDLR: [],
    StateListDDLR: [],
    CityListDDLR: [],
    AreaListDDLR: [],
    FoodTypeListDDLR: [],
    FoodCategoryListDDLR: [],
    QuantityTypeListDDLR: [],
    UserTypeListDDLR: [],
    MessTypeListDDLR: []
}

function DropdownListReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

        case Type.FETCH_CONTINENTS:
            return {
                ...state,
                ContinentListDDLR: payload
            }
        case Type.FETCH_COUNTRIES:
            return {
                ...state,
                CountryListDDLR: payload
            }
        case Type.FETCH_STATES:
            return {
                ...state,
                StateListDDLR: payload
            }
        case Type.FETCH_CITIES:
            return {
                ...state,
                CityListDDLR: payload
            }
        case Type.FETCH_AREAS:
            return {
                ...state,
                CountryListDDLR: payload
            }
        case Type.FETCH_CITIES_BY_COUNTRY:
            return {
                ...state,
                SelectedCitiesDDLR: payload
            }
        case Type.FETCH_AREAS_BY_CITY:
            return {
                ...state,
                SelectedAreasDDLR: payload
            }
        case Type.FETCH_FOOD_TYPES:
            return {
                ...state,
                FoodTypeListDDLR: payload
            }
        case Type.FETCH_FOOD_CATEGORY:
            return {
                ...state,
                FoodCategoryListDDLR: payload
            }
        case Type.FETCH_QUANTITY_TYPE:
            return {
                ...state,
                QuantityTypeListDDLR: payload
            }
        case Type.FETCH_USER_TYPE:
            return {
                ...state,
                UserTypeListDDLR: payload
            }
        default:
            return state;
    }
}

export default DropdownListReducer;