import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useHistory } from "react-router-dom";
import { UserLogout } from "../../../redux/ActionsCreator/userAuthService";
import { useState, useLayoutEffect } from "react";

import UserAccount from "./user-account";
import  UserDashboard  from "./user-dashboard-home";

import  UserBookedMesses  from "./user-booked-mess";
import  UserMatchingFood  from "./user-matching-food";
import UserMyChefs from "./user-mychefs";
import UserOrderHistory from "./user-orders-history";
import UserTodaysOrders from "./user-todays-orders";
import  UserStatement  from "./user-statements";
import UserAddFoodMessSchedule from "../../user/user-add-food-mess-schedule";



const UserTabForm = () => {

  let navigate = useNavigate();

  const { isLoggedIn, user } = useSelector(state => state.UserAuthReducer);


  const [_user, setUser] = useState({});
  const [_userImage, setImage] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  
  useLayoutEffect(() => {


    if(user !== null){
    if (user.email !== null || user.email !== undefined) {
      setUser(user);

      // if (user.dtoImage.imageBinary === undefined || user.dtoImage.imageBinary === null || user.dtoImage.imageBinary === '') {
      //   if (user.gender === 'Female') {
      //     setImage("assets/images/DPFemale.jpg")
      //   }
      //   else {
      //     setImage("assets/images/DPMale.jpg")
      //   }
      // }
      // else {
      //   setImage(user.dtoImage.imageBinary);
      // }
    }
  }

  }, [user]);

  const dispatch = useDispatch();

  const fnLogOut = () => {

    if (isLoggedIn === true) {

      dispatch(UserLogout());
      sessionStorage.clear();
      localStorage.clear();
      navigate('/Login')
    }
  }

  return (
    <div  className={`dashboard ${isLoggedIn ? 'sec-box slide-in' : ''}`}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <TabList>
                <div
                  className="profile-sidebar brd-rd5 wow fadeIn"
                  data-wow-delay="0.2s" 
                >
                  <div className="profile-sidebar-inner brd-rd5">
                    <div className="user-info red-bg">
                      <img
                        className="brd-rd50"
                        src="../images/avatar/small/chris.jpg"
                        alt="User Image"
                        itemprop="image"
                        style={{ height: '100px', width: '100px' }}
                      />
                      <div className="user-info-inner" >
                        <h5 itemprop="headline">
                          <a href="#" title="" itemprop="url"  style={{fontFamily: 'Rokkitt' }}>
                            {_user.name}
                          </a>
                        </h5>
                        <span>
                          <a href="#" title="" itemprop="url"  style={{fontFamily: 'Rokkitt' }}>
                            {_user.email}
                          </a>
                        </span>
                        <a
                          className="brd-rd3 sign-out-btn"
                          href="#"
                          title=""
                          itemprop="url"
                        >
                          <button type="button" className="fa fa-sign-out" onClick={fnLogOut}>SIGN OUT</button>
                          {/* <i className="fa fa-sign-out"></i> SIGN OUT */}
                        </a>
                      </div>
                    </div>
                    <div
                      className="dashboard-tabs nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >


                      <Tab
                        className="nav-link active nav-style"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <a href="#dashboard">
                          <i className="fa fa-dashboard"></i> DASHBOARD
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-order-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-order"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-order"
                        aria-selected="false"
                      >
                        <a href="#my-orders">
                          <i className="fa fa-shopping-basket"></i> MY TODAYS ORDERS
                        </a>
                      </Tab>


                      <Tab
                        className="nav-link active nav-style"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <a href="#my-receipes">
                          <i className="fa fa-cutlery" aria-hidden="true"></i> MY MATCHING FOOD SEARCH
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link active nav-style"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <a href="#add-receipe">
                          <i class="fa fa-plus" aria-hidden="true"></i> ADD FOOD MESS SCHEDULE
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-order-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-order"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-order"
                        aria-selected="false"
                      >
                        <a href="#my-orders">
                          <i className="fa fa-shopping-basket"></i> MY BOOKED MESS SCHEDULES
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        <a href="#my-reviews">
                          <i className="fa fa-comments"></i> ORDERS HISTORY
                        </a>
                      </Tab>

                     

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-shortlist-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-shortlist"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-shortlist"
                        aria-selected="false"
                      >
                        <a href="#shortlists">
                          <i className="fa fa-heart"></i> MY CHEFS
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-statement-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-statement"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-statement"
                        aria-selected="false"
                      >
                        <a href="#statement">
                          <i className="fa fa-wpforms"></i> STATEMENT
                        </a>
                      </Tab>

                      <Tab
                        className="nav-link nav-style"
                        id="v-pills-account-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-account"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-account"
                        aria-selected="false"
                      >
                        <a href="#account-settings">
                          <i className="fa fa-cog"></i> ACCOUNT SETTINGS
                        </a>
                      </Tab>

                    </div>
                  </div>
                </div>
              </TabList>
            </div>
            <div className="col-md-8">

              <TabPanel>
                {/* User Dashboard Home */}
                <UserDashboard />
              </TabPanel>

              <TabPanel>
                {/* Todays Orders */}
                <UserTodaysOrders/>
              </TabPanel>

              <TabPanel>
                {/* My Matching Food */}
              <UserMatchingFood/>
              </TabPanel>

              <TabPanel>
              {/* Add Food Mess Schedule */}
              <UserAddFoodMessSchedule/>
              </TabPanel>

              <TabPanel>
                {/* My Booked Mess List */}
               <UserBookedMesses/>
              </TabPanel>

              <TabPanel>
                {/* User Order History */}
               <UserOrderHistory/>
              </TabPanel>

              <TabPanel>
                {/* My Chefs */}
              <UserMyChefs/>
              </TabPanel>

              <TabPanel>
                {/* Statements */}
              <UserStatement/>
              </TabPanel>

              <TabPanel>
               {/* User Account */}
               <UserAccount />
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    </div>
  );

}
export default UserTabForm;
