import React from "react";
import Slider from "react-slick";
const Sliderimg1 = (
    <img src="assets/images/resources/testi-avatar.jpg" alt="Sliderimg" />
);
const Sliderimg2 = (
    <img src="assets/images/resources/testi-avatar.jpg" alt="Sliderimg" />
);
const Sliderimg3 = (
    <img src="assets/images/resources/testi-avatar.jpg" alt="Sliderimg" />
);
const SliderContent = [
    {
        image: Sliderimg1,
        title: "James Smith",
        description:
            "Torn between a rooftop bar and a tranquil ile country setting? Take inspiration from our ui restaurant collections.",
    },
    {
        image: Sliderimg2,
        title: "Chicken Reshmi Kabab",
        description:
            "Torn between a rooftop bar and a tranquil ile country setting? Take inspiration from our ui restaurant collections.",
    },
    {
        image: Sliderimg3,
        title: "Albie Liam",
        description:
            " Torn between a rooftop bar and a tranquil ile country setting? Take inspiration from our ui restaurant collections.",
    },
];

var settings = {
    fade: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
};

const CustomerReviews = () => {

    return (
        <>
        {   SliderContent.map((value, index) => (
           <div className="testi-item position-relative" style={{backgroundColor:'#e8ebf1'}} key={index}>
              
               <div className="testi-meta">
               {/* <div className="testi-avatar" style={{position: 'absolute',bottom: '1.125rem',right: '4.6875rem'}}>{value.image}</div> */}
                   <h4 className="h4 font-weight-bold">{value.title}</h4>
                   <div className="testi-rating">
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                   </div>
               </div>
               <p className="fs-22 font-style-italic">{value.description}</p>
              
           
           </div>
          
       ))
        }
        </>
        



    )
}
export default CustomerReviews;
