import { RegisterActionTypes as Type } from "../../ActionTypes/actionTypes";

const initialState = {

    chefListRR: [],
    chefRR: {}
}

function RegisterReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

        case Type.FETCH_CHEF:

            return {
                ...state,
                chefRR: payload
            }
        case Type.FETCH_CHEFS:

            return {
                ...state,
                chefListRR: payload
            }

        default:
            return state;
    }
}

export default RegisterReducer;