import Global from '../../../global';
import { GeneralActionTypes } from '../../ActionTypes/actionTypes'

const initialState = {
  isLoading: false
};

export default function GeneralReducer(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {
    case GeneralActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: payload,
        isLoading: false 
      };

    case GeneralActionTypes.CLEAR_MESSAGE:
      return { 
        ...state,
        message: "",
        isLoading: false 
       };

    case GeneralActionTypes.START_LOADING:
      return { ...state, isLoading: true };

    case GeneralActionTypes.END_LOADING:
      return { ...state, isLoading: false };

     

    default:
      return state;
  }
}