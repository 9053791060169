import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AddCircleOutline, AddchartOutlined, RemoveCircleOutline } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const SelectQuantity = () => {

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const controlProps = (item) => ({

    onChange: handleChange,
    value: item,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  return (
    <React.Fragment>
      <div className="container">
        <div class="row ">
          <div className="col-md-12 col-sm-12 col-lg-12 d-flex" >
            <div className="col-md-4 col-sm-4 col-lg-4">
              <strong className="left">BEAF BIRYANI</strong>
            </div>
            <div className="col-md-5 col-sm-5 col-lg-5 d-flex">
              <Button><RemoveCircleOutline /></Button>
              <Box component="form" sx={{ '& > :not(style)': { m: 0 }}} autoComplete="off">
                                        <TextField  sx={{ width: '8ch' }} id="filled-basic" value={1}  style={{height:'10px',textAlign:'center'}}  variant="outlined"  name="txtQuantity" disabled />
                                      
                                    </Box>
              {/* <input id="quantity" title="price of the quantity" name="quantity" className="brd-rd3" type="text" style={{ width: '100px', border: '2px solid #9e9e9e', height: '50%' }} maxLength="2" /> */}
              <Button><AddCircleOutline /></Button>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3">
              <strong>price </strong> AED 26.00
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className='modal-header'>
            <div className="col-md-12 col-sm-12 col-lg-12" >
              <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} square={true} >
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>Select any quantity (Choose 1)</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl>
                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"

                      >
                        <FormControlLabel value="regular" control={<Radio size='small' />} label="Regular (AED 26.00)" style={{ fontSize: '13px !important' }} />
                        <FormControlLabel value="half" control={<Radio size='small' />} label="Half (AED 38.00)" style={{ fontSize: '13px' }} />
                        <FormControlLabel value="full" control={<Radio size='small' />} label="Full (AED 54.00)" style={{ fontSize: '13px' }} />
                      </RadioGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Add-ons</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Checkbox {...label} defaultChecked size="small" /> <span style={{ fontSize: '13px' }}>Extra Mayoneese (AED 3.00) </span>
                    <Checkbox {...label} size="small" /> <span style={{ fontSize: '13px' }}>Special Sauce (AED 5.00) </span>
                    <Checkbox {...label} size="small" /> <span style={{ fontSize: '13px' }}>Ketchup (AED 2.00) </span>
                    <Checkbox {...label} size="small" /> <span style={{ fontSize: '13px' }}>Peperoni Sauce (AED 4.00) </span>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography>Extra Instructions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '75ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    ><div>
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={2}
                          placeholder='  Type your instructions here..'
                        />
                      </div>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

          </div>
        </div>

      </div>
    </React.Fragment>
  );
}

export default SelectQuantity;