import React, { useState, useEffect } from 'react';
// import Tabs from 'react-responsive-tabs';
// import 'react-responsive-tabs/styles.css';
import AboutPricing from '../Common/AboutPricing';
import RecipeGrid from '../Common/RecipeGrid';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaCalendar, FaSearch, FaCalendarWeek, FaThumbsUp, FaEye } from "react-icons/fa";
import { GiHotMeal, GiFruitBowl } from "react-icons/gi";
import { BiSolidCategory } from "react-icons/bi";
import { convertTime } from "../../utility";
import Accordion from 'react-bootstrap/Accordion';
import BasicExample from './chef-recipes-display';

const ChefMasterView = ({ recipe }) => {

    const presidents = [
        { name: 'George Washington', biography: <AboutPricing /> },
        { name: 'Theodore Roosevelt', biography: <RecipeGrid /> },
    ];

    function getTabs() {
        return presidents.map((president, index) => ({
            title: president.name,
            getContent: () => president.biography,
            /* Optional parameters */
            key: index,
            tabClassName: 'tab',
            panelClassName: 'panel',
            showInkBar: true,
            transform: true,
            showMore: true
        }));
    }
    return (
        recipe ?
            <>
                <div class="container" >
                    <div class="row" style={{ borderRadius: '15px', borderStyle: 'solid', borderColor: '#babcc1',padding:'2%' }}>
                        <div class="col-md-1 col-sm-1 col-lg-1">
                            <img src="/assets/images/resources/BBLogo-Square.jpeg"
                                alt="order-img1.jpg"
                                itemprop="image"
                                style={{ borderRadius: '4px', height: '110px' }}
                            />
                        </div>
                        <div class="col-md-10 col-sm-10 col-lg-10" style={{ lineHeight: '0.5', marginLeft: '55px' }}>
                            <h2 style={{ lineHeight: '1.7', fontSize: '20px' }}>Try Luck Restraunt</h2>
                            <div style={{ fontFamily: 'calibri', fontSize: '13px' }}>
                                <p>Pakistani, Indian, Arabic</p>
                                <p>Location: In Al Qasimiya, Al Nud, Sharjah, UAE</p>
                                <p>Mobile: +971-5482012</p>
                            </div>

                        </div>

                    </div>


                    <div className='row' style={{ marginTop: '25px' }}>
                        <div className='col-md-12'>
                            <div >
                                <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="home" title="Home">


                                        <div className='row' >
                                            <div className='col-md-6 col-sm-6 col-lg-6'>
                                                <div style={{ borderRadius: '15px', borderStyle: 'solid', borderColor: '#babcc1',padding:'2%' }}>

                                                </div>
                                            </div>
                                            <div className='col-md-6 col-sm-6 col-lg-6'>
                                                <div id={"cardId_" + recipe.chefRecipeId} key={recipe.chefRecipeId} style={{ float: 'right' }} >

                                                    <div className="slide-in" style={{ borderRadius: '15px', borderStyle: 'solid', borderColor: '#babcc1',fontSize:'13px' }}>
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                                <div className="chef-special">
                                                                    <ul className="recipe-cokng-info d-flex justify-content-center p-0 list-unstyled">
                                                                        <li className="fs-16 text-gray">
                                                                            <span>
                                                                                <svg viewBox="0 0 512 512" height="18" fill="#4fd675">
                                                                                    <g>

                                                                                        <path d="M498.682,435.326L297.917,234.56L63.357,0H45.026l-3.743,9.511c-9.879,25.104-14.1,50.78-12.205,74.249 c2.16,26.752,12.323,49.913,29.392,66.982L241.58,333.852l24.152-24.152l169.285,189.293c16.84,16.84,45.825,17.84,63.665,0 C516.236,481.439,516.236,452.879,498.682,435.326z" />
                                                                                    </g>
                                                                                    <g>

                                                                                        <path d="M156.728,291.442L13.317,434.853c-17.552,17.552-17.552,46.113,0,63.665c16.674,16.674,45.519,18.146,63.665,0 l143.412-143.412L156.728,291.442z" />
                                                                                    </g>
                                                                                    <g>

                                                                                        <path d="M490.253,85.249l-81.351,81.35l-21.223-21.222l81.351-81.351l-21.222-21.222l-81.35,81.35l-21.222-21.222l81.351-81.35 L405.366,0.361L299.256,106.471c-12.981,12.981-20.732,30.217-21.828,48.535c-0.277,4.641-1.329,9.206-3.074,13.548l68.929,68.929 c4.342-1.747,8.908-2.798,13.548-3.075c18.318-1.093,35.554-8.846,48.535-21.827l106.11-106.109L490.253,85.249z" />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>

                                                                            <label style={{ fontSize: '12px' }}>Instant recipe:</label>  <strong style={{ fontSize: '12px' }}>{recipe.isQuickSaleAvailable === true ? "YES" : "NO"}</strong>


                                                                        </li>
                                                                        <li className="fs-16 text-gray">
                                                                            <span>
                                                                                <svg viewBox="0 0 443.294 443.294" height="18" fill="#4fd675">
                                                                                    <path d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z" />
                                                                                    <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z" />
                                                                                </svg>
                                                                            </span>

                                                                            <label style={{ fontSize: '12px' }} htmlFor="">Hours:</label>  <strong style={{ fontSize: '12px' }}>{convertTime(recipe.startHour)} - {convertTime(recipe.endHour)}</strong>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3 col-sm-3 col-lg-3">
                                                                <div className="recipe-thumb brd-rd5">
                                                                    <a href="#" title="" itemprop="url">
                                                                        <img
                                                                            src="/assets/images/biryani.jpg"
                                                                            alt="order-img1.jpg"
                                                                            itemprop="image"
                                                                            style={{ borderRadius: '4px', width: '113px', height: '120px', marginLeft: '8px' }}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9 col-sm-9 col-lg-9">
                                                                <div className="recipe-info" style={{marginLeft:'5px'}}>
                                                                    <div>
                                                                        <FaCalendarWeek /> <label > Weekdays:</label>
                                                                        <span style={{ color: '#eb8a8e' }}>
                                                                            {" " + recipe.weekdays}
                                                                        </span>
                                                                        <div>
                                                                            <GiFruitBowl /> <label > Food Type: </label>
                                                                            <span style={{ color: '#eb8a8e' }}>
                                                                                {" " + recipe.foodType}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <BiSolidCategory /> <label > Food Category: </label>
                                                                            <span style={{ color: '#eb8a8e' }}>
                                                                                {" " + recipe.foodCategory}
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginTop: '5px' }}>
                                                                            <h5 itemprop="headline"  > <GiHotMeal />

                                                                                <a href="#" title="" itemprop="url" style={{fontSize:'medium'}}>
                                                                                    {" " + recipe.recipeName}
                                                                                </a>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div style={{ marginLeft: '30%' }}>
                                                            <table border='0' style={{ fontSize: 'smaller',minWidth:'200px' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Select</th>
                                                                        <th>Quantity</th>
                                                                        <th>Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        recipe.chefRecipeDetails?.map((value, index) => (
                                                                            <tr key={index}>
                                                                                <td><input type='checkbox' id={'price_'+value.chefRecipeDetailId}></input> </td>
                                                                                <td>{value.quantityType}</td>
                                                                                <td>{value.price + " AED"}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Tab>
                                    <Tab eventKey="profile" title="Profile">
                                        Tab content for Profile
                                    </Tab>
                                    <Tab eventKey="contact" title="Contact">
                                        Tab content for Contact
                                    </Tab>
                                </Tabs>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="float-checkout">

                </div>
                {/* <Tabs items={getTabs()} /> */}
            </> : ""
    );

}

export default ChefMasterView;