import React from "react";
import Modal from "react-modal";
import Button from 'react-bootstrap/Button'



const customStyles = {
  content: {
    width: '60%', // Adjust the width as needed
    margin: 'auto', // Center the modal horizontally
    zIndex: '3'
  },
};

const ManageOrderModal = ({ isOpen, closeModal, recipe }) => {
 // console.log('recipe: ', recipe)

  return (
   <div>

      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
    
    <Button onClick={closeModal} style={{ backgroundColor: '#de7a72', borderColor: '#de7a72' , float:'right' , padding:'4px'}}>Close</Button>
        <div className="container" style={{display:'inline-flex'}}> 
        
        <div className="recipe-thumb brd-rd5 flex" style={{position:'relative'}} >
                                        <a href="#" title="" itemprop="url">
                                            <img
                                                src="assets/images/resources/BBLogo-Square.jpeg"
                                                alt="order-img1.jpg"
                                                itemprop="image"
                                                style={{ borderRadius: '0px', width: '100px', height: '100px' }}
                                            />
                                        </a>
                                        <span className="post-rate yellow-bg brd-rd2">
                                            <i className="fa fa-star-o"></i> 4.25
                                        </span>
                                    </div>
          <div className="sec-heading text-center">
          <h2 className="text-uppercase fs-45" style={{whiteSpace:'normal',overflow:'hidden',fontSize:'20px'}}>Chicken Biryani</h2>
            <p>by Alex Hales</p>
          </div>
        </div>


        <section className="">
          <div className="container bg-white">
            <div className="row">
              <div className="col-md-12">
                <div className="special-ingradient d-flex">
                  <div className="col-md-4">
                  <h3 text-theme fs-20 d-block mb-2 theme-color>Pricing</h3>
                    <div className="w-100">
                      <table class="striped-table" border='0'>
                        <thead>
                          <tr>
                            <th>Quantity Type</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>

                       {
                         recipe?.chefReceipeDetails?.map((value,index) =>(
                           <tr key={index}>
                             <td>{value.quantityType}</td>
                             <td>{value.price + " AED"}</td>
                           </tr>
                         ))
                       }
                         
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-8">
                  <div className="pl-130">
                    <h3 className="text-theme fs-20 d-block mb-2 theme-color">User Info</h3>
                    <div className="list-unstyled gradient-list ">
                      <div className="d-flex">
                          <div >
                            <div style={{lineHeight:'50px'}}>
                              <label style={{ fontSize: '18px' }}>Weekdays: </label>
                              <span style={{ color: '#eb8a8e', fontSize: '18px' }}>
                                {" " + recipe?.weekdays}
                              </span>
                              <div>
                                <label style={{ fontSize: '18px' }}>Food Type: </label>
                                <span style={{ color: '#eb8a8e', fontSize: '18px' }}>
                                  {" " + recipe?.foodType}
                                </span>
                              </div>
                              <div>
                                <label style={{ fontSize: '18px' }}>Food Category: </label>
                                <span style={{ color: '#eb8a8e', fontSize: '18px' }}>
                                  {" " + recipe?.foodCategory}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
           <div className="container bg-white">
           <h3>Description</h3>
            <p className="end-descrip">
              ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.
            
            </p>
           </div>
            {/* <section className="sec-space">
              <div className="container">
                <div className="sec-heading text-center">
                  <span className="text-theme fs-20 d-block mb-2 theme-color">
                    What your Customers Said
                  </span>
                </div>
               
              </div>
            </section> */}
          </div>
        </section>




      </Modal> 
      </div>
  );
};
export default ManageOrderModal;