import React from "react";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import Header2 from "../header/Header2";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { apiHeader, authToken, urlDeleteChef, urlGetAllChef } from "../../endpoints";

export const ChefDetailTest = () => {

    const [chefList, setChefList] = useState([]);

    useEffect(() => {

        GetAllChefs();

    });

    async function GetAllChefs() {

       await fetch(`${urlGetAllChef}`, {
            method: 'GET',
            headers: apiHeader
        })
            .then((response) => { return response.json() })
            .then((responseData) => {
                setChefList(responseData);
            })
            .catch((error) => { console.log(error) })
    }

    async function deleteChef(id, event) {
        event.preventDefault();
        console.log('Delete chef id: ' + id);

        await fetch(`${urlDeleteChef}/${id}`, {
            method: 'DELETE',
            headers:apiHeader
        })
            .then((response) => console.log(response.json()))
            .then((responseData) => {
                console.log(responseData);
               // alert(responseData);
                GetAllChefs();
            })
            .catch((error) => { console.log(error) })
    }

    return (
        <div>
            <Header2 />
            <Banner title="Chef Account Detail Test" />

            <div className="container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>Complete Name</td>
                            <td>Email</td>
                            <td>Password</td>
                            <td>Phone</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            
                             chefList ? (
                                
                                chefList.map((chef) =>
                            
                                <tr key={chef.chefId}>
                                    <td>{chef.chefId}</td>
                                    <td>{chef.fullName}</td>
                                    <td>{chef.email}</td>
                                    <td>{chef.password}</td>
                                    <td>{chef.phone}</td>
                                    <td>
                                        <Link className='btn btn-success' to={`ChefDetailTest/Signup/edit/${chef.chefId}`}>Edit</Link>
                                        <button className='btn btn-danger' onClick={(e) => deleteChef(chef.chefId, e)}>Delete</button>
                                    </td>
                                </tr>
                            )
                             ) : 'no record found'
                        }
                    </tbody>
                </table>
            </div>
            <Footer></Footer>
        </div>
    )
}