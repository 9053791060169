import React, { useEffect, useState, useMemo, useLayoutEffect, useCallback } from 'react'
import { Controller, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchFoodCategory, fetchFoodTypes, fetchQuantityTypes } from '../../../redux/ActionsCreator/dropdownListService';
import RecipePriceModel from '../../Models/RecipePriceModel';
import { fetchAutoCompleteRecipeNames, PublishChefRecipe, UpdateChefRecipe } from '../../../redux/ActionsCreator/chefRecipeService';
import ChefRecipeModel from '../../Models/ChefRecipeModel';
import AlertPopup from '../../General/AlertPopup';
import AlertModel from '../../Models/AlertModel';
import Global from '../../../global';
//import { Spinner, Tooltip } from 'react-bootstrap';
import Select from "react-select";


const schema = yup.object().shape({

    RecipeName: yup.string().required('Recipe name is required').uppercase('First letter should be upper case'),
    ddlFoodType: yup.string().nullable().required('Food Type is required field'),
    ddlFoodCategory: yup.number().nullable().required('Food Category is required field'),
    StartHour: yup.string().required('Starting Hour is required field'),
    EndHour: yup.string().required('Ending Hour is required field'),
    ddlCustomizedRecipe: yup.string().required('Please answer this question').nullable(),
    ddlQuickSale: yup.string().required('Please answer this question'),
});

var lstPrice = [];
var errorCounter = 0;
var foodCategoryOptions = [];
var selectCategoryId = 0;
var recipeCode = "";
const ChefAddRecipe = (props) => {

    const { recipeModel , fnReloadRecipes} = props;


    const { register, watch, control, getValues, handleSubmit, formState: { errors, isSubmitSuccessful }, reset, resetField, setFocus, setValue, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const { field } = useController({ name: 'ddlFoodCategory', control });
    const { value: ddlFoodCategoryValue, onChange: ddlFoodCategoryOnChange, ...restFoodCategoryField } = field;

    var PriceModel = new RecipePriceModel();
    var AlertOptions = new AlertModel();
    var AlertOptionsInstantOrderInfo = new AlertModel();

    const dispatch = useDispatch();

    const _apiHeader = useSelector((state) => state.ChefAuthReducer.chef?.apiHeader);
    const _quantityTypeListDDLR = useSelector((state) => state.DropdownListReducer.QuantityTypeListDDLR);

    // Hooks Assignments 
    const [weekdaysChecked, setWeekdaysChecked] = useState([]);
    const [quantityPriceArr, setQuantityPriceArr] = useState([]);
    const [errorMsgWeekdays, setErrorMsgWeekdays] = useState('')
    const [errorMsgQuantity, setErrorMsgQuantity] = useState('')
    const [errorMsgPrice, setErrorMsgPrice] = useState('')
    const [alertOptions, setAlertOptions] = useState(AlertOptions);
    const [alertOptionsInstantOrderInfo, setAlertOptionsInstantOrderInfo] = useState(AlertOptionsInstantOrderInfo);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMsg, setLoadingMsg]  = useState('')
    const [selectedFoodCategoryOption, setSelectedFoodCategoryOption] = useState();
    
     // On Page Load 
     useEffect(() => {

        if (_quantityTypeListDDLR.length === 0) 
        {
            dispatch(fetchQuantityTypes())
          //  props.fetchQuantityTypes();
        }

        AlertOptionsInstantOrderInfo.Type = Global.AlertPopupType.SimpleAlert;
        AlertOptionsInstantOrderInfo.Variant = Global.AlertVariantType.Info;
        AlertOptionsInstantOrderInfo.Message = "Information alert !  Customers can contact you and ask for instant orders during your available hours, the customer will receive an order parcel from your doorstep with cash payment.";

        setAlertOptionsInstantOrderInfo(AlertOptionsInstantOrderInfo);

        // if (props._fetchAutoCompleteRecipeNames.length <= 0) {
        props.fetchAutoCompleteRecipeNames();
        // }

        if (props._foodTypeListDDLR.length <= 0) {
            props.fetchFoodTypes();
        }

        if (props._foodCategoryListDDLR.length <= 0) {
            props.fetchFoodCategories();
        }

        

    }, []);

    //Load Recipe Data on Edit Mode
    useEffect(() => {
       
        console.log('recipeModel:', recipeModel);

        if (recipeModel !== undefined && recipeModel !== null) {

            recipeCode = recipeModel.recipeCode;
            setValue('RecipeName', recipeModel.recipeName);
            setValue('ddlFoodCategory', recipeModel.foodCategoryId);
            setValue('ddlFoodType', recipeModel.foodTypeId);
            setValue('StartHour', recipeModel.startHour);
            setValue('EndHour', recipeModel.endHour);
            setValue('ddlQuickSale', recipeModel.isQuickSaleAvailable == true ? 1 : 0);
            setValue('ddlCustomizedRecipe', recipeModel.isRecipeCustomizationAllow == true ? 1 : 0);

            if (recipeModel?.chefRecipeDetails !== undefined && recipeModel?.chefRecipeDetails !== null) {
                setQuantityPriceArr([]);
                lstPrice = [];

                for (let index = 0; index < recipeModel.chefRecipeDetails.length; index++) {

                    const _recipePrice = recipeModel.chefRecipeDetails[index];
                    var _quantityTypeId = 'Quantity_' + _recipePrice.quantityTypeId;


                    PriceModel = new RecipePriceModel();
                    PriceModel.QuantityTypeId = _recipePrice.quantityTypeId;
                    PriceModel.QuantityType = _recipePrice.quantityType;
                    PriceModel.Price = _recipePrice.price;

                    lstPrice.push(PriceModel);

                    setQuantityPriceArr(lstPrice);

                    console.log(' Quantity Type Populated list: ', lstPrice);
                    setValue('QuantityPrice' + PriceModel.QuantityTypeId, PriceModel.Price)

                    if (lstPrice.length <= 0) {
                        document.getElementById("lblQuantityPrice").style.visibility = "hidden";
                        document.getElementById("lblQuantityPriceErrMsg").innerHTML = ""
                        document.getElementById("lblQuantityPriceErrMsg").style.visibility = "hidden";
                    }
                    else {
                        document.getElementById("lblQuantityPrice").style.visibility = "visible";
                        document.getElementById("lblQuantityPriceErrMsg").style.visibility = "visible";
                    }

                    if (_quantityTypeListDDLR?.length > 0) {
                        document.getElementById(_quantityTypeId).checked = true;
                    }
                }

                if (recipeModel.weekdays !== undefined && recipeModel.weekdays !== null) {

                    var _weekdays = [];
                    setWeekdaysChecked([]);

                    if (recipeModel.weekdays.includes(',')) {
                        var daysArray = recipeModel.weekdays.split(',');
                        for (var i = 0; i < daysArray.length; i++) {
                            //console.log('Day ' + (i + 1) + ': ' + daysArray[i]);
                            setValue('Weekdays_' + daysArray[i], daysArray[i])
                            _weekdays.push(daysArray[i])
                        }
                    } else {
                        _weekdays.push(recipeModel.weekdays)
                        setValue('Weekdays_' + recipeModel.weekdays, recipeModel.weekdays)
                    }
                    //  console.log('Array Week day on load: ', _weekdays);
                    if (recipeModel.weekdays.length <= 0) {
                        document.getElementById("divLblWeekdays").style.visibility = "hidden";
                    }
                    else {
                        setWeekdaysChecked(_weekdays);
                        document.getElementById("divLblWeekdays").style.visibility = "visible";
                    }


                }
            }
            document.getElementById('btnPublishRecipe').innerHTML = 'Update';
            document.getElementById('lblHeader').innerHTML = 'UPDATE YOUR RECIPE';
        }
        else {
            var recipecodeCntrl = document.getElementById("cntrlRecipeCode");
           
            if (recipecodeCntrl) {
                recipecodeCntrl.style.visibility = "hidden";
            }
            setFocus("RecipeName");
        }
    }, [recipeModel, _quantityTypeListDDLR])

    const onChangeRecipeNames = (e) => {

        var prefix = e.target.value;
        console.log(prefix);

        if (prefix !== "" && prefix !== undefined) {
            const searchValue = watch('RecipeName');
            console.log(searchValue);
            // const filteredItems = props._fetchAutoCompleteRecipeNames.filter((item) =>
            //     item.name.toLowerCase().startsWith(searchValue.toLowerCase())
            // );
        }
    }

    const onChangeFoodType = (e) => {

    }

    const onChangeFoodCategory = (data) => {
        setSelectedFoodCategoryOption(data);
        selectCategoryId = data.value;
        console.log('selected food category: ', selectCategoryId)
    }

    const WeekDaysList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    // Add/Remove weekdays checked item from list
    const handleWeekdays = (event) => {
        var updatedList = [...weekdaysChecked];
        if (event.target.checked) {

            updatedList = [...weekdaysChecked, event.target.value];
        } else {
            updatedList.splice(weekdaysChecked.indexOf(event.target.value), 1);
        }
        setWeekdaysChecked(updatedList);
      //  console.log('Weekdays selected list: ', weekdaysChecked);

        if (updatedList.length <= 0) {
            document.getElementById("divLblWeekdays").style.visibility = "hidden";
        }
        else {
            document.getElementById("divLblWeekdays").style.visibility = "visible";
        }
    };

    // Add/Remove quantities checked item from list
    const handleQuantityType = (event) => {
        
        PriceModel = new RecipePriceModel();
        PriceModel.QuantityTypeId = event.target.value;
        PriceModel.QuantityType = event.target.attributes["text"].value;
        PriceModel.Price = 0;

        lstPrice = [...quantityPriceArr];

        if (event.target.checked) {

            lstPrice = [...quantityPriceArr, PriceModel];

            setQuantityPriceArr(lstPrice);
            console.log('Quantity Type selected list: ', lstPrice);

            setTimeout(() => {
                setFocus('QuantityPrice' + PriceModel.QuantityTypeId)
            }, 500);

        } else {

            if (lstPrice != null && lstPrice != undefined) {
                if (lstPrice.length > 0) {
                    for (let i = 0; i < lstPrice.length; i++) {
                        if (parseInt(lstPrice[i].QuantityTypeId) === parseInt(PriceModel.QuantityTypeId)) {

                            lstPrice.splice(i, 1); // 2nd parameter means remove one item only
                            setQuantityPriceArr(lstPrice);
                           // console.log('Quantity Type un-selected list : ', quantityPriceArr);
                            break;
                        }
                    }
                    if (quantityPriceArr.length === 0) {

                    }
                }
            }
        }

        if (lstPrice.length <= 0) {
            document.getElementById("lblQuantityPrice").style.visibility = "hidden";
            document.getElementById("lblQuantityPriceErrMsg").innerHTML = ""
            document.getElementById("lblQuantityPriceErrMsg").style.visibility = "hidden";
        }
        else {
            document.getElementById("lblQuantityPrice").style.visibility = "visible";
            document.getElementById("lblQuantityPriceErrMsg").style.visibility = "visible";
        }
    };

    // Generate string of weekdays checked items
    const checkedWeekdaysItems = weekdaysChecked.length
        ? weekdaysChecked.reduce((total, item) => {
            return total + ", " + item;
        })
        : "";

    // Return classes based on whether item is checked
    var isWeekdaysChecked = (item) => weekdaysChecked.includes(item) ? "checked-item" : "not-checked-item";
    var isQuantityChecked = (item) => quantityPriceArr.includes(item) ? "checked-item" : "not-checked-item";

    const handleChangePrice = (event) => {

        PriceModel = new RecipePriceModel();

        if (event.target.value != '') {

            //console.log('price: ', event.target.value)

            if (parseInt(event.target.value.length) > 4) {
                setErrorMsgPrice('Price cannot be more than 4 digit');
            }
            else {
                setErrorMsgPrice('')
            }
            PriceModel.QuantityTypeId = event.target.attributes["id"].value;
            PriceModel.Price = event.target.value;

            if (lstPrice != null && lstPrice != undefined) {
                if (lstPrice.length > 0) {
                    for (let i = 0; i < lstPrice.length; i++) {
                        if (parseInt(lstPrice[i].QuantityTypeId) === parseInt(PriceModel.QuantityTypeId)) {

                            lstPrice[i].Price = parseInt(PriceModel.Price);

                            setQuantityPriceArr(lstPrice);
                            //  console.log('Quantity Type With Price : ', quantityPriceArr);
                            break;
                        }
                    }
                }
            }
        }
        else {

            var index = lstPrice.findIndex(x => x.QuantityTypeId == event.target.attributes["id"].value);

            if (parseInt(index) > -1) {
                lstPrice[index].Price = '';
                setQuantityPriceArr(lstPrice);
            }
        }
    }

    const handleChangeTiming = (event) => {

        errorCounter = 0;

        if (event != undefined && event != null) {
            var hourValue = event.target.value;
        }


        var _startHour = 0.0;
        var _endHour = 0.0;

        if (event != undefined && event != null && event.target.attributes["name"].value == "StartHour") {
            _startHour = parseFloat(hourValue);

            if (isNaN(_startHour) || _startHour == null) {

                setFocus('StartHour');
                setError('StartHour', { type: 'custom', message: 'Number is required' });
                setValue('StartHour', '');
                errorCounter++;
                return;
            }
        }
        else {
            _startHour = parseFloat(getValues("StartHour"));
        }

        if (event != undefined && event != null && event.target.attributes["name"].value == "EndHour") {
            _endHour = parseFloat(hourValue);

            if (isNaN(_endHour) || _endHour == null) {

                setFocus('EndHour');
                setError('EndHour', { type: 'custom', message: 'Number is required' });
                setValue('EndHour', '');
                errorCounter++;
                return;
            }
        }
        else {
            _endHour = parseFloat(getValues("EndHour"));
        }

        if (_startHour <= 0) {
            setFocus('StartHour');
            setError('StartHour', { type: 'custom', message: 'Start hour is required field' });
            errorCounter++;
            return;
        }

        if (_endHour <= 0) {
            setFocus('EndHour');
            setError('EndHour', { type: 'custom', message: 'End hour is required field' });
            errorCounter++;
            return;
        }

        if (_startHour > 24 || _startHour < 0) {

            setFocus('StartHour');
            setError('StartHour', { type: 'custom', message: 'Invalid hour (time should be between 0-24 hours)' });
            errorCounter++;
            return;
        }

        if (_endHour > 24 || _endHour < 0) {

            setFocus('EndHour');
            setError('EndHour', { type: 'custom', message: 'Invalid hour (time should be between 0-24 hours)' });
            errorCounter++;
            return;
        }

        if (_endHour <= _startHour) {

            setFocus('EndHour');
            setError('EndHour', { type: 'custom', message: 'End hour should be greater than Start hour' });
            errorCounter++;
            return;
        }

        if (_endHour > _startHour) {

            var hourDiff = _endHour - _startHour;
            hourDiff = parseFloat(hourDiff);

            if (hourDiff <= 1) {

                setFocus('EndHour');
                setError('EndHour', { type: 'custom', message: 'Recipe availibility timing hours should be more than 2 hour' });
                errorCounter++;
                return;
            }
            else {
                setError('EndHour', { type: 'custom', message: '' });
                setError('StartHour', { type: 'custom', message: '' });
            }
        }


    }

    const isNumberKey = (event) => {

        var charCode = (event.which) ? event.which : event.keyCode

        if (charCode < 48 || charCode > 57) {

            if (event.target.attributes["name"].value == "StartHour") {
                document.getElementsByName('StartHour').value('');
            }
            else if (event.target.attributes["name"].value == "EndHour") {
                document.getElementsByName('EndHour').value('');
            }
            return false;
        }
        return true;
    }

    //Submit or update the recipe in the system
    const handleSubmitAccount = useCallback(async (data) => {

        setIsLoading(true);

        //  console.log("Submitted data: ", data);

        setAlertOptions(new AlertModel());
        setErrorMsgWeekdays('');
        setErrorMsgQuantity('');
        setErrorMsgPrice('');

        handleChangeTiming(undefined);

        var isQuantityTrue = true;
        var isQuantityPriceTrue = true;
        var isWeekdayTrue = true;

        if (quantityPriceArr.length === 0) {
            errorCounter++;
            isQuantityTrue = false;
            setErrorMsgQuantity('Please select any one quantity type for the recipe');
        }
        else if (quantityPriceArr != null && quantityPriceArr != undefined) {
            if (quantityPriceArr.length > 0) {

                let errorMsg = "";
                for (let i = 0; i < quantityPriceArr.length; i++) {
                    if (quantityPriceArr[i].Price == 0 || quantityPriceArr[i].Price == '' || quantityPriceArr[i].Price == null || quantityPriceArr[i].Price == undefined) {

                        if (errorMsg == "") {
                            document.getElementById(quantityPriceArr[i].QuantityTypeId).focus();
                            errorMsg = "Please enter the price(s) of " + quantityPriceArr[i].QuantityType + " quantity\n";
                            errorCounter++;
                        }
                        else {
                            errorMsg += " | " + quantityPriceArr[i].QuantityType + " quantity";
                            errorCounter++;
                        }
                    }
                }
                setErrorMsgPrice(errorMsg);

                if (errorMsg.length > 0) {
                    errorCounter++;
                    isQuantityPriceTrue = false;
                }
                else {

                    for (let i = 0; i < quantityPriceArr.length; i++) {
                        if (parseInt(quantityPriceArr[i].Price) > 0) {
                            if (parseInt(quantityPriceArr[i].Price.toString().length) > 4) {

                                if (errorMsg == "") {
                                    document.getElementById(quantityPriceArr[i].QuantityTypeId).focus();
                                    errorMsg = quantityPriceArr[i].QuantityType;
                                    errorCounter++;
                                }
                                else {
                                    errorMsg += " | " + quantityPriceArr[i].QuantityType;
                                    errorCounter++;
                                }
                            }
                        }
                    }
                    errorMsg += " quantity price(s) cannot be more than 4 digit";
                    setErrorMsgPrice(errorMsg);
                }
            }
        }

        if (weekdaysChecked.length === 0) {
            errorCounter++;
            isWeekdayTrue = false;
            setErrorMsgWeekdays('Please select any one week day available for the recipe')
        }

        if (isQuantityTrue === false) { // Quantity should be focused if not selected
            document.getElementById("Quantity_1").focus();
            document.getElementById("lblQuantities").focus();
        }

        if (isWeekdayTrue === false) { // Weekdays should be focused if not selected
            document.getElementById("Weekdays").focus();
        }

        if (errorCounter > 0) {
            setIsLoading(false);
            return;
        }

        var _chefRecipeModel = new ChefRecipeModel();

        if (recipeModel !== undefined && recipeModel !== null) {
            _chefRecipeModel.ChefRecipeId = recipeModel.chefRecipeId;
            _chefRecipeModel.RecipeId = recipeModel.recipeId;
            alert('chefRecipeId: '+ recipeModel.chefRecipeId)
        }

        _chefRecipeModel.ChefId = props._chefId;
        _chefRecipeModel.CreatedBy = props._chefId;
        _chefRecipeModel.EndHour = data.EndHour;
        _chefRecipeModel.StartHour = data.StartHour;
        _chefRecipeModel.FoodCategoryId = data.ddlFoodCategory;
        _chefRecipeModel.FoodTypeId = data.ddlFoodType;
        _chefRecipeModel.IsActive = true;
        _chefRecipeModel.IsQuickSaleAvailable = data.ddlQuickSale == 1 ? true : false;
        _chefRecipeModel.IsRecipeCustomizationAllow = data.ddlCustomizedRecipe == 1 ? true : false;;
        _chefRecipeModel.RecipeName = data.RecipeName;
       
        let strWeekDays = "";
        if (weekdaysChecked.length > 0) {

            for (let i = 0; i < weekdaysChecked.length; i++) {
                if (strWeekDays === "") {
                    strWeekDays = weekdaysChecked[i];
                }
                else {
                    strWeekDays += "," + weekdaysChecked[i];
                }
            }
            _chefRecipeModel.Weekdays = strWeekDays;
        }

        var _priceModelList = [];

        if (quantityPriceArr.length > 0) {

            for (let i = 0; i < quantityPriceArr.length; i++) {

                PriceModel = new RecipePriceModel();

                PriceModel.Price = quantityPriceArr[i].Price;
                PriceModel.QuantityType = quantityPriceArr[i].QuantityType;
                PriceModel.QuantityTypeId = quantityPriceArr[i].QuantityTypeId;

                _priceModelList.push(PriceModel);
            }
            _chefRecipeModel.ChefRecipeDetails = _priceModelList;
        }
        //  console.log('final model data before calling API', _chefRecipeModel);

        if (parseInt(_chefRecipeModel.ChefRecipeId) > 0) {
            setLoadingMsg('Recipe is updating...')
            //Calling chef recipe service for updating the chef recipe
            props.updateChefRecipe(_chefRecipeModel, );
        }
        else {
            setLoadingMsg('Recipe is publishing...')
            //Calling chef recipe service for publishing the chef recipe
            props.publishChefRecipe(_chefRecipeModel, );
        }
    });

    //Checking then Showing result after every submission & updation
    useEffect(() => {

        if (foodCategoryOptions == null || foodCategoryOptions == undefined || foodCategoryOptions.length == 0) {
            for (let index = 0; index < props._foodCategoryListDDLR.length; index++) {
                const option = {
                    value: props._foodCategoryListDDLR[index].id,
                    label: props._foodCategoryListDDLR[index].foodCategory
                }
                foodCategoryOptions.push(option);
            }
        }

        if (parseInt(localStorage.rowEffected) > 0) {
            
            setTimeout(() => {

                if (recipeModel !== undefined && recipeModel !== null) {
                    fnReloadRecipes();
                }

                setIsLoading(false);
                
                  //  alert('Chef recipe published successfully');
                  AlertOptions.Type = Global.AlertPopupType.AdditionalContentAlert;
                  AlertOptions.Heading = "Congratulation! Chef"
                  AlertOptions.Variant = Global.AlertVariantType.Success;
  
                  var _foodCategory = "";
                  var _foodType = "";
  
                  try {
                      _foodCategory = props._foodCategoryListDDLR.filter(x => x.id == parseInt(getValues("ddlFoodCategory")))[0].foodCategory;
                      _foodType = props._foodTypeListDDLR.filter(x => x.id == parseInt(getValues("ddlFoodType")))[0].foodType;
  
                  } catch (error) {
                      console.log(error)
                  }
  
                  const fullRecipeName = _foodCategory + " " + _foodType + " " + getValues("RecipeName") + " ";

                if (recipeModel === undefined || recipeModel === null) { //resetting all fields

                    setWeekdaysChecked([]);
                    document.getElementById("divLblWeekdays").style.visibility = "hidden";

                    setQuantityPriceArr([]);

                    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
                    for (var i = 0; i < checkboxes.length; i++) {
                        checkboxes[i].checked = false;
                    }

                    resetField('StartHour');
                    resetField('EndHour');
                    resetField('ddlFoodCategory');
                    resetField('ddlFoodType');
                    resetField('ddlQuickSale');
                    resetField('ddlCustomizedRecipe');
                    resetField('RecipeName');

                    AlertOptions.Message = fullRecipeName + " recipe has been successfully published for our valued customers. Feel free to review all your recipes in the My Recipes section."
                    
                    window.scrollTo(0, 20);
                }
                else{
                    AlertOptions.Message = fullRecipeName + " recipe has been updated successfully for our valued customers."
                    // document.getElementById('lblHeader').focus();
                    // window.scrollTo(0, 0);
                    
                }

                

                setErrorMsgWeekdays('');
                setErrorMsgQuantity('');
                setErrorMsgPrice('');
                localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
                setAlertOptions(AlertOptions);
               
            setFocus('RecipeName');
            

            }, 3500);
        }
        else if (localStorage.rowEffected == '-1' || localStorage.rowEffected == '-2') {

            setTimeout(() => {
                setIsLoading(false);

                AlertOptions.Type = Global.AlertPopupType.AdditionalContentAlert;

                if (props._notifyReturnModelCRR !== undefined && props._notifyReturnModelCRR.rowEffected == Global.eResponse.Error) {
                    AlertOptions.Variant = Global.AlertVariantType.Danger;
                    AlertOptions.Heading = "Oh, chef! You got an error!"
                    AlertOptions.Message = props._notifyReturnModelCRR.errorMsg;
                }
                else if (props._notifyReturnModelCRR !== undefined && props._notifyReturnModelCRR.rowEffected == Global.eResponse.Warning) {
                    AlertOptions.Variant = Global.AlertVariantType.Warning;
                    AlertOptions.Heading = "Oh, chef! You got a warning, Please consider it."
                    AlertOptions.Message = props._notifyReturnModelCRR.warningMsg;
                }
                localStorage.setItem(Global.SessionKey.RowEffected, Global.eResponse.Default)
                setAlertOptions(AlertOptions);
                window.scrollTo(0, 20);

            }, 3500);
        }
    });

    function AutocompleteItem({ item }) {
        return (
          <div className="autocomplete-item">
            <option className="recipe-name">{item.Value}</option>
          </div>
        );
      }
      
    return (
        <>
            <div
                className="tab-pane"
                id="v-pills-account"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
            >
                <div className="tabs-wrp account-settings brd-rd5">
                    <div className='row page-header'>
                        <h4 itemprop="headline" id='lblHeader'>PUBLISH YOUR RECIPE</h4></div>
                    <AlertPopup alertOptions={alertOptions}></AlertPopup>
                    <div className="account-settings-inner">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <div className="profile-info-form-wrap">
                                    <form className="profile-info-form" onSubmit={handleSubmit(handleSubmitAccount)}>
                                        <div className="row mrg20">
                                              {/* Recipe Code */}
                                            <div className="col-md-6 col-sm-6 col-lg-6 d-flex" id='cntrlRecipeCode' >
                                                <div className="col-md-4 col-sm-4 col-lg-4" >
                                                    <label>Recipe Code : </label>
                                                    </div>
                                                    <div className="col-md-8 col-sm-8 col-lg-8" >
                                                        <label>
                                                            <span style={{ color: '#eb8a8e', fontSize: '18px', fontWeight: 'bold' }}>{" " + recipeCode} </span>
                                                        </label>
                                                    </div>
                                               
                                            </div>
                                            <hr></hr>
                                            {/* Recipe Name (Auto Complete) */}
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <label>
                                                    Recipe Name <sup>*</sup>
                                                </label>
                                                <Controller
                                                    name="RecipeName"
                                                    control={control}

                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <div className='autocomplete'>
                                                            <input
                                                                {...field}
                                                                {...register("RecipeName", {
                                                                    required: true,
                                                                    pattern: /^[A-Z]*$/ // regular expression that matches uppercase alphabet characters
                                                                })}
                                                                list="options"
                                                                autoComplete="off"
                                                                placeholder="Enter Recipe Name"
                                                                style={{ height: '62px' }}
                                                                className="brd-rd3"
                                                                type='text'
                                                            />
                                                            <datalist id="options" className='autocomplete-options'>
                                                                {props._fetchAutoCompleteRecipeNames.map((option) => (
                                                                    <option key={option.key} value={option.value} className="recipe-name autocomplete-option">
                                                                        <span className="food-type-category">
                                                                            {option.value2} | {option.value3}
                                                                        </span>
                                                                    </option>
                                                                ))}
                                                            </datalist>
                                                        </div>
                                                    )}
                                                />
                                                <p className="text-danger">{errors.RecipeName?.message}</p>
                                                {errors.RecipeName?.type === 'pattern' && <p>Only uppercase alphabet characters are allowed</p>}
                                            </div>

                                            {/* Recipe Name */}
                                            {/* <div className="col-md-12 col-sm-12 col-lg-12">
                                                <label>
                                                    Recipe Name <sup>*</sup>
                                                </label>
                                                <input {...register("RecipeName")} name="RecipeName"
                                                    className="brd-rd3"
                                                    type="text"
                                                    placeholder="Enter Recipe Name"
                                                    style={{ height: '62px' }}
                                                />
                                                <p className="text-danger">{errors.RecipeName?.message}</p>

                                            </div> */}

                                            {/* Food Type */}
                                            <div className="col-md-6 col-sm-6 col-lg-6">
                                                <label>
                                                    Food Type <sup>*</sup>
                                                </label>
                                                <div className="select-wrp">
                                                    <select {...register("ddlFoodType")} name="ddlFoodType"
                                                        onChange={(e) => { onChangeFoodType(e.target.value) }} style={{ height: '62px' }}>
                                                        <option value='' selected>Select Food Type</option>
                                                        {
                                                            props._foodTypeListDDLR?.map((value, index) => (
                                                                <option value={value.id} key={index}>{value.foodType}</option>
                                                            ))
                                                        }

                                                    </select>
                                                    <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlFoodType?.message}</p>
                                                </div>
                                            </div>
                                            {/* Food Category */}
                                            <div className="col-md-6 col-sm-6 col-lg-6">
                                                <label>
                                                    Food Category <sup>*</sup>
                                                </label>

                                                <div className="select-wrp">
                                                    <Select
                                                        {...register("ddlFoodCategory")}
                                                        className='select-input'
                                                        placeholder="Select Food Category"
                                                        isClearable
                                                        options={foodCategoryOptions}
                                                        value={ddlFoodCategoryValue ? foodCategoryOptions.find(x => x.value === ddlFoodCategoryValue) : ddlFoodCategoryValue}
                                                        defaultValue=""
                                                        onChange={option => ddlFoodCategoryOnChange(option ? option.value : option)}
                                                        {...restFoodCategoryField}
                                                    />
                                                </div>
                                                <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlFoodCategory?.message}</p>

                                                {/* <div className="select-wrp">
                                                    <select {...register("ddlFoodCategory")} name="ddlFoodCategory" onChange={(e) => { onChangeFoodCategory(e.target.value) }}>
                                                        <option value='' selected>Select Food Category</option>
                                                        {
                                                            props._foodCategoryListDDLR?.map((value, index) => (
                                                                <option value={value.id} key={index}>{value.foodCategory}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div> */}
                                            </div>
                                            <hr></hr>
                                            {/* Quantity Types */}
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <label  >
                                                    Select the quantity type of this recipe: <sup>*</sup>
                                                </label>
                                                <p className="text-danger" id="lblQuantities">{errorMsgQuantity}</p>

                                                <div className="radiobtn-list-container">
                                                    {_quantityTypeListDDLR.map((item, index) => (
                                                        <div key={index} style={{ display: 'inline-block' }}>
                                                            <input {...register("Quantity_") + item.id} id={`Quantity_${item.id}`}  name={`Quantity_${item.id}`} 
                                                            value={item.id} text={item.quantityType}
                                                             type="checkbox" style={{ height: '30px' }} onChange={handleQuantityType}
                                                             />
                                                            <span className={isQuantityChecked(item.quantityType)}>{item.quantityType}</span>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* Quantity with price */}
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <label id="lblQuantityPrice" hidden>
                                                    Enter price of the selected quantity: <sup>*</sup>
                                                </label>
                                                <p id="lblQuantityPriceErrMsg" className="text-danger">{errorMsgPrice}</p>
                                                {
                                                    quantityPriceArr.map((item, index) => (
                                                        <>
                                                            <div key={index} style={{ display: 'inline-block' }}>
                                                                <label>{item.QuantityType}</label>
                                                                <div key={index} >
                                                                    <input {...register('QuantityPrice' + item.QuantityTypeId)} id={item.QuantityTypeId} onChange={handleChangePrice} placeholder="price" title="price of the quantity" name={item.QuantityType} className="brd-rd3" type="number" style={{ width: '90px' }} maxLength="4" />
                                                                    <label>AED</label>
                                                                </div>

                                                            </div>
                                                        </>
                                                    ))}
                                            </div>
                                            <hr></hr>
                                            {/* Weekdays */}
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <label for="Weekdays" id="lblWeekdays">
                                                    Select Available Weekdays of this Recipe <sup>*</sup>
                                                </label>
                                                <p className="text-danger">{errorMsgWeekdays}</p>

                                                <div className="radiobtn-list-container">
                                                    {WeekDaysList.map((item, index) => (
                                                        <div key={index} style={{ display: 'inline-block' }}>
                                                            <input {...register("Weekdays_" + item)} id={"Weekdays_" + item} name={item} value={item} type="checkbox" onChange={handleWeekdays} style={{ height: '30px' }} />
                                                            <span className={isWeekdaysChecked(item)}>{item}</span>
                                                        </div>
                                                    ))}
                                                </div>


                                                <div id='divLblWeekdays' style={{ color: 'green', visibility: 'hidden' }}>
                                                    {`This recipe will be available only on `} <b>{checkedWeekdaysItems}</b>
                                                </div>
                                                <br></br>
                                            </div>
                                            <hr></hr>
                                            {/* Start Hour */}
                                            <div className="col-md-6 col-sm-12 col-lg-6">
                                                <label>
                                                    Starting Hour <span style={{ fontWeight: 'lighter', fontSize: 'small' }}> (24 hour format)</span> <sup>*</sup>
                                                </label>
                                                <input {...register("StartHour")} name="StartHour"
                                                    className="brd-rd3"
                                                    type="text"
                                                    style={{ width: '80px' }}
                                                    onKeyUp={handleChangeTiming}
                                                    //  onKeyPress={isNumberKey}
                                                    maxLength={5}
                                                    placeholder="Hour"
                                                    title='Starting hour of this recipe'
                                                />
                                                <p className="text-danger">{errors.StartHour?.message}</p>

                                            </div>
                                            {/*( End Hour */}
                                            <div className="col-md-6 col-sm-12 col-lg-6">
                                                <label>
                                                    Ending Hour <span style={{ fontWeight: 'lighter', fontSize: 'small' }}> (24 hour format)</span><sup>*</sup>
                                                </label>
                                                <input {...register("EndHour")} name="EndHour"
                                                    className="brd-rd3"
                                                    type="text"
                                                    style={{ width: '80px' }}
                                                    onKeyUp={handleChangeTiming}
                                                    // onKeyPress={isNumberKey}
                                                    maxLength={5}
                                                    placeholder="Hour"
                                                    title='Ending hour of this recipe'
                                                />
                                                <p className="text-danger">{errors.EndHour?.message}</p>
                                            </div>
                                            {/* Customize Recipe */}
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <label>
                                                    Can the customer demand you customize this recipe?? <sup>*</sup>
                                                </label>

                                                <div className="select-wrp">
                                                    <select {...register("ddlCustomizedRecipe")} name="ddlCustomizedRecipe"  >
                                                        <option selected value=''>Select</option>
                                                        <option value='1'>Yes</option>
                                                        <option value='0'>No</option>
                                                    </select>
                                                    <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlCustomizedRecipe?.message}</p>
                                                </div>
                                            </div>
                                            {/* Is Quick Sale Recipe */}
                                            <div className="col-md-12 col-sm-12 col-lg-12">
                                                <label>
                                                    Is customer can do instant order for this recipe? <sup>*</sup>
                                                </label>
                                                <hr></hr>

                                                <AlertPopup alertOptions={alertOptionsInstantOrderInfo}></AlertPopup>

                                                <div className="select-wrp">
                                                    <select {...register("ddlQuickSale")} name="ddlQuickSale"  >
                                                        <option selected value=''>Select</option>
                                                        <option value='1'>Yes</option>
                                                        <option value='0'>No</option>
                                                    </select>
                                                    <p className="text-danger" style={{ padding: '10px' }}>{errors.ddlQuickSale?.message}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {isLoading &&
                                            <div className='cover-spin'>
                                                <h4 className='spin-waiting-msg'>{loadingMsg}</h4>
                                            </div>
                                        }
                                        <div className="col-md-12 col-sm-12 col-lg-12">
                                            <button type="submit" id='btnPublishRecipe' className="theme-btn-secondary mt-20" style={{ float: 'right' }} >
                                                Publish Recipe <span></span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {

    return {

        _apiHeader: state.ChefAuthReducer.chef?.apiHeader,
        _chefId: state.ChefAuthReducer.chef?.chefId,
        _foodTypeListDDLR: state.DropdownListReducer.FoodTypeListDDLR,
        _foodCategoryListDDLR: state.DropdownListReducer.FoodCategoryListDDLR,
        // _quantityTypeListDDLR: state.DropdownListReducer.QuantityTypeListDDLR,
        _notifyReturnModelCRR: state.ChefRecipeReducer.notifyReturnModelCRR,
        _fetchAutoCompleteRecipeNames: state.ChefRecipeReducer.fetchAutoCompleteRecipeNamesCRR
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        fetchFoodTypes: () => { dispatch(fetchFoodTypes()) },
        fetchFoodCategories: () => { dispatch(fetchFoodCategory()) },
        // fetchQuantityTypes: () => { dispatch(fetchQuantityTypes()) },
        fetchAutoCompleteRecipeNames: () => { dispatch(fetchAutoCompleteRecipeNames()) },
        publishChefRecipe: (ChefRecipeModel) => { dispatch(PublishChefRecipe(ChefRecipeModel)) },
        updateChefRecipe: (ChefRecipeModel) => { dispatch(UpdateChefRecipe(ChefRecipeModel)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChefAddRecipe)