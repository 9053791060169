import { PromotionActionTypes as Type} from "../../ActionTypes/actionTypes";

const initialState = {
    fetchSurveyQuestionnairePR: [],
    isSurveyAdded : 0
}


function PromotionRegisterReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
       
        case Type.FETCH_QUESTIONNAIRE:
            return {
                ...state,
                fetchSurveyQuestionnairePR: payload
            }

        case Type.ADD_SURVEY:
            console.log("ADD_SURVEY (PromotionRegisterReducer): " ,payload);
            return {
                ...state,
                isSurveyAdded: payload
            }

        default:
            return state;
    }
}

export default PromotionRegisterReducer;