import ChefRecipeModel from "./ChefRecipeModel";

export default class MstrSearchModel {
    SearchId=0;
    UserId;
    RecipeSearchText;
    PayTypeId;
    FoodTypeIds;
    FoodType;
    FoodCategoryIds;
    FoodCategory;
    Weekdays;
    FromTime;
    ToTime;
    IsActive;
    IsDelivery;
    DistanceInMeter = 0;
    Latitude = 0;
    Longitude = 0;
    CreatedDate;
    ArrayRecipeNames = [];
    ChefReceipesList = new ChefRecipeModel();
}