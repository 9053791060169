import React, { Fragment } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Banner from "../header/Banner";
import ChefTabForm from "./chef/ChefTabForm";
import UserTabForm from "./user/UserTabForm";
import { useSelector } from "react-redux";

const Dashboard = () => {

  const loginType = useSelector((state) => state.SessionReducer.user.loginType);

  return (
    <>
      <Header />
      {/* <!-- Breadcrumbs --> */}
      <Banner title="Dashboard" />
      {
        loginType === "user" ? <UserTabForm /> :
        loginType === "admin" ? null :
        loginType === "chef" ? <ChefTabForm /> :
        null
      }
      <Footer />
    </>
  );
};

export default Dashboard;
