export const ChefStatement = () => {

    return(
        <>
        <div
                    className="tab-pane"
                    id="v-pills-statement"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="tabs-wrp brd-rd5">
                      <h4 itemprop="headline">STATEMENTS</h4>
                      <div className="select-wrap-inner">
                        <div className="select-wrp2"></div>
                        <div className="select-wrp2">
                          <select>
                            <option>Select Date Range</option>
                            <option>Select Date Range</option>
                            <option>Select Date Range</option>
                          </select>
                        </div>
                      </div>
                      <div className="statement-table">
                        <table>
                          <thead>
                            <tr>
                              <th>TRANSACTION ID</th>
                              <th>ORDER ID</th>
                              <th>DATE</th>
                              <th>DETAIL</th>
                              <th>AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>#30737723</td>
                              <td>8720</td>
                              <td>Aug 17,2017</td>
                              <td>Order - Misumisu Thai</td>
                              <td>
                                <span className="red-clr">$35.97</span>
                              </td>
                            </tr>
                            <tr>
                              <td>#30737723</td>
                              <td>8720</td>
                              <td>Aug 17,2017</td>
                              <td>Order - Jet's Kitchen</td>
                              <td>
                                <span className="red-clr">$35.97</span>
                              </td>
                            </tr>
                            <tr>
                              <td>#30737723</td>
                              <td>8720</td>
                              <td>Aug 17,2017</td>
                              <td>Order - Misumisu Thai</td>
                              <td>
                                <span className="red-clr">$35.97</span>
                              </td>
                            </tr>
                            <tr>
                              <td>#30737723</td>
                              <td>8720</td>
                              <td>Aug 17,2017</td>
                              <td>Order - Misumisu Thai</td>
                              <td>
                                <span className="red-clr">$35.97</span>
                              </td>
                            </tr>
                            <tr>
                              <td>#30737723</td>
                              <td>8720</td>
                              <td>Aug 17,2017</td>
                              <td>Order - Misumisu Thai</td>
                              <td>
                                <span className="red-clr">$35.97</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="pagination-wrapper text-center style2">
                        <nav className="pt-60">
                          <ul className="pagination d-flex justify-content-center mb-0">
                            <li className="prev-page">
                              <a href="javascript:void(0)">
                                <i className="fa fa-angle-left"></i>Prev
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">1</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">2</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">3</a>
                            </li>
                            <li className="page-on">......</li>
                            <li>
                              <a href="javascript:void(0)">28</a>
                            </li>
                            <li className="next-page">
                              <a href="javascript:void(0)">
                                Next<i className="fa fa-angle-right"></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
        </>
    );
}