import { apiHeader, urlGetAllAreas, urlGetAllCities, urlGetAllContinents, urlGetAllCountries, urlGetAllFoodCategories, urlGetAllFoodTypes, urlGetAllStates, urlGetAreasByCityId, urlGetCitiesByCountryId, urlGetQuantityTypes, urlGetUserTypes } from "../../endpoints";
import { DropdownListActionTypes } from "../ActionTypes/actionTypes";

export const fetchContinents = () => {
    return async (dispatch) => {

        await fetch(`${urlGetAllContinents}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {
                dispatch({ type: DropdownListActionTypes.FETCH_CONTINENTS, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchCountries = () => {

    return async (dispatch) => {

        await fetch(`${urlGetAllCountries}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: DropdownListActionTypes.FETCH_COUNTRIES, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchStates = () => {
    return async (dispatch) => {

        await fetch(`${urlGetAllStates}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {
                dispatch({ type: DropdownListActionTypes.FETCH_STATES, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchCities = () => {

    return async (dispatch) => {

        await fetch(`${urlGetAllCities}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: DropdownListActionTypes.FETCH_CITIES, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchAreas = () => {

    return async (dispatch) => {

        await fetch(`${urlGetAllAreas}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: DropdownListActionTypes.FETCH_AREAS, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchCitiesByCountry = (countryId) => {
    return async (dispatch) => {

        await fetch(`${urlGetCitiesByCountryId}/${countryId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {
                dispatch({ type: DropdownListActionTypes.FETCH_CITIES_BY_COUNTRY, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchAreasByCity = (cityId) => {
    return async (dispatch) => {

        await fetch(`${urlGetAreasByCityId}/${cityId}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {
                dispatch({ type: DropdownListActionTypes.FETCH_AREAS_BY_CITY, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchFoodTypes = () => {

    return async (dispatch) => {

        await fetch(`${urlGetAllFoodTypes}`, {
            method: 'GET',
            headers: apiHeader
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: DropdownListActionTypes.FETCH_FOOD_TYPES, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchFoodCategory = () => {

    return async (dispatch) => {

        await fetch(`${urlGetAllFoodCategories}`, {
            method: 'GET',
            headers: apiHeader
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: DropdownListActionTypes.FETCH_FOOD_CATEGORY, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchQuantityTypes = () => {

    return async (dispatch) => {

        await fetch(`${urlGetQuantityTypes}`, {
            method: 'GET',
            headers: apiHeader
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: DropdownListActionTypes.FETCH_QUANTITY_TYPE, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}

export const fetchUserTypes = () => {

    return async (dispatch) => {

        await fetch(`${urlGetUserTypes}`, {
            method: 'GET',
            headers: JSON.parse(localStorage.APIHeader)
        }).then((response) => {
            return response.json();
        })
            .then((responseData) => {

                dispatch({ type: DropdownListActionTypes.FETCH_USER_TYPE, payload: responseData })
            })
            .catch((error) => { console.log(error) })
    }
}