
class ImageModel  {
     ImageId
    ;EntityId
    ;ImageName
    ;Ext
    ;ImageTypeId
    ;ImageBinary
    ;IsActive

}

export default ImageModel;