import React, { Component, Fragment, useEffect, useState, } from "react";
import Header2 from "../header/Header2";
import Footer from "../footer/Footer";
import { useNavigate  } from "react-router-dom";
import MasterSearch from "./user-master-search";
import { Link, useParams } from "react-router-dom";

const RecipeSearch = () => {

    let navigate = useNavigate();

    let SearchText = useParams();


    return(
        <>
            <Header2 navigate={navigate} />
            <br></br>
            <div class="container" style={{
                border: 'solid 1px',
                borderColor: '#de7a72',
                padding: '30px',
                borderRadius: '30px'
            }}>
                <MasterSearch closeModal={undefined} SearchText={SearchText}></MasterSearch>
            </div>
            <br></br>
            <Footer />
        </>
    );
}

export default RecipeSearch;