import { ReactComponent as Motor } from "../../../svg/motorbike.svg";

export const ChefDashboardHome = () => {

    return(
        <>
          <div
                    className="tab-box-content fade show active tab-box-content"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="dashboard-wrapper brd-rd5">
                      <div className="welcome-note yellow-bg brd-rd5">
                        <h4 itemprop="headline">WELCOME TO YOUR ACCOUNT</h4>
                        <p itemprop="description">
                          Things that get tricky are things like burgers and
                          fries, or things that are deep-fried. We do have a
                          couple of burger restaurants that are capable of doing
                          a good
                        </p>
                        <img
                          src="assets/images/resource/welcome-note-img.png"
                          alt="welcome-note-img.png"
                          itemprop="image"
                        />
                        <a
                          className="remove-noti"
                          href="#"
                          title=""
                          itemprop="url"
                        >
                          <img
                            src="assets/images/close-icon.png"
                            alt="close-icon.png"
                            itemprop="image"
                          />
                        </a>
                      </div>
                      <div className="dashboard-title">
                        <h4 itemprop="headline">SUGGESTED BRANCHES</h4>
                        <span>
                          Define{" "}
                          <a
                            className="red-clr"
                            href="#"
                            title=""
                            itemprop="url"
                          >
                            Search criteria
                          </a>{" "}
                          to search for specific
                        </span>
                      </div>
                      <div className="restaurants-list">
                        <div
                          className="featured-restaurant-box style3 brd-rd5 wow fadeInUp"
                          data-wow-delay="0.2s"
                        >
                          <div className="featured-restaurant-thumb">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/logo.jpeg"
                                alt="restaurant-logo1-1.png"
                                itemprop="image"
                                style={{ borderRadius: '15px', width: '182px', height: '161px' }}
                              />
                            </a>
                          </div>
                          <div className="featured-restaurant-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                New York Branch
                              </a>
                            </h4>
                            <ul className="post-meta">
                              <li>
                                <i className="fa fa-check-circle-o"></i> Min
                                order $50
                              </li>
                              <li>
                                <Motor/> 30 min
                              </li>
                            </ul>
                          </div>
                          <div className="view-menu-liks">
                            <span className="red-bg brd-rd4 post-likes">
                              <i className="fa fa-heart-o"></i> 12
                            </span>
                            <a
                              className="brd-rd3"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              View Menu
                            </a>
                          </div>
                        </div>
                        <div
                          className="featured-restaurant-box style3 brd-rd5 wow fadeInUp"
                          data-wow-delay="0.3s"
                        >
                          <div className="featured-restaurant-thumb">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/logo.jpeg"
                                alt="restaurant-logo1-2.png"
                                itemprop="image"
                                style={{ borderRadius: '15px', width: '182px', height: '161px' }}
                              />
                            </a>
                          </div>
                          <div className="featured-restaurant-info">
                            <span className="red-clr">
                              5th Avenue Washington DC
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Washington Branch
                              </a>
                            </h4>
                            <ul className="post-meta">
                              <li>
                                <i className="fa fa-check-circle-o"></i> Min
                                order $40
                              </li>
                              <li>
                                <Motor/> 25 min
                              </li>
                            </ul>
                          </div>
                          <div className="view-menu-liks">
                            <span className="red-bg brd-rd4 post-likes">
                              <i className="fa fa-heart-o"></i> 20
                            </span>
                            <a
                              className="brd-rd3"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              View Menu
                            </a>
                          </div>
                        </div>
                        <div
                          className="featured-restaurant-box style3 brd-rd5 wow fadeInUp"
                          data-wow-delay="0.4s"
                        >
                          <div className="featured-restaurant-thumb">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/logo.jpeg"
                                alt="restaurant-logo1-1.png"
                                itemprop="image"
                                style={{ borderRadius: '15px', width: '182px', height: '161px' }}
                              />
                            </a>
                          </div>
                          <div className="featured-restaurant-info">
                            <span className="red-clr">
                              Araam Building New Jersey
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                New Jersey Branch
                              </a>
                            </h4>
                            <ul className="post-meta">
                              <li>
                                <i className="fa fa-check-circle-o"></i> Min
                                order $100
                              </li>
                              <li>
                                <Motor /> 20 min
                              </li>
                            </ul>
                          </div>
                          <div className="view-menu-liks">
                            <span className="red-bg brd-rd4 post-likes">
                              <i className="fa fa-heart-o"></i> 15
                            </span>
                            <a
                              className="brd-rd3"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              View Menu
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        </>
    );
}