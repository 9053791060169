import { OrdersActionTypes as Type } from "../../ActionTypes/actionTypes";

const initialState = {

    fetchMasterOrderOR: [],
    fetchOrderCommentsOR: [],
    fetchOrderHistoryOR: [],
    fetchOrderList: []
  
}

function OrdersReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case Type.FETCH_MASTER_ORDERS:
            return {
                ...state,
                fetchMasterOrderOR: payload,
            };
        case Type.ADD_MASTER_ORDER:
            return {
                ...state,
                fetchMasterOrderOR: [...state.fetchMasterOrderOR, payload]
            };
        case Type.UPDATE_MASTER_ORDER:
            const updatedIndex = state.fetchMasterOrderOR.findIndex(order => order.orderId === payload.orderId);
            if (updatedIndex !== -1) {
                // If found, create a new array with the updated order
                const updatedOrders = [
                    ...state.fetchMasterOrderOR.slice(0, updatedIndex), // Keep items before the updated order
                    payload,
                    ...state.fetchMasterOrderOR.slice(updatedIndex + 1) // Keep items after the updated order
                ]

                return {
                    ...state,
                    fetchMasterOrderOR: updatedOrders
                };
            }
        case Type.DELETE_MASTER_ORDER:
            const { orderId } = payload; // Assuming the payload contains the ID of the order to be deleted

            // Filter out the deleted order from the fetchMasterOrderOR list
            const updatedOrders = state.fetchMasterOrderOR.filter(order => order.orderId !== orderId);

            return {
                ...state,
                fetchMasterOrderOR: updatedOrders
            };
        case Type.UPDATE_ORDER_STATUS:

            // Update the status of the specific order within the fetchMasterOrderOR list
            const updatedOrdersUOS = state.fetchMasterOrderOR.map(order => {

                const { orderId, orderStatusId } = payload; // Assuming the payload contains the ID of the order and the new status

                const statusName = state.fetchOrderList.find(status => status.statusId === orderStatusId)?.statusName;

                if (order.orderId === orderId && statusName !== "" && statusName !== undefined) {
                    return {
                        ...order,
                        orderStatus: statusName,
                        statusId: orderStatusId // Update the status with the newStatus provided in the payload
                    };
                }
                return order;
            });
            return {
                ...state,
                fetchMasterOrderOR: updatedOrdersUOS
            };
        case Type.ADD_ORDER_COMMENTS:
            return {
                ...state,
                fetchOrderCommentsOR: [...state.fetchOrderCommentsOR, payload]
            };
        case Type.FETCH_ORDER_COMMENTS:
            return {
                ...state,
                fetchOrderCommentsOR: payload
            };
        case Type.FETCH_ORDER_HISTORY:
            return {
                ...state,
                fetchOrderHistoryOR: payload
            };
        case Type.ADD_ORDER_HISTORY:
            return {
                ...state,
                fetchOrderHistoryOR: [...state.fetchOrderHistoryOR, payload]
            };
        case Type.FETCH_ORDER_LIST:
            return {
                ...state,
                fetchOrderList: payload
            };
        
        default:
            return state;

    }
}

export default OrdersReducer;