import React from 'react';
import Header2 from '../header/Header2';

class ThankYouPage extends React.Component {
    render() {
        return (
            <html lang="en">
                <head>
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>Thank You</title>
                    <link href='https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700' rel='stylesheet' type='text/css' />
                    <style>
                        @import url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);
                        @import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
                    </style>
                    <link rel="stylesheet" href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css" />
                    <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/jquery-1.9.1.min.js"></script>
                    <script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/html5shiv.js"></script>
                </head>
                <body>
					<Header2></Header2>
                    <header class="site-header" id="header">
                        <h1 class="site-header__title" data-lead-id="site-header-title">THANK YOU!</h1>
                    </header>

                    <div class="main-content">
                        <i class="fa fa-check main-content__checkmark" id="checkmark"></i>
                        <p class="main-content__body" data-lead-id="main-content-body">Thank you for completing the questionnaire. Your input is valuable to us and will help us improve our services/products. We appreciate your time and effort.
</p>
                    </div>
                    <p>Please close the window</p>

                    <footer class="site-footer" id="footer">
                        <p class="site-footer__fineprint" id="fineprint">Copyright ©2024 | All Rights Reserved by {" "}  <a className="text-theme" href="https://brownonions.co" title="Brown Onions">
                  Brown Onions 
                  </a></p>
                    </footer>
                </body>
            </html>
        );
    }
}

export default ThankYouPage;
