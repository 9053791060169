export const ChefMyReviews = () => {

    return(
        <>
          <div
                    className="tab-pane"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div className="tabs-wrp brd-rd5">
                      <h4 itemprop="headline">MY REVIEWS</h4>
                      <div className="select-wrap-inner">
                        <div className="select-wrp2">
                          <select>
                            <option>New Reviews</option>
                            <option>Old Reviews</option>
                            <option>Popular Reviews</option>
                          </select>
                        </div>
                        <div className="select-wrp2">
                          <select>
                            <option>Select Date Range</option>
                            <option>Select Date Range</option>
                            <option>Select Date Range</option>
                          </select>
                        </div>
                      </div>
                      <div className="review-list">
                        <div className="review-box brd-rd5">
                          <h4 itemprop="headline">
                            <a href="#" title="" itemprop="url">
                              RESTAURANT DEMO
                            </a>
                          </h4>
                          <div className="ratings">
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star off"></i>
                            <i className="fa fa-star off"></i>
                          </div>
                          <p itemprop="description">
                            FoodBakery order today. So great to be able to order
                            food and not have to talk to anyone.
                          </p>
                          <div className="review-info">
                            <img
                              className="brd-rd50"
                              src="assets/images/resources/reviewr-img1.jpg"
                              alt="reviewr-img1.jpg"
                              itemprop="image"
                            />
                            <div className="review-info-inner">
                              <h5 itemprop="headline">QLARK JAKSON</h5>
                              <i className="red-clr">2 months Ago</i>
                            </div>
                          </div>
                        </div>
                        <div className="review-box brd-rd5">
                          <h4 itemprop="headline">
                            <a href="#" title="" itemprop="url">
                              RESTAURANT DEMO
                            </a>
                          </h4>
                          <div className="ratings">
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star off"></i>
                          </div>
                          <p itemprop="description">
                            FoodBakery order today. So great to be able to order
                            food and not have to talk to anyone.
                          </p>
                          <div className="review-info">
                            <img
                              className="brd-rd50"
                              src="assets/images/resources/reviewr-img2.jpg"
                              alt="reviewr-img2.jpg"
                              itemprop="image"
                            />
                            <div className="review-info-inner">
                              <h5 itemprop="headline">QLARK JAKSON</h5>
                              <i className="red-clr">2 months Ago</i>
                            </div>
                          </div>
                        </div>
                        <div className="review-box brd-rd5">
                          <h4 itemprop="headline">
                            <a href="#" title="" itemprop="url">
                              RESTAURANT DEMO
                            </a>
                          </h4>
                          <div className="ratings">
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                            <i className="fa fa-star on"></i>
                          </div>
                          <p itemprop="description">
                            FoodBakery order today. So great to be able to order
                            food and not have to talk to anyone.
                          </p>
                          <div className="review-info">
                            <img
                              className="brd-rd50"
                              src="assets/images/resources/reviewr-img2.jpg"
                              alt="reviewr-img3.jpg"
                              itemprop="image"
                            />
                            <div className="review-info-inner">
                              <h5 itemprop="headline">QLARK JAKSON</h5>
                              <i className="red-clr">2 months Ago</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        </>
    );
}