export function convertTime(time24) {
  // split the time string into hours and minutes
  var hours24 = 0;
  var minutes = 0;

  if (typeof time24 === 'string') {
    const timeParts = time24.split(':');
    hours24 = parseInt(timeParts[0], 10);
    minutes = parseInt(timeParts[1], 10);
  } else {
    hours24 = time24;
  }

  // Special case for 24:00
  if (hours24 === 24) {
    return '12:00 AM';
  }

  // determine if it's AM or PM
  var ampm = hours24 >= 12 ? 'PM' : 'AM';

  // convert from 24-hour to 12-hour format
  var hours12 = hours24 % 12;
  hours12 = hours12 ? hours12 : 12; // the hour '0' should be '12'

  // construct the new time string in 12-hour format
  var time12 = hours12 + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;

  return time12;
}


export function disablePageScroll() {
  // Get a reference to the body element
  const body = document.body;

  // Save the current scroll position
  const scrollY = window.scrollY;

  // Set "overflow: hidden" on the body to disable scrolling
  body.style.overflow = 'hidden';

  // Adjust the body's top position to maintain the current scroll position
  body.style.position = 'fixed';
  body.style.top = `-${scrollY}px`;
}

// Function to enable the parent page scroll
export function enablePageScroll() {
  // Get a reference to the body element
  const body = document.body;

  // Restore the original scroll position
  const scrollY = parseInt(body.style.top, 10);
  body.style.position = '';
  body.style.top = '';
  body.style.overflow = 'auto';

  // Scroll to the previous position
  window.scrollTo(0, scrollY);
}


