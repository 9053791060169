import { apiHeader, urlGetChefAuthentication, urlRegisterChef } from "../../endpoints";
import Global from "../../global";
import { AuthActionTypes, GeneralActionTypes } from "../ActionTypes/actionTypes"
import axios from 'axios'
//import { GET_SESSION_SUCCESS } from "redux-react-session/actiontypes";
import { sessionService } from 'redux-react-session';

export const ChefLogin = (chefLogin) => {

    return async (dispatch) => {

        sessionService.deleteSession();
        sessionService.deleteUser();
        
        dispatch({ type: GeneralActionTypes.START_LOADING })

        await fetch(`${urlGetChefAuthentication}`, {
            method: 'POST',
            headers: apiHeader,
            body: JSON.stringify(chefLogin)
        })
            .then((response) => response.json())
            .then((responseData) => {

                console.log("ChefLogin (Services: Response data): ", responseData);

                if (responseData === "Invalid credentials") {
                    dispatch({ type: AuthActionTypes.CHEF_LOGIN_FAIL })
                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: "Your credentials are incorrect, Please provide your valid mobile number or password" })
                    sessionService.invalidateSession();
                    localStorage.setItem(Global.SessionKey.IsAuthenticated, false);
                }
                else {

                    var sessionLoginUserData = {

                        userId: responseData.id,
                        usernName: responseData.chef.fullName,
                        loginType: responseData.loginType,
                        token: responseData.token,
                        guidId: responseData.guidId,
                        mobile: responseData.mobile,
                        email: responseData.email,
                        expiredTime: responseData.expiredTime,
                        validaty: responseData.validaty,

                    }

                    sessionService
                        .saveSession(sessionLoginUserData)
                        .then(() => {
                            sessionService
                                .saveUser(sessionLoginUserData)
                                .then(() => {

                                    const _apiHeader = {

                                        'Authorization': `Bearer ${responseData.token}`,
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                    }
                                    responseData.chef.apiHeader = _apiHeader;
                                    localStorage.setItem(Global.SessionKey.LoginChefModel, JSON.stringify(responseData.chef));
                                    localStorage.setItem(Global.SessionKey.IsAuthenticated, true);
                                    localStorage.setItem(Global.SessionKey.APIHeader, JSON.stringify(responseData.chef.apiHeader));
                                    dispatch({ type: AuthActionTypes.CHEF_LOGIN_SUCCESS, payload: responseData.chef })
                                    dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })

                                })
                                .catch((err) => {
                                    dispatch({ type: AuthActionTypes.CHEF_LOGIN_FAIL })
                                    dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                                });
                        })
                        .catch((err) => {
                            dispatch({ type: AuthActionTypes.CHEF_LOGIN_FAIL })
                            dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                        });
                }
            })
            .catch((error) => {
                dispatch({ type: AuthActionTypes.CHEF_LOGIN_FAIL })
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: "Oops! something wrong, Please contact through our email (help@brown-onions.com)" })
            })
            dispatch({ type: GeneralActionTypes.END_LOADING })
    }
}

export const ChefLogout = () => {
    // console.log("ChefAuthService (Services): ChefLogout invoked");
    return (dispatch) => {

        localStorage.clear();
        sessionService.deleteSession();
        sessionService.deleteUser();

        dispatch({ type: AuthActionTypes.CHEF_LOGOUT })
    }
}

export const RegisterChef = (navigate,ChefModel) => {

    return async (dispatch) => {
        try {

            dispatch({ type: GeneralActionTypes.START_LOADING })

            const res = await axios.post(`${urlRegisterChef}`, ChefModel)
            console.log(res.data)

            let responseData = res.data;

            if (responseData === undefined) {
                let errMsg = 'Unable to received respose from Register API';
                dispatch({ type: AuthActionTypes.CHEF_REGISTER_FAIL })
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: errMsg })
                localStorage.setItem(Global.SessionKey.IsAuthenticated, false);
                return;
            }

            if (responseData === 'wrong credentials') {
                dispatch({ type: AuthActionTypes.CHEF_REGISTER_FAIL })
                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: responseData })
                localStorage.setItem(Global.SessionKey.IsAuthenticated, false);
            }
            else {
                const _apiHeader = {

                    'Authorization': `Bearer ${responseData.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
                responseData.chef.apiHeader = _apiHeader;

                var sessionLoginChefData = {

                    chefId: responseData.id,
                    chefnName: responseData.chef.name,
                    loginType: responseData.loginType,
                    token: responseData.token,
                    guidId: responseData.guidId,
                    mobile: responseData.mobile,
                    email: responseData.email,
                    expiredTime: responseData.expiredTime,
                    validaty: responseData.validaty,

                }
                sessionService
                    .saveSession(sessionLoginChefData)
                    .then(() => {
                        sessionService
                            .saveUser(sessionLoginChefData)
                            .then(() => {
                                const _apiHeader = {

                                    'Authorization': `Bearer ${responseData.token}`,
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                }
                                responseData.chef.apiHeader = _apiHeader;
                                localStorage.setItem(Global.SessionKey.LoginChefModel, JSON.stringify(responseData.chef));
                                localStorage.setItem(Global.SessionKey.IsAuthenticated, true);
                                localStorage.setItem(Global.SessionKey.APIHeader, JSON.stringify(responseData.chef.apiHeader));
                                
                                dispatch({ type: AuthActionTypes.CHEF_REGISTER_SUCCESS, payload: responseData.chef })
                                dispatch({ type: GeneralActionTypes.CLEAR_MESSAGE })
                                navigate("/Questionnaire");

                            })
                            .catch((err) => {
                                console.error(err)
                                dispatch({ type: AuthActionTypes.CHEF_REGISTER_FAIL })
                                dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                            });
                    })
                    .catch((err) => {
                        console.error(err)
                        dispatch({ type: AuthActionTypes.CHEF_REGISTER_FAIL })
                        dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
                    });
            }
        } catch (err) {
            console.error(err)
            dispatch({ type: AuthActionTypes.CHEF_REGISTER_FAIL })
            dispatch({ type: GeneralActionTypes.SET_MESSAGE, payload: err })
        }
        dispatch({ type: GeneralActionTypes.END_LOADING })
    }
}
