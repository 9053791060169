import React, { Component } from "react";
import { ReactComponent as Clock } from "../../svg/wall-clock2.svg";
import { ReactComponent as Placeholder } from "../../svg/placeholder.svg";
import { Link } from "react-router-dom";

import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/brownonions" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/company/brownonions/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/brownonions63" },
  // { Social: <FaTwitter />, link: "https://twitter.com/" },
];
class Footer extends Component {
  render() {
    return (
      <footer className="overlay position-relative" style={{paddingTop:'2.5em'}}>
        <div
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "assets/images/mazaa-pattern.png"
            })`,
            backgroundRepeat: "no-repeat",
            width: "250px",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="widget abt-us-widget">
                <h4 className="widget-title text-white h4 font-weight-bold">
                  About Us
                </h4>
                <p className="fs-18 text-gray2 mb-0" style={{textAlign:'justify'}}>
                A concept centered around an online platform aims to unlock extensive B2C business prospects
                 for home-based chefs of all ages, while simultaneously fostering B2B connections with nearby
                  restaurants offering meal services. In the UAE, where 90% of the population comprises 
                  expatriates, a substantial portion of households, predominantly women and men seeking customers
                   for their home-cooked meals, face challenges in achieving significant traction for their culinary
                    ventures. In response, we present an innovative solution—a web-based platform that connects chefs
                     with a vast customer base, enabling them to establish a robust network and effectively market their
                      culinary creations to users.
                </p>
                <div className="folow-us flex-row">
                  <div className="social-share-inner">
                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                      {SocialShare.map((val, i) => (
                        <li key={i}>
                          <a href={`${val.link}`}>{val.Social}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-btm text-center" >
            <div className="row">
              <div className="col-md-12">
                <p className="copy-rigts fs-16 text-gray2">
                  Copyright{" "}
                  <span className="text-white font-weight-semibold">
                  ©2024 
                  </span>
                  . All rights reserved by {" "}
                  <a className="text-theme" href="https://brownonions.co" title="Brown Onions">
                  Brown Onions 
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
