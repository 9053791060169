import { ReactComponent as Motor } from "../../../svg/motorbike.svg";
export const ChefShortlist = () => {

    return(
        <>
         <div
                    className="tab-pane"
                    id="v-pills-shortlist"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    {" "}
                    <div className="tabs-wrp brd-rd5">
                      <h4 itemprop="headline">SHORTLISTS</h4>
                      <div className="restaurants-list">
                        <div className="featured-restaurant-box style3 brd-rd5">
                          <div className="featured-restaurant-thumb">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/logo.png"
                                alt="restaurant-logo1-1.png"
                                itemprop="image"
                              />
                            </a>
                          </div>
                          <div className="featured-restaurant-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Domino's Pizza
                              </a>
                            </h4>
                            <ul className="post-meta">
                              <li>
                                <i className="fa fa-check-circle-o"></i> Min
                                order $50
                              </li>
                              <li>
                                <Motor /> 30min
                              </li>
                            </ul>
                          </div>
                          <div className="view-menu-liks">
                            <a
                              className="brd-rd3"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              View Menu
                            </a>
                          </div>
                        </div>
                        <div className="featured-restaurant-box style3 brd-rd5">
                          <div className="featured-restaurant-thumb">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/logo.png"
                                alt="restaurant-logo1-2.png"
                                itemprop="image"
                              />
                            </a>
                          </div>
                          <div className="featured-restaurant-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Pizza Hut
                              </a>
                            </h4>
                            <ul className="post-meta">
                              <li>
                                <i className="fa fa-check-circle-o"></i> Min
                                order $40
                              </li>
                              <li>
                                <Motor /> 30min
                              </li>
                            </ul>
                          </div>
                          <div className="view-menu-liks">
                            <a
                              className="brd-rd3"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              View Menu
                            </a>
                          </div>
                        </div>
                        <div className="featured-restaurant-box style3 brd-rd5">
                          <div className="featured-restaurant-thumb">
                            <a href="#" title="" itemprop="url">
                              <img
                                src="assets/images/logo.png"
                                alt="restaurant-logo1-3.png"
                                itemprop="image"
                              />
                            </a>
                          </div>
                          <div className="featured-restaurant-info">
                            <span className="red-clr">
                              5th Avenue New York 68
                            </span>
                            <h4 itemprop="headline">
                              <a href="#" title="" itemprop="url">
                                Burger King
                              </a>
                            </h4>
                            <ul className="post-meta">
                              <li>
                                <i className="fa fa-check-circle-o"></i> Min
                                order $100
                              </li>
                              <li>
                                <Motor /> 30min
                              </li>
                            </ul>
                          </div>
                          <div className="view-menu-liks">
                            <a
                              className="brd-rd3"
                              href="#"
                              title=""
                              itemprop="url"
                            >
                              View Menu
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="pagination-wrapper text-center style2">
                        <nav className="pt-60">
                          <ul className="pagination d-flex justify-content-center mb-0">
                            <li className="prev-page">
                              <a href="javascript:void(0)">
                                <i className="fa fa-angle-left"></i>Prev
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">1</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">2</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">3</a>
                            </li>
                            <li className="page-on">......</li>
                            <li>
                              <a href="javascript:void(0)">28</a>
                            </li>
                            <li className="next-page">
                              <a href="javascript:void(0)">
                                Next<i className="fa fa-angle-right"></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
        </>
    );
}