import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { fetchAutoCompleteRecipeNames } from "../../redux/ActionsCreator/chefRecipeService";
import { useSelector, useDispatch } from 'react-redux';
import { FaCalendar, FaSearch, FaCalendarWeek } from "react-icons/fa";

const SearchRecipeControl = ({ handleChangeSearchRecipe, handleAutocompleteChange }) => {
  /*
const recipes = [
  {
    id: 1,
    name: 'Farhan',
    character: '',
    picture:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Tom_Holland_by_Gage_Skidmore.jpg/220px-Tom_Holland_by_Gage_Skidmore.jpg',
  },
  {
    id: 2,
    name: 'Islam',
    character: '',
    picture:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Dwayne_Johnson_2%2C_2013.jpg/220px-Dwayne_Johnson_2%2C_2013.jpg',
  },
  {
    id: 3,
    name: 'Asif',
    character: '',
    picture:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Meryl_Streep_December_2018.jpg/220px-Meryl_Streep_December_2018.jpg',
  },
  {
      id: 4,
      name: 'Wahaj',
      character: '',
      picture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Meryl_Streep_December_2018.jpg/220px-Meryl_Streep_December_2018.jpg',
    },
    {
      id: 5,
      name: 'Imran',
      character: '',
      picture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Meryl_Streep_December_2018.jpg/220px-Meryl_Streep_December_2018.jpg',
    },
    {
      id: 6,
      name: 'Shahroz',
      character: '',
      picture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Meryl_Streep_December_2018.jpg/220px-Meryl_Streep_December_2018.jpg',
    },
    {
      id: 7,
      name: 'Wahaj',
      character: '',
      picture:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Meryl_Streep_December_2018.jpg/220px-Meryl_Streep_December_2018.jpg',
    },
];
*/

  const customStyles = {

    overlay: {
      border: 'none !important',
      boxShadow: 'none !important'
    },
    content: {
      border: 'none !important',
      boxShadow: 'none !important',

    },
    input: {
      color: '#666666',
      borderRadius: '40px',
      border: '2px solid #de7a72',
      padding: '1rem 2rem',
      fontSize: '1rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      width: '100%',
    }
  };

  var dispatch = useDispatch();

  const _fetchAutoCompleteRecipeNamesCRR = useSelector((state) => state.ChefRecipeReducer.fetchAutoCompleteRecipeNamesCRR);
  const _searchText = useSelector((state) => state.UserReducer.searchText);
  const [selected, setSelected] = useState([])

  // On Page Load (one time)
  useEffect(() => {
    console.log('search control fired on load')
    dispatch(fetchAutoCompleteRecipeNames());
    //document.getElementById("txtSearchReceipe").focus();

    if (document.getElementsByClassName("rbt-input")[0].getAttribute("aria-haspopup") === "listbox") {
      document.getElementsByClassName("rbt-input")[0].style.color = '#666666';
      document.getElementsByClassName("rbt-input")[0].style.borderRadius = '40px';
      document.getElementsByClassName("rbt-input")[0].style.border = '2px solid #de7a72';
      document.getElementsByClassName("rbt-input")[0].style.padding = '1rem 2rem';
      document.getElementsByClassName("rbt-input")[0].style.fontSize = '1rem';
      document.getElementsByClassName("rbt-input")[0].style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
      document.getElementsByClassName("rbt-input")[0].style.width = '100%';
    }

  }, []);

  useEffect(() => {
    const inputElement = document.querySelector('.rbt-input-main');
    if (inputElement && inputElement.value === "") {
      inputElement.value = _searchText;
    }

  });

  return (
    <div className="mb-3">

      <Typeahead
        id="txtSearchReceipe"
        onInputChange={(inputValue) => {
          handleChangeSearchRecipe(inputValue)
        }}
        onChange={(selected) => {
          console.log("onChange Search recipe control: ", selected);
          setSelected(selected)
          handleAutocompleteChange(selected)
        }}
        options={_fetchAutoCompleteRecipeNamesCRR}
        labelKey={(recipe) => recipe.value}
        filterBy={['value']}
        placeholder="Search recipes here..."

        minLength={1}
        flip={true}
        selected={selected}
        value={selected}
        style={customStyles}
        className=""
        renderMenuItemChildren={(recipe) => (
          <>
            <img
              alt="recipe"
              src={"/" + recipe.value3}
              style={{
                height: '56px',
                marginRight: '10px',
                width: '64px',
                borderRadius: '4px',
                float: 'left'
              }}
            />
            <span style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: '1rem' }}>{recipe.value}</span>
            <div style={{ fontFamily: 'calibri light', fontSize: '13px' }}>{recipe.value4}</div>

          </>
        )}

      />
      <div className="search-icon">
        <FaSearch  />
      </div>

    </div>
  );
}

export default SearchRecipeControl