import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const PersonalInfo = () => {

    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');
    const [area, setArea] = React.useState('');

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleChangeCity = (event) => {
        setCity(event.target.value);
    };

    const handleChangeArea = (event) => {
        setArea(event.target.value);
    };


    return (
        <React.Fragment>
            <div className='container'>

                {/* Delivery Address */}
                <div className="row">
                    <div className='modal-header'>
                        <div className="col-md-12 col-sm-12 col-lg-12" >
                            <div className='left' >
                                <Typography variant="overline" display="block">
                                    Delivery Address
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">

                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 207 }} size="small">
                            <InputLabel id="demo-simple-select-filled-label">Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={country}
                                onChange={handleChangeCountry}
                                label="Country"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>UAE</MenuItem>
                                <MenuItem value={20}>Saudia Arbia</MenuItem>
                                <MenuItem value={30}>Oman</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 207 }} size="small">
                            <InputLabel id="demo-simple-select-filled-label">City</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={city}
                                onChange={handleChangeCity}
                                label="City"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Dubai</MenuItem>
                                <MenuItem value={20}>Sharjah</MenuItem>
                                <MenuItem value={30}>Abu Dhabi</MenuItem>
                                <MenuItem value={30}>Ajman</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 207 }} size="small">
                            <InputLabel id="demo-simple-select-filled-label">Area</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={area}
                                onChange={handleChangeArea}
                                label="Area"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Al Qasmiya</MenuItem>
                                <MenuItem value={20}>Abu Dhagara</MenuItem>
                                <MenuItem value={30}>Buteena</MenuItem>
                                <MenuItem value={40}>Buhaira Cornish</MenuItem>
                                <MenuItem value={50}>Electra Street</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1}, }} autoComplete="off">
                            <TextField sx={{ width: '23ch' }} id="filled-basic" label="Street Name" variant="outlined" size='small' />
                        </Box>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1, minwidth: 235 }, }} autoComplete="off">
                            <TextField required sx={{ width: '23ch' }} id="filled-basic" label="Building Name" variant="outlined" size='small'  />
                        </Box>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1, minwidth: 235 }, }} autoComplete="off">
                            <TextField required sx={{ width: '23ch' }} id="filled-basic" label="Flat/Villa No" type='number' variant="outlined" size='small' />
                        </Box>
                    </div>

                </div>

                {/* Personal Information */}
                <div className="row">
                    <div className='modal-header'>
                        <div className="col-md-12 col-sm-12 col-lg-12" >
                            <div className='left' >
                                <Typography variant="overline" display="block">
                                    Personal Information
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1 }, }} autoComplete="off">
                            <TextField required id="filled-basic" label="Name" variant="outlined" size='small' helperText="Please enter your good name" error />
                        </Box>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1}, }} autoComplete="off">
                            <TextField required  sx={{ width: '23ch' }} id="filled-basic" label="Mobile No" variant="outlined" size='small' helperText="Please enter your phone no" error />
                        </Box>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1}, }} autoComplete="off">
                            <TextField required sx={{ width: '23ch' }} id="filled-basic" label="Whatsapp No" variant="outlined" size='small' />
                        </Box>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1 }, }} autoComplete="off">
                            <TextField required sx={{ width: '23ch' }} id="filled-basic" label="Email" variant="outlined" size='small' helperText="Incorrect email." error />
                        </Box>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1 }, }} autoComplete="off">
                            <TextField required sx={{ width: '23ch' }} id="filled-basic" label="Password" type='password' variant="outlined" size='small' />
                        </Box>
                    </div>
                    <div className="col-md-4 col-sm-4 col-lg-4" >
                        <Box component="form" sx={{ '& > :not(style)': { m: 1}, }} autoComplete="off">
                            <TextField required sx={{ width: '23ch' }} id="filled-basic" label="Confirm Password" type='password' variant="outlined" size='small' helperText="Password mismatching." error />
                        </Box>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PersonalInfo;